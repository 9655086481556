import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { SendReview } from "../SendReview";
import { ReviewAll } from "../ReviewAll";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import { MONTH } from "../../../utils/variables";
import { postReviewData } from "../../../api/reviewApi";
import { ReviewItem } from "./ReviewItem";

export function Reviews({setProfileSection}) {
  const { t } = useTranslation();

  const [reviewData, setReviewData] = useState([]);
  const [reviewAll, setReviewAll] = useState("");

  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState(0);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const fetchReviewData = async (id) => {
    try {
      setProfileSection("Reviews");
      const result = await postReviewData(id);
      setReviewData([result.data.reviewview]);
      setMessage(result.data.currentUserName);
    } catch (error) {
      console.error("Error fetching review data:", error);
    }
  };

  useEffect(() => {
    fetchReviewData();
  }, []);

  return (
    <>
      {reviewAll == "" ? (
        <ReviewAll setall={setReviewAll} profile_data={fetchReviewData} />
      ) : (
        <div class="main-header revumain">
          <button onClick={(e) => setReviewAll("")}>
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          {reviewData && reviewData[0] ? (
            <>
              <h2 className="border"></h2>
              <div className="detail_invit">
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  <span className="date">
                    {new Date(
                      reviewData && reviewData[0] && reviewData[0].created_at
                    ).toLocaleDateString("en-US", { weekday: "short" })}
                    ,
                    {MONTH[
                      new Date(
                        reviewData && reviewData[0] && reviewData[0].created_at
                      ).getMonth()
                    ] +
                      " " +
                      new Date(
                        reviewData && reviewData[0] && reviewData[0].created_at
                      ).getDate()}
                    ,
                    {new Date(
                      reviewData && reviewData[0] && reviewData[0].created_at
                    ).getFullYear()}
                    ,
                    {new Date(
                      reviewData && reviewData[0] && reviewData[0].created_at
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <Link
                    to={
                      reviewData[0]?.SenderUsertype != "parents"
                        ? "/profile-provider/" + reviewData[0]?.sender_userid
                        : "/profile-parents/" + reviewData[0]?.sender_userid
                    }
                  >
                    {reviewData && reviewData[0] && reviewData[0].SenderName}
                  </Link>
                  {t("left-review")}
                </p>
                <h2 style={{ marginTop: "8px" }}>
                  {t("text-dear")} {message + " "},
                </h2>
                <h2>{t("review-receive")}</h2>
              </div>
              <div class="right_side_section">
                <div class="looking_for_candidate">
                  {reviewData.map((data, index) => {
                    if (index <= 5) {
                      return (
                        <ReviewItem
                          key={data.id}
                          data={data}
                          setReviewAll={setReviewAll}
                          setUserId={setUserId}
                          setShowReviewModal={setShowReviewModal}
                          setUsername={setUsername}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
      {showReviewModal ? (
        <Modal show={showReviewModal} onHide={(e) => setShowReviewModal(false)}>
          <Modal.Body>
            <SendReview
              setrequest={setShowReviewModal}
              slugdata={userId}
              username={username}
            />
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
