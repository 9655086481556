import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const WarningModal = ({
  isWarningModal,
  setIsWarningModal,
  setIsAutoDeleteModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isWarningModal} onHide={(e) => setIsWarningModal(false)}>
      <Modal.Body>
        <div className="promocode_content cancelmembership deletemodel">
          <Link to="" onClick={(e) => setIsWarningModal(false)}>
            +
          </Link>
          <h2>
            <img src={window.location.origin + "/images/warning.svg"} />
            <br />
            {t("Delete Account")}
          </h2>
          <div className="cancelmembershipp">
            <p>
              <span>{t("text-warning")}</span> {t("action-undone")}
            </p>
            <div className="onepxline"></div>
            <ul>
              <li>
                <img
                  className="crossicon"
                  src={window.location.origin + "/images/cancel.svg"}
                  alt=""
                />
                {t("All your contacts will be inaccessible.")}
              </li>
              <li>
                <img
                  className="crossicon"
                  src={window.location.origin + "/images/cancel.svg"}
                  alt=""
                />
                {t("All your employment history will be deleted.")}
              </li>
              <li>
                <img
                  className="crossicon"
                  src={window.location.origin + "/images/cancel.svg"}
                  alt=""
                />
                {t(
                  "all-messages"
                )}
              </li>
              <li>
                <img
                  className="crossicon"
                  src={window.location.origin + "/images/cancel.svg"}
                  alt=""
                />
                {t(
                  "recommendations-download"
                )}
              </li>
            </ul>
            <div className="button text-center">
              <div className="pull-right">
                <button
                  className="btn"
                  onClick={(e) => {
                    setIsAutoDeleteModal();
                    setIsWarningModal(false);
                  }}
                >
                  {t("Delete my account")}
                </button>
              </div>
              <div className="pull-right">
                <button
                  className="btn confirm"
                  onClick={(e) => setIsWarningModal(false)}
                >
                  {t("Keep my account")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
