import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";

export const InviteModal = ({
                              isInviteModal,
                              setIsInviteModal,
                              jobsListProfession,
                              setJobID,
                              handleInterviewInvite
                            }) => {
  const { t } = useTranslation();

  return (
      <Modal show={isInviteModal} onHide={() => setIsInviteModal(false)}>
        <Modal.Body>
          <div className="promocode_content signout invite request request2">
            <h2>{t("Interview invite")}</h2>
            <ul>
              <li>
                <span>{t("Send invite for:")}</span>
              </li>
              {jobsListProfession.service_type !== null && Object.entries(jobsListProfession.service_type).map(([key, value], index) => {
                return (
                    <li key={index}>
                      <label style={{ cursor: "pointer" }}>
                        <input
                            type="radio"
                            name="profession"
                            onClick={() => setJobID(value)}
                            style={{ marginRight: "8px" }}
                        />
                        <span>
                      {value === "Special Education Paraprofessional"
                          ? t("text-paraprofessional")
                          : value === "Special Education Teacher"
                              ? t("sped-teacher")
                              : t(value)}
                    </span>
                      </label>
                    </li>
                );
              })}
            </ul>
            <div className="button text-center">
              <div className="pull-right">
                <button className="btn" onClick={() => setIsInviteModal(false)}>
                  {t("text-cancel")}
                </button>
              </div>
              <div className="pull-right">
                <button className="btn confirm" onClick={handleInterviewInvite}>
                  {t("text-send")}
                </button>
              </div>
            </div>
            <div className="noteedit">
              <MarkRoundIcon />
              <span>
              <b>{t("note-with")}</b> {t("record-indicate-meeting")}
            </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
};
