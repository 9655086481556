import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";
import { NannyIcon } from "../../assets/image/NannyIcon";
import { TeacherIcon } from "../../assets/image/TeacherIcon";
import { ParaprofessionalIcon } from "../../assets/image/ParaprofessionalIcon";
import { TutorIcon } from "../../assets/image/TutorIcon";
import {AdvancedSearchProvider} from "../../components/AdvancedSearch/AdvancedSearchProvider";
import {AdvancedSearchParent} from "../../components/AdvancedSearch/AdvancesSearchParent";

export const ProviderSearchFilter = ({
  rate,
  setRate,
  search,
  setSearch,
  profile,
  setChildren,
  handleChildrenAge,
  recommend,
  handleShowRecommend,
  handleShowContent,
  handleSelectElement,
  handleElementById,
  handleProviderList,
  childrenAge,
  isOpen,
  advanceSearch,
    jobData,
    jobCategory,
    setJobCategory,
    languageSpeak,
    setLanguageSpeak,
    selfProfile,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState({
    data1: "",
    data2: "",
    data3: "",
    data4: "",
  });

  const categories = Object.values(categoryData).filter(
    (category) => category !== ""
  );

  const shouldDisplayFilter =
    profile.about === "" ||
    profile.about === null ||
    !localStorage.getItem("token") ||
    !localStorage.getItem("id") ||
    localStorage.getItem("refine");

  return (
    <div className="filter_search">
      <div className="advance_search_filter">
        <div
          className={
            "advance_search_filter_input Profile_complete " +
            (!isOpen ? "op" : "")
          }
        >
          <span
            id="my-adv-filter"
            onClick={(e) => handleShowContent("filtern")}
          >
            {t("Advanced search")}
          </span>
          {
            advanceSearch === true && (Object.keys(selfProfile).length === 0 || selfProfile.user_type === 'parents') ? (
                <AdvancedSearchParent
                    rate={rate}
                    setRate={setRate}
                    search={search}
                    setSearch={setSearch}
                    profile={profile}
                    setChildren={setChildren}
                    handleChildrenAge={handleChildrenAge}
                    handleProviderList={handleProviderList}
                    handleElementById={handleElementById}
                    childrenAge={childrenAge}
                    jobData={jobData}
                    jobCategory={jobCategory}
                    setJobCategory={setJobCategory}
                    languageSpeak={languageSpeak}
                    setLanguageSpeak={setLanguageSpeak}
                />
                ) : (
            advanceSearch !== false ? (
            <AdvancedSearchProvider
                rate={rate}
                setRate={setRate}
                search={search}
                setSearch={setSearch}
                profile={profile}
                setChildren={setChildren}
                handleChildrenAge={handleChildrenAge}
                handleProviderList={handleProviderList}
                handleElementById={handleElementById}
                childrenAge={childrenAge}
            />
          ) : (
            ""
          ))}
        </div>
      </div>
      <div className="filter_category Profile_complete ">
        <div className="filter_category_select detail work-experience">
          <div className="job_performance">
            <div className="form_group   full">
              <div className="customselect inp">
                <input
                  id="my-fil-cat"
                  className="keyword"
                  type="text"
                  placeholder={
                    categories.length === 0 && t("Select job category")
                  }
                  value={categories.join(", ")}
                />
                <div
                  className="overflow"
                  id="over3"
                  onClick={(e) => handleShowRecommend("cate9", "over3")}
                  style={
                    recommend != "" ? { display: "block" } : { display: "none" }
                  }
                ></div>
                <div
                  className="option"
                  id="cate9"
                  style={
                    recommend != "" ? { display: "block" } : { display: "none" }
                  }
                >
                  <p>
                    <input
                      type="checkbox"
                      onClick={(a) => {
                        handleSelectElement(t("text-nanny"));
                        if (a.target.checked) {
                          setCategoryData({
                            ...categoryData,
                            data1: t("text-nanny"),
                          });
                        } else {
                          setCategoryData({ ...categoryData, data1: "" });
                        }
                      }}
                    />
                    <h3>
                      <NannyIcon />
                      {t("text-nanny")}
                    </h3>
                    <span></span>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      onClick={(a) => {
                        handleSelectElement(t("spec-education-teacher"));
                        if (a.target.checked) {
                          setCategoryData({
                            ...categoryData,
                            data2: t("spec-education-teacher"),
                          });
                        } else {
                          setCategoryData({ ...categoryData, data2: "" });
                        }
                      }}
                    />
                    <h3>
                      <TeacherIcon />
                      {t("spec-education-teacher")}
                    </h3>
                    <span></span>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      onClick={(a) => {
                        handleSelectElement(
                          t("spec-education-paraprofessional")
                        );
                        if (a.target.checked) {
                          setCategoryData({
                            ...categoryData,
                            data3: t("Special education paraprofessional"),
                          });
                        } else {
                          setCategoryData({ ...categoryData, data3: "" });
                        }
                      }}
                    />
                    <h3>
                      <ParaprofessionalIcon />
                      {t("spec-education-paraprofessional")}
                    </h3>
                    <span></span>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      onClick={(a) => {
                        handleSelectElement(t("text-tutor"));
                        if (a.target.checked) {
                          setCategoryData({
                            ...categoryData,
                            data4: t("text-tutor"),
                          });
                        } else {
                          setCategoryData({ ...categoryData, data4: "" });
                        }
                      }}
                    />
                    <h3>
                      <TutorIcon />
                      {t("text-tutor")}
                    </h3>
                    <span></span>
                  </p>
                </div>
                <span onClick={(e) => handleShowRecommend("cate9", "over3")}>
                  <ArrowUpIcon color={"#7D2B8B"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="city">
        <input
          type="text"
          placeholder={t("text-city")}
          id="my-fil-city"
          onChange={(e) => setSearch({ ...search, city: e.target.value })}
        />
      </div>
      <div className="zip code">
        <input
          type="text"
          placeholder={t("zip-code")}
          id="my-fil-zip"
          onChange={(e) => setSearch({ ...search, zip: e.target.value })}
        />
      </div>
      <div className="distance">
        <div className="distance_input">
          <select
            name=""
            id="my-fil-dis"
            onChange={(e) => setSearch({ ...search, distance: e.target.value })}
          >
            <option value="" selected>
              {t("Distance")}
            </option>
            <option value={5}>5 {t("Miles")}</option>
            <option value={10}>10 {t("Miles")}</option>
            <option value={15}>15 {t("Miles")}</option>
            <option value={20}>20 {t("Miles")}</option>
            <option value={25}>25 {t("Miles")}</option>
            <option value={30}>30 {t("Miles")}</option>
            <option value={35}>35 {t("Miles")}</option>
            <option value={40}>40 {t("Miles")}</option>
            <option value={45}>45 {t("Miles")}</option>
            <option value={50}>50 {t("Miles")}</option>
          </select>
        </div>
      </div>
    </div>
  );
};
