import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const RemoveModal = ({
  id,
  isRemove,
  setIsRemove,
  username,
  setIsCheck,
  handleSubmitProfile,
  heart2,
  setRemoveCheck,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isRemove} onHide={(e) => setIsRemove(false)}>
      <Modal.Body>
        <div className="promocode_content signout invite favoritemod">
          <h2>{t("remove-favorite")}</h2>
          <p>
            {t("sure-remove")}
            {" "}
            <span style={{ color: "#A98D4B" }}>{username} </span>
            {t("favorites-question")}
          </p>
          <div class="button text-center ">
            <div class="pull-right">
              <button
                class="btn"
                onClick={(e) => {
                  handleSubmitProfile(id);
                  setIsRemove(false);
                  setIsCheck(false);
                  if (heart2 && heart2 == true) {
                    setRemoveCheck(false);
                  }
                }}
              >
                {t("text-remove")}
              </button>
              <button
                class="btn"
                onClick={(e) => {
                  setRemoveCheck(true);
                  setIsRemove(false);
                }}
              >
                {t("text-keep")}
              </button>
            </div>
          </div>
          <p class="hidemod">
            {t("text-visit")}
            <Link to="/faq" target="_blank">
              {t("text-faq")}
            </Link>
            {t("text-and")}
            <Link target="_blank" to="/safety-center">
              {t("safety-center")}
            </Link>
            {t("about-platform")}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
