import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

export const InviteRow = ({ data, slugdata, handleDeleteSelect, setView, setProfileSection }) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const language = i18n.language;
  
  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleRowClick = () => {
    localStorage.setItem('side', 'Job_history');
    setProfileSection('Job_history');
    navigate("/search-providers/interview-invites/" + data.id);
    setView(data.id);
  };

  return (
    <tr className={data.read_status == 0 ? "unread" : ""}>
      <td className="view-message dont-show">
        <input
          type="checkbox"
          className="mail-radio"
          name="chk"
          onChange={() => handleDeleteSelect(data.id)}
        />
        <span className="circle"></span>
        <span onClick={handleRowClick}>
          {data.read_status == 0 && (
            <span className="new-tag">{t("text-new-up")}</span>
          )}
          <span className="username-text"> {data.ParentsName} </span>
          {t("interview-sent-row")}
        </span>
      </td>
      <td className="view-message text-right">
        {`${month[new Date(data.created_at).getMonth()]} ${new Date(
          data.created_at
        ).getDate()}`}
      </td>
    </tr>
  );
};
