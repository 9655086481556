import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../urls";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useTranslation } from "react-i18next";
import { ViewEdit } from "../../components/common/ViewEdit";
import { ViewEditProvider } from "../../components/common/ViewEditProvider";

import { Calendar } from "../../components/common/Calendar";
import { FavoriteProfile } from "../../components/common/FavoriteProfile";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { PhoneIcon } from "../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../assets/image/ServiceIcon";
import { StarIcon } from "../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../assets/image/WhiteStarIcon";
import { PawsIcon } from "../../assets/image/PawsIcon";
import { DoneIcon } from "../../assets/image/DoneIcon";
import { RoundHandIcon } from "../../assets/image/RoundHandIcon";

import { ArrowLeftIcon } from "../../assets/image/ArrowLeftIcon";
import { ProfileFilter } from "./ProfileFilter";
import { useRatesStore } from "../../store/useRatesStore";
import { useProfileDetailsStore } from "../../store/useProfileDetailsStore";
import { ProfileLeftSection } from "./ProfileLeftSection";
import { ProfileMobileMenu } from "./ProfileMobileMenu";
import { JobApplicationModal } from "./Modals/JobApplicationModal";
import { SuccessModal } from "./Modals/SuccessModal";
import { ReviewModal } from "./Modals/ReviewModal";
import { PhotoUploadModal } from "./Modals/PhotoUploadModal";
import { PerfectJobModal } from "./Modals/PerfectJobModal";
import { ProviderModal } from "./Modals/ProviderModal";
import { RecommendationModal } from "./Modals/RecommendationModal";
import { InterviewModal } from "./Modals/InterviewModal";
import { SignoutModal } from "../../components/Modals/SignoutModal";
import { SurveyModal } from "./Modals/SurveyModal";
import { LoginModal } from "./Modals/LoginModal";
import { ProfileUsernameContent } from "./ProfileUsernameContent";
import { ProfileLocalInfo } from "./ProfileLocalInfo";
import {SearchLeftSection} from "../SearchProvidersPage/SearchLeftSection";
import {SearchLeftSection as ParentsSearchLeft} from "../SearchParentsPage/SearchLeftSection";

export function ProfilePage() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const [isPerfectJobModal, setIsPerfectJobModal] = useState(false);
  const [isSuccessfullyModal, setIsSuccessfullyModal] = useState(false);
  const [isJobApplicationModal, setIsJobApplicationModal] = useState(false);
  const [isProviderModal, setIsProviderModal] = useState(false);
  const [photoUpload, setPhotoUpload] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [image, setImage] = useState(null);
  const [recommend, setRecommend] = useState("");
  const [profile, setProfile] = useState({});
  const [selfProfile, setSelfProfile] = useState({});
  const [count, setCount] = useState(true);
  const [isFilterCheck, setIsFilterCheck] = useState(true);
  const [children, setChildren] = useState("");
  const [childrenAge, setChildrenAge] = useState([]);
  const [category, setCategory] = useState([]);

  const [photo, setPhoto] = useState("");
  const [isSurveyModal, setIsSurveyModal] = useState(false);
  const [isInterviewInvite, setIsIterviewInvite] = useState(false);
  const [isRecommendationModal, setIsRecommendationModal] = useState(false);
  const [userId, setUserId] = useState({
    id: "",
    userid: "",
    index: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const [profileSection, setProfileSection] = useState(
    localStorage.getItem("side") && localStorage.getItem("side") != ""
      ? localStorage.getItem("side")
      : ""
  );
  const [tab, setTab] = useState(
    localStorage.getItem("search") && localStorage.getItem("search") != ""
      ? localStorage.getItem("search")
      : ""
  );

  const [isSignoutModal, setIsSignoutModal] = useState(false);

  const [childrenAgeData, setChildrenAgeData] = useState([]);
  const [number, setNumber] = useState("");
  const [jobApply, setJobApply] = useState({
    id1: "",
    id2: "",
  });
  const [generalJobApply, setGeneralJobApply] = useState("");

  const [localInfo, setLocalInfo] = useState({
    About: localStorage.getItem("edittime") ? "active" : "",
    kids: localStorage.getItem("edittime") ? "active" : "",
    job: localStorage.getItem("edittime") ? "active" : "",
    availability: localStorage.getItem("edittime") ? "active" : "",
    info: localStorage.getItem("edittime") ? "active" : "",
    security: localStorage.getItem("edittime") ? "active" : "",
    personal: localStorage.getItem("edittime") ? "active" : "",
    Reviews: localStorage.getItem("edittime") ? "active" : "",
    Job: localStorage.getItem("edittime") ? "active" : "active",
  });

  const [isOpenLink, setIsOpenLink] = useState(true);

  const [calendarType, setCalendarType] = useState(1);

  const [report, setReport] = useState({
    resion: "",
    other: "",
    error: "",
  });
  const [isLoginModal, setIsLoginModal] = useState(false);
  const id = localStorage.getItem('id');
  const [advanceSearch, setAdcanceSearch] = useState(id === null);
  const [step, setStep] = useState("");

  const {
    oralSpeak,
    habit,
    setOralSpeak,
    setHabit,
    qualifications,
    setQualifications,
  } = useProfileDetailsStore();

  const {
    secondaryRate,
    setSecondaryRate,
    generalRate,
    tutorRate,
    nanyRate,
    setNanyRate,
    setGeneralRate,
    setTutorRate,
  } = useRatesStore();

  const sliderRef = useRef();

  const handleSelectOption = (data) => {
    let sum = false;
    category.map((e, index) => {
      if (e.name == data) {
        sum = true;
        category.splice(index, 1);
      }
    });
    if (sum == false) {
      category.push({ name: data });
    }
    setTimeout(() => {
      setCategory([...category]);
    }, 500);
  };

  const handleDeleteOption = (data) => {
    let sum = false;
    childrenAge.map((e, index) => {
      if (e.name == data) {
        sum = true;
        childrenAge.splice(index, 1);
      }
    });
    if (sum == false) {
      childrenAge.push({ name: data });
    }
    setTimeout(() => {
      setChildrenAge([...childrenAge]);
    }, 500);
  };

  const handleElementById = (e) => {
    if (isOpen) {
      window.document.getElementById(e).style.display = "block";
      setIsOpen(false);
    } else {
      window.document.getElementById(e).style.display = "none";
      setIsOpen(true);
    }
  };

  const handleElementDisplay = (e, x) => {
    if (isOpen) {
      document.getElementById(e).style.display = "block";
      document.getElementById(x).style.display = "block";
      setIsOpen(false);
    } else {
      document.getElementById(e).style.display = "none";
      document.getElementById(x).style.display = "none";
      setIsOpen(true);
    }
  };

  const slugdata = useParams();

  const handleDetailData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSelfProfile(result.data);
        setAdcanceSearch(true);
      })
      .catch((error) => console.log("error", error));
  };

  const handleInformation = (e, x, z) => {
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isOpenLink) {
      document.getElementById(z).style.display = "block";
      setIsOpenLink(false);
    } else {
      setIsOpenLink(true);
      document.getElementById(z).style.display = "none";
    }
  };
  const handleProfileUpdate = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("file_path", photo);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setPhotoUpload(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer  " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: slugdata.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      api +
        "/api/" +
        (localStorage.getItem("token") != null
          ? "v1/favotheruserprofileview"
          : "otheruserprofileview"),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setProfile(result.data);
        console.log(result);
        setQualifications(
          result.data.tutorliketoteach != null
            ? result.data.tutorliketoteach
            : {}
        );
        setOralSpeak(
          result.data.candidatespeaks != null
            ? result.data.candidatespeaks
            : { ...oralSpeak }
        );
        setTimeout(() => {
          if (result.data.calanderlastupdate != null) {
            setCalendarType(parseInt(result.data.calanderlastupdate));
          }
        }, 2000);
        setHabit({
          licence: result.data.childtransportation
            ? result.data.childtransportation
            : "",
          kids: result.data.cookingforkids ? result.data.cookingforkids : "",
          housework: result.data.lighthousework
            ? result.data.lighthousework
            : "",
        });
        setChildren(result.data.childneedcareno);
        setChildrenAgeData(
          result.data.childage != null ? result.data.childage : []
        );
        if (localStorage.getItem("token") != null) {
          setChildrenAge(
              result.data.yourkidallapplicable != null
                  ? result.data.yourkidallapplicable
                  : []
          );
        }
        setNanyRate({
          min:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setGeneralRate({
          min:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setSecondaryRate({
          min:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
      })
      .catch((error) => console.log("error", error));
  };

  const handleFilterElements = () => {
    if (isFilterCheck) {
      document.getElementById("filteron").style.display = "block";
      setIsFilterCheck(false);
    } else {
      document.getElementById("filteron").style.display = "none";
      setIsFilterCheck(true);
    }
  };

  const handleReportUser = () => {
    if (report.resion != "") {
      setReport({ ...report, error: "" });
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        report_userid: slugdata.id,
        reason: report.resion,
        other_reason: report.other,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(api + "/api/v1/reportuser", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsSurveyModal(false);
          setIsLoginModal(true);
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    } else {
      setReport({ ...report, error: "error" });
    }
  };

  const handleCalendarData = (name, e) => {
    console.log(name, e);
  };

  const handleVisitorData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      userid: slugdata.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/visitorsadd", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    const myHeadersProvider = new Headers();
    myHeadersProvider.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeadersProvider.append("Content-Type", "application/json");

    const raw2 = JSON.stringify({
      visitor_userid: slugdata.id,
    });

    const requestOptionsProvider = {
      method: "POST",
      headers: myHeadersProvider,
      body: raw2,
      redirect: "follow",
    };

    fetch(api + "/api/v1/providerprofilecheck", requestOptionsProvider)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setPhoto(event.target.files[0]);
    }
  };

  const handleRecommendation = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      recommendation_userid: slugdata.id,
      recommendation_message: recommend,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/sendrecommendationrequest", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIsRecommendationModal(false);
        setNumber("1");
        setStep("1");
        setTimeout(() => {
          setStep("");
        }, 2000);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleJobApply = (id, user_id, indexnum) => {
    console.log(indexnum);

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      job_id: id,
      job_ownerid: user_id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/applyjob", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setJobApply({
          ...jobApply,
          [indexnum]: id,
        });
        setGeneralJobApply(
          result.message == "Unauthorised" ? result.errors : result.message
        );
        setIsSuccessfullyModal(true);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (count) {
      handleProfileData();
      handleDetailData();
      handleVisitorData();
      setCount(false);
    }
    console.log(jobApply, "job apply");
    localStorage.setItem("side", profileSection);
    localStorage.setItem("back", localStorage.getItem("back"));
  }, [profile, jobApply, profileSection]);

  return (
    <>
      <Header fil={handleFilterElements} />
      {step == "1" && (
        <div className="popup_status" id="popup_status">
          {t("post-sent-recommendation")}
        </div>
      )}

      <div className="container-fluid">
        <div className="filter_header" id="">
          <div className="container">
            <ProfileFilter
              profile={profile}
              handleSelectOption={handleSelectOption}
              handleDeleteOption={handleDeleteOption}
              setChildren={setChildren}
              selfProfile={selfProfile}
              category={category}
              handleElementDisplay={handleElementDisplay}
              handleElementById={handleElementById}
              childrenAge={childrenAge}
              advanceSearch={advanceSearch}
              isOpen={isOpen}
            />
          </div>
        </div>

        {tab == "Profile" && (
          <>
            {localStorage.getItem("user_type") == "parents" ? (
              <div className="mobile profile">
                <ViewEdit />
              </div>
            ) : (
              <div className="mobile profile">
                <ViewEditProvider />
              </div>
            )}
          </>
        )}

        <div className="container" style={{ overflow: "hidden" }}>
          <div
            className="body_section searchbody "
            style={
              tab != ""
                ? {
                    minHeight: "auto",
                    marginBottom: "100px",
                    float: "left",
                    width: "100%",
                  }
                : {}
            }
          >
            <div className="left_side_section" id={!isOpen ? "leftdata" : ""}>
              {selfProfile && selfProfile.user_type === 'provider' && selfProfile.first_name && (
                  <SearchLeftSection
                      profile={selfProfile}
                      setPhotoUpload={setPhotoUpload}
                      setIsSignoutModal={setIsSignoutModal}
                      setProfileSection={setProfileSection}
                      profileSection={profileSection}
                      setSubTab={setTab}
                  />
              )  }
              {selfProfile && selfProfile.user_type === 'parent' &&  (
                  <ParentsSearchLeft
                      profile={selfProfile}
                      setPhotoUpload={setPhotoUpload}
                      setIsSignoutModal={setIsSignoutModal}
                      setProfileSection={setProfileSection}
                      profileSection={profileSection}
                  />
              )  }
            </div>

            {tab == "" && profile?.username && (
              <div
                className="Account  editview profileshow"
                style={{ width: "calc(100% - 22.5%)" }}
              >
                <h2>
                  {t("Profile Overview")}
                  <Link
                    to={
                      localStorage.getItem("back")
                        ? "/search-parents/" + localStorage.getItem("back")
                        : "/search-parents"
                    }
                  >
                    <ArrowLeftIcon />
                    {t("text-back")}
                  </Link>
                </h2>
                <div className="about_edit">
                  <div className="profile_full left_side_section ">
                    <div className="profile_box">
                      <div className="profile_box_social">
                        <div className="profile_box_social_sec1">
                          {profile.phoneVerifiedStatus == 1 ? (
                            <img
                              src={
                                window.location.origin +
                                "/images/nany_phone.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <PhoneIcon />
                          )}
                          <img
                            src={
                              window.location.origin + "/images/nany_msg.svg"
                            }
                            alt=""
                          />
                          {profile.facebookverify != null ||
                          profile.linkdinverify != null ? (
                            <img
                              src={
                                window.location.origin + "/images/nany_cont.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <ServiceIcon />
                          )}
                        </div>
                        {profile.id == profile.id ? (
                          ""
                        ) : (
                          <div className="profile_box_social_sec2">
                            <RoundHandIcon
                              onClick={(e) => setIsSurveyModal(true)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="profile_pic_sec">
                        <div className="profile_pic">
                          <img
                            src={
                              api + "/assets/images/users/" + profile.file_path
                            }
                            width="80"
                            height="80"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="profile_detail">
                        <p>
                          {profile.first_name + " " + profile.last_name} (25)
                          <a className="heart_sec">
                            {localStorage.getItem("user_type") == "provider" &&
                            selfProfile.id != profile.id ? (
                              <FavoriteProfile
                                id={profile.id}
                                username={profile.username}
                                heart2={profile.favornotprofile}
                              />
                            ) : (
                              ""
                            )}
                          </a>
                        </p>
                        <div className="profilestart_video">
                          {console.log(
                            profile.job,
                            profile.job.filter(
                              (e) =>
                                e?.checkhiredorNot?.applyjob_hiredstatus == 0
                            )
                          )}

                          {profile.reviewAvg >= 0 ? (
                            (profile.invitationData &&
                              profile.invitationData.filter(
                                (e) => e.ProviderId == selfProfile.id
                              )[0]?.inviteStatus == 1 &&
                              profile.checkreviewstatus != true) ||
                            profile.job.filter(
                              (e) =>
                                e?.checkhiredorNot?.applyjob_hiredstatus == 0
                            )[0] ||
                            Object.values(jobApply)[0] ? (
                              <>
                                {[...Array(profile.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return (
                                      <StarIcon
                                        onClick={(e) => {
                                          console.log("Button clicked!");
                                          setShowReviewModal(true);
                                        }}
                                      />
                                    );
                                  }
                                )}
                                {[...Array(5 - profile.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return (
                                      <WhiteStarIcon
                                        onClick={(e) => {
                                          console.log("Button clicked!");
                                          setShowReviewModal(true);
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <>
                                {[...Array(profile.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return (
                                      <StarIcon
                                        onClick={(e) => {
                                          console.log("Button clicked!");
                                          setShowReviewModal(true);
                                        }}
                                      />
                                    );
                                  }
                                )}
                                {[...Array(5 - profile.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return (
                                      <WhiteStarIcon
                                        onClick={(e) => {
                                          console.log("Button clicked!");
                                          setShowReviewModal(true);
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </>
                            )
                          ) : (
                            ""
                          )}
                          <span>({profile.reviewcount})</span>
                        </div>
                      </div>
                      <div className="profile_functions">
                        <div className="members">
                          <h5>
                            {new Date().getFullYear() -
                              new Date(profile.created_at).getFullYear() >
                            0
                              ? new Date().getFullYear() -
                                new Date(profile.created_at).getFullYear() +
                                t(" YRS")
                              : 0 + t(" YRS")}
                          </h5>
                          <h6>{t("Member")}</h6>
                        </div>
                        <div className="vi"></div>

                        <div className="applications">
                          <h5>{profile.jobs}</h5>
                          <h6>{t("Job posts")}</h6>
                        </div>
                        <div className="vi"></div>
                        <div className="hiring">
                          <h5>{profile.hiringcount}</h5>
                          <h6>{t("Hirings")}</h6>
                        </div>
                      </div>
                    </div>
                    <div
                      className="quarybtn faid"
                      style={
                        profile.invitationData &&
                        profile.invitationData.filter(
                          (e) => e.ProviderId == selfProfile.id
                        )[0]?.inviteStatus == 1
                          ? { opacity: "1" }
                          : {}
                      }
                    >
                      {!localStorage.getItem("token") ||
                      !localStorage.getItem("id") ? (
                        <Link to="/signup" className="message">
                          {t("send-message")}
                        </Link>
                      ) : (
                        <button
                          className="message"
                          onClick={(e) => {
                            selfProfile.user_type == "provider" &&
                            selfProfile.backgroundstatus == null ? (
                              setIsPerfectJobModal(true)
                            ) : selfProfile.user_type == "parents" &&
                              !selfProfile.job[0] ? (
                              setIsProviderModal(true)
                            ) : (
                              <>
                                {
                                  (navigate(
                                    "/search-providers/message-inbox/" +
                                      profile.id
                                  ),
                                  localStorage.setItem("message", slugdata.id))
                                }
                              </>
                            );
                          }}
                          style={
                            profile.invitationData &&
                            profile.invitationData.filter(
                              (e) => e.ProviderId == selfProfile.id
                            )[0]?.inviteStatus == 1 &&
                            selfProfile.user_type == "provider"
                              ? {}
                              : { opacity: "0.5" }
                          }
                          disabled={
                            profile.invitationData &&
                            profile.invitationData.filter(
                              (e) => e.ProviderId == selfProfile.id
                            )[0]?.inviteStatus == 1
                              ? false
                              : true
                          }
                        >
                          Send message
                        </button>
                      )}
                      {number == "" ? (
                        <button
                          style={
                            profile.invitationData &&
                            profile.invitationData.filter(
                              (e) => e.ProviderId == selfProfile.id
                            )[0]?.inviteStatus == 1 &&
                            selfProfile.user_type == "provider" &&
                            !profile.recomm_status
                              ? {}
                              : { opacity: "0.5" }
                          }
                          className="ask"
                          onClick={(e) => {
                            selfProfile.user_type == "provider" &&
                            selfProfile.backgroundstatus == null
                              ? setIsPerfectJobModal(true)
                              : selfProfile.user_type == "parents" &&
                                  !selfProfile.job[0]
                                ? setIsProviderModal(true)
                                : setIsRecommendationModal(true);
                          }}
                          disabled={
                            profile.invitationData &&
                            profile.invitationData.filter(
                              (e) => e.ProviderId == selfProfile.id
                            )[0]?.inviteStatus == 1 &&
                            selfProfile.user_type == "provider" &&
                            !profile.recomm_status
                              ? false
                              : true
                          }
                        >
                          {t("Ask for Recommendation")}
                        </button>
                      ) : (
                        <button
                          style={{ opacity: "0.5" }}
                          className="ask"
                          disabled={true}
                        >
                          {t("Ask for Recommendation")}
                        </button>
                      )}
                    </div>
                  </div>
                  <p>
                    <h4>{t("About me and my family")}</h4>
                    <PawsIcon />
                    <span>{profile.about}</span>
                    <div
                      className="quarybtn faid"
                      style={
                        profile.invitationData &&
                        profile.invitationData.filter(
                          (e) => e.ProviderId == selfProfile.id
                        )[0]?.inviteStatus == 1
                          ? { opacity: "1" }
                          : {}
                      }
                    >
                      {!localStorage.getItem("token") ||
                      !localStorage.getItem("id") ? (
                        <Link to="/signup" className="message">
                          {t("send-message")}
                        </Link>
                      ) : (
                        <button
                          className="message"
                          onClick={(e) => {
                            selfProfile.user_type == "provider" &&
                            selfProfile.backgroundstatus == null ? (
                              setIsPerfectJobModal(true)
                            ) : selfProfile.user_type == "parents" &&
                              !selfProfile.job[0] ? (
                              setIsProviderModal(true)
                            ) : (
                              <>
                                {
                                  (navigate(
                                    "/search-providers/message-inbox/" +
                                      profile.id
                                  ),
                                  localStorage.setItem("message", slugdata.id))
                                }
                              </>
                            );
                          }}
                          style={
                            profile.invitationData &&
                            profile.invitationData.filter(
                              (e) => e.ProviderId == selfProfile.id
                            )[0]?.inviteStatus == 1 &&
                            selfProfile.user_type == "provider"
                              ? {}
                              : { opacity: "0.5" }
                          }
                          disabled={
                            profile.invitationData &&
                            profile.invitationData.filter(
                              (e) => e.ProviderId == selfProfile.id
                            )[0]?.inviteStatus == 1
                              ? false
                              : true
                          }
                        >
                          {t("send-message")}
                        </button>
                      )}
                      {number == "" ? (
                        <button
                          style={
                            profile.invitationData &&
                            profile.invitationData.filter(
                              (e) => e.ProviderId == selfProfile.id
                            )[0]?.inviteStatus == 1 &&
                            selfProfile.user_type == "provider" &&
                            !profile.recomm_status
                              ? {}
                              : { opacity: "0.5" }
                          }
                          className="ask"
                          onClick={(e) => setIsRecommendationModal(true)}
                          disabled={
                            profile.invitationData &&
                            profile.invitationData.filter(
                              (e) => e.ProviderId == selfProfile.id
                            )[0]?.inviteStatus == 1 &&
                            selfProfile.user_type == "provider" &&
                            !profile.recomm_status
                              ? false
                              : true
                          }
                        >
                          {t("Ask for Recommendation")}
                        </button>
                      ) : (
                        <button
                          style={{ opacity: "0.5" }}
                          className="ask"
                          disabled={true}
                        >
                          {t("Ask for Recommendation")}
                        </button>
                      )}
                    </div>
                  </p>
                </div>

                <ProfileUsernameContent
                  localInfo={localInfo}
                  setLocalInfo={setLocalInfo}
                  profile={profile}
                  selfProfile={selfProfile}
                  setIsPerfectJobModal={setIsPerfectJobModal}
                  setIsProviderModal={setIsProviderModal}
                  setIsJobApplicationModal={setIsJobApplicationModal}
                  jobApply={jobApply}
                  setUserId={setUserId}
                  handleInformation={handleInformation}
                  setIsOpenLink={setIsOpenLink}
                  sliderRef={sliderRef}
                />

                <div
                  className={
                    localInfo.kids == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setLocalInfo({
                        ...localInfo,
                        kids: localInfo.kids == "" ? "active" : "",
                      })
                    }
                  >
                    {t("Info about kids")}
                  </h3>
                  {localInfo.kids == "active" && (
                    <div className="editkids">
                      <label>
                        {t("Number of children to take care of")}
                        <span
                          className={`cir ${
                            children === "twins" && language === "sr"
                              ? "specialStyle"
                              : ""
                          }`}
                        >
                          {t(children)}
                        </span>
                      </label>
                      {childrenAgeData[0] ? (
                        <label>
                          {t("child-age")}
                          <span>
                            <strong>
                              {childrenAgeData.map((e, index) => {
                                return (
                                  t(e.name) +
                                  (profile.childage.length - 1 >= index + 1
                                    ? "; "
                                    : "")
                                );
                              })}
                            </strong>
                          </span>
                        </label>
                      ) : (
                        ""
                      )}
                      <br />
                      <label>
                        {t("Special needs")}
                        <span>
                          <strong>
                            <DoneIcon />
                            {t(profile.childsocialneed)}
                          </strong>
                        </span>
                      </label>
                      {profile.childsocialneed == t("objection")
                        ? ""
                        : childrenAge.map((e) => {
                            return (
                              <label>
                                <span>
                                  <strong>
                                    <DoneIcon />
                                    {t(e.name)}
                                  </strong>
                                </span>
                              </label>
                            );
                          })}
                      {profile.service_type && profile.service_type.tab2 ? (
                        <label>
                          {t("Child needs IEP")}
                          <span>
                            <strong>
                              <DoneIcon />
                              {t(profile.childneediep)}
                            </strong>
                          </span>
                        </label>
                      ) : (
                        ""
                      )}
                      <br />
                      <label>
                        {t("Allergies")}
                        <span>
                          <strong>
                            <DoneIcon />
                            {t(profile.childanyallergies)}
                          </strong>
                        </span>
                      </label>
                      <label>
                        {t("Medical conditions")}
                        <span>
                          <strong>
                            <DoneIcon />
                            {t(profile.childmedicalcondition)}
                          </strong>
                        </span>
                      </label>
                    </div>
                  )}
                </div>
                <div
                  className={
                    localInfo.job == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setLocalInfo({
                        ...localInfo,
                        job: localInfo.job == "" ? "active" : "",
                      })
                    }
                  >
                    {t("job-preferences")}
                  </h3>
                  {localInfo.job == "active" && (
                    <div className="editkids editabout">
                      {profile.service_type && profile.service_type.tab1 && (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={
                                window.location.origin + "/images/nany_pur.svg"
                              }
                            />
                            {t("text-nanny")}
                          </h2>
                          <label>
                            {t("Live in nanny")}
                            <span>
                              <strong>
                                <DoneIcon />
                                {profile.liveinnany}
                              </strong>
                            </span>
                          </label>
                          <label>
                            {t("Rate per hour")}
                            <span>
                              <strong>
                                <b> {nanyRate.min + " - " + nanyRate.max}$</b>
                              </strong>
                            </span>
                          </label>
                          <br />
                          <br />
                        </div>
                      )}

                      {profile.service_type && profile.service_type.tab2 && (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={
                                window.location.origin +
                                "/images/special_education.svg"
                              }
                            />
                            {t("education-teacher")}
                          </h2>
                          <label>
                            {t("Rate per hour")}
                            <span>
                              <strong>
                                <b>
                                  {generalRate.min + " - " + generalRate.max}$
                                </b>
                              </strong>
                            </span>
                          </label>
                          <br />
                          <br />
                        </div>
                      )}

                      {profile.service_type && profile.service_type.tab3 && (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={
                                window.location.origin +
                                "/images/professional.svg"
                              }
                            />
                            {t("education-paraprofessional")}
                          </h2>
                          <label>
                            {t("Rate per hour")}
                            <span>
                              <strong>
                                <b>
                                  {secondaryRate.min +
                                    " - " +
                                    secondaryRate.max}
                                  $
                                </b>
                              </strong>
                            </span>
                          </label>
                          <br />
                          <br />
                        </div>
                      )}

                      {profile.service_type && profile.service_type.tab4 && (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={window.location.origin + "/images/tuter.svg"}
                            />
                            {t("text-tutor")}
                          </h2>
                          <h4>{t("We need a tutor for")}</h4>
                          <label>
                            {qualifications.English &&
                            qualifications.English != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("english")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Serbian &&
                            qualifications.Serbian != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("serbian")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Mathematics &&
                            qualifications.Mathematics != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("mathematics")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Physics &&
                            qualifications.Physics != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("physics")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Chemistry &&
                            qualifications.Chemistry != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("chemistry")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Other &&
                            qualifications.Other != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("text-other")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                          <br />
                          <label>
                            {t("Rate per hour")}
                            <span>
                              <strong>
                                <b>{tutorRate.min + " - " + tutorRate.max}$</b>
                              </strong>
                            </span>
                          </label>
                          <br />
                          <br />
                        </div>
                      )}

                      <div className="gernaledt">
                        <h2 className="border ">{t("general-info")}</h2>
                        <div className="left2">
                          <label>
                            {t("work-experience-preffer")}
                            <span>
                              <strong>
                                <b>{t(profile.tutorexp)}</b>
                              </strong>
                            </span>
                          </label>
                          <label>
                            {t("age-provider-preffer")}
                            <span>
                              <strong>
                                <b>{t(profile.preferredageofprovider)}</b>
                              </strong>
                            </span>
                          </label>
                        </div>
                        <div className="right2">
                          <label>
                            {t("start-date")}
                            <span>
                              <strong>
                                <b>{profile.tutorstartdate}</b>
                              </strong>
                            </span>
                          </label>
                          <label>
                            {t("text-frequency")}
                            <span>
                              <strong>
                                <b>{t(profile.tutorintrestedin)}</b>
                              </strong>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    localInfo.availability == "active"
                      ? "active personal"
                      : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setLocalInfo({
                        ...localInfo,
                        availability:
                          localInfo.availability == "" ? "active" : "",
                      })
                    }
                  >
                    {t("availability")}
                  </h3>
                  {localInfo.availability == "active" ? (
                    <div className="calendershow">
                      <div className="calander">
                        <div className="form_group full">
                          <label>{t("Availability and working hours")}</label>
                        </div>
                        <div className="legend">
                          <div className="form_group full">
                            <label className="border">{t("text-legend")}</label>
                            <ul>
                              <li>
                                <strong>{t("full-time")}</strong>
                                <br />
                                {t("mon-fri-9-6")}
                              </li>
                              <li>
                                <strong>{t("before-school")}</strong>
                                <br />
                                {t("mon-fri-6-9")}
                              </li>
                              <li>
                                <strong>{t("after-school")}</strong>
                                <br />
                                {t("mon-fri-3-9")}
                              </li>
                              <li>
                                <strong>{t("text-overnight")}</strong>
                                <br />
                                {t("mon-fri-9-6-am")}
                              </li>
                              <li>
                                <strong>{t("text-weekends")}</strong>
                                <br />
                                {t("saturday-sunday")}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="calanderfull progilecl">
                          {calendarType == 1 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.fulltime}
                              data2={"fulltime"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                          {calendarType == 2 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.beforeschool}
                              data2={"beforeschool"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                          {calendarType == 3 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.afterschool}
                              data2={"afterschool"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                          {calendarType == 4 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.overnight}
                              data2={"overnight"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                          {calendarType == 5 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.weekends}
                              data2={"weekends"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    localInfo.info == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setLocalInfo({
                        ...localInfo,
                        info: localInfo.info == "" ? "active" : "",
                      })
                    }
                  >
                    {t("additional-info")}
                  </h3>
                  {localInfo.info == "active" && (
                    <ProfileLocalInfo
                      oralSpeak={oralSpeak}
                      profile={profile}
                      habit={habit}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <ProfileMobileMenu
          setProfileSection={setProfileSection}
          profileSection={profileSection}
          tab={tab}
          setTab={setTab}
        />
      </div>

      {isLoginModal && (
        <LoginModal
          isLoginModal={isLoginModal}
          setIsLoginModal={setIsLoginModal}
          setIsSurveyModal={setIsSurveyModal}
        />
      )}

      {isSurveyModal && (
        <SurveyModal
          isSurveyModal={isSurveyModal}
          setIsSurveyModal={setIsSurveyModal}
          report={report}
          handleReportUser={handleReportUser}
          setReport={setReport}
        />
      )}

      {isSignoutModal && (
        <SignoutModal
          isSignoutModal={isSignoutModal}
          setIsSignoutModal={setIsSignoutModal}
        />
      )}

      {isInterviewInvite && (
        <InterviewModal
          isInterviewInvite={isInterviewInvite}
          setIsIterviewInvite={setIsIterviewInvite}
          profile={profile}
        />
      )}

      {isRecommendationModal && (
        <RecommendationModal
          isRecommendationModal={isRecommendationModal}
          setIsRecommendationModal={setIsRecommendationModal}
          profile={profile}
          setRecommend={setRecommend}
          recommend={recommend}
          handleRecommendation={handleRecommendation}
        />
      )}
      {isProviderModal && (
        <ProviderModal
          isProviderModal={isProviderModal}
          setIsProviderModal={setIsProviderModal}
        />
      )}

      {isPerfectJobModal && (
        <PerfectJobModal
          isPerfectJobModal={isPerfectJobModal}
          setIsPerfectJobModal={setIsPerfectJobModal}
        />
      )}

      {photoUpload && (
        <PhotoUploadModal
          photoUpload={photoUpload}
          photo={photo}
          image={image}
          selfProfile={selfProfile}
          setPhotoUpload={setPhotoUpload}
          setPhoto={setPhoto}
          handleImageChange={handleImageChange}
          handleProfileUpdate={handleProfileUpdate}
        />
      )}

      {showReviewModal && (
        <ReviewModal
          showReviewModal={showReviewModal}
          profile={profile}
          slugdata={slugdata}
          setShowReviewModal={setShowReviewModal}
        />
      )}

      {isSuccessfullyModal && (
        <SuccessModal
          isSuccessfullyModal={isSuccessfullyModal}
          generalJobApply={generalJobApply}
          setIsSuccessfullyModal={setIsSuccessfullyModal}
        />
      )}

      {isJobApplicationModal && (
        <JobApplicationModal
          isJobApplicationModal={isJobApplicationModal}
          userId={userId}
          profile={profile}
          handleJobApply={handleJobApply}
          setIsJobApplicationModal={setIsJobApplicationModal}
        />
      )}
      <Footer />
    </>
  );
}
