import React, { useState, useEffect } from "react";
import { FacebookProvider, LoginButton } from "react-facebook";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { api } from "../../../urls";
import { DATA_COUNTRY } from "../../../utils/data";

import { useTranslation } from "react-i18next";
import { ProviderProfession } from "../ProviderProfession";
import { Calendar } from "../Calendar";
import { LinkedinContent } from "../LinkedinContent";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { LinkedinIcon } from "../../../assets/image/LinkedinIcon";
import { DateIcon } from "../../../assets/image/DateIcon";
import { BanCarIcon } from "../../../assets/image/BanCarIcon";
import { CarIcon } from "../../../assets/image/CarIcon";
import { BanPlaneIcon } from "../../../assets/image/BanPlaneIcon";
import { PlaneIcon } from "../../../assets/image/PlaneIcon";
import { BanHouseIcon } from "../../../assets/image/BanHouseIcon";
import { HouseIcon } from "../../../assets/image/HouseIcon";
import { BanCookIcon } from "../../../assets/image/BanCookIcon";
import { CookIcon } from "../../../assets/image/CookIcon";
import { FlagIcon } from "../../../assets/image/FlagIcon";
import { BanSmokeIcon } from "../../../assets/image/BanSmokeIcon";
import { SmokeIcon } from "../../../assets/image/SmokeIcon";
import { ArrowUpIcon } from "../../../assets/image/ArrowUpIcon";
import { FacebookIcon } from "../../../assets/image/FacebookIcon";
import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";
import { VideoIcon } from "../../../assets/image/VideoIcon";
import { PawsIcon } from "../../../assets/image/PawsIcon";
import { UploadIcon } from "../../../assets/image/UploadIcon";
import { DoneIcon } from "../../../assets/image/DoneIcon";
import { ThingIcon } from "../../../assets/image/ThingIcon";
import { MarkIcon } from "../../../assets/image/MarkIcon";
import { DumpIcon } from "../../../assets/image/DumpIcon";
import { AddIcon } from "../../../assets/image/AddIcon";
import { ConfirmIcon } from "../../../assets/image/ConfirmIcon";
import { HomeIcon } from "../../../assets/image/HomeIcon";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../../utils/variables";
import {DoneIconGray} from "../../../assets/image/DoneIconGray";

export function ViewEditProvider() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [showModal, setShowModal] = useState(false);
  const [isDisable, setIsDisable] = useState("");
  const [generalInfo, setGeneralInfo] = useState({
    About: localStorage.getItem("edittime") ? "active" : "",
    kids: localStorage.getItem("edittime") ? "active" : "",
    Experience: "",
    job: localStorage.getItem("edittime") ? "active" : "",
    availability: localStorage.getItem("edittime") ? "active" : "",
    info: localStorage.getItem("edittime") ? "active" : "",
    security: localStorage.getItem("edittime") ? "active" : "",
    personal: localStorage.getItem("edittime") ? "active" : "",
  });
  const [uploadPhoto, setUploadPhoto] = useState(false);
  const [certificates, setCertificates] = useState({
    qualificationscertificatesname: "",
    qualificationscertificatesname2: "",
    qualificationscertificatesname3: "",
    qualificationscertificatesname4: "",
  });

  const [tutorRate, setTutorRate] = useState({
    min: 0,
    max: 0,
  });

  const [tutorTime, setTutorTime] = useState({
    fulltime: "",
    parttime: "",
    occasionally: "",
  });

  const [promoCity, setPromoCity] = useState({
    firstcity: "",
    secondcity: "",
    thirdcity: "",
  });

  const [offerPromotion, setOfferPromotional] = useState({
    afterAll: "",
    licensed: "",
    medical: "",
    education: "",
    special: "",
    activities: "",
    other: "",
  });
  
  const [offerEducation, setOfferEducation] =
    useState({
      sensory: "",
      school: "",
      all: "",
      educational: "",
      baby: "",
    });

  const [editGeneralIngo, setEditGeneralInfo] = useState({
    About: localStorage.getItem("edittime") ? "edit" : "",
    kids: localStorage.getItem("edittime") ? "edit" : "",
    Experience: "",
    job: localStorage.getItem("edittime") ? "edit" : "",
    availability: localStorage.getItem("edittime") ? "edit" : "",
    info: localStorage.getItem("edittime") ? "edit" : "",
    security: localStorage.getItem("edittime") ? "edit" : "",
    personal: localStorage.getItem("edittime") ? "edit" : "",
  });

  const [range, setRange] = useState(0);
  const [sepallapplicable, setsepallapplicable] = useState([]);
  const [setallapplicable2, setsetallapplicable2] = useState([]);
  const [tutorExperience, setTutorExperience] = useState(0);
  const [workExperience, setWorkExperience] = useState(0);
  const [experience, setExperience] = useState(0);
  const [allapplicable, setallapplicable] = useState([]);
  const [allMethods, setAllMethods] = useState([]);
  const addNewMethod = (newItem) => {
    setAllMethods((prevArray) => [...prevArray, newItem]);
  };
  const removeMethod = (itemToRemove) => {
    setAllMethods((prevArray) => prevArray.filter((item) => item !== itemToRemove));
  };
  const [nchildren, setnchildren] = useState("");
  const [childrenAge, setchildrenAge] = useState([]);
  const [laveo, setlavelo] = useState(false);
  const [awarded, setawarded] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [methods, setMethods] = useState({
    setexpmethods: "",
    setexpmethods2: "",
    setexpmethods3: "",
    setexpmethods4: "",
  });
  const [today] = useState(new Date());
  const [tutorintrestedinm, settutorintrestedinm] = useState({
    fulltime: "",
    parttime: "",
    occasionally: "",
  });
  const [languages, setLanguages] = useState([]);

  const handleCloseModal = () => setShowModal(false);

  const selectoption5 = (data) => {
    let sum = false;
    sepallapplicable.map((e, index) => {
      if (e.name == data) {
        sum = true;
        sepallapplicable.splice(index, 1);
      }
    });
    if (sum == false) {
      sepallapplicable.push({ name: data });
    }
    setTimeout(() => {
      setsepallapplicable([...sepallapplicable]);
    }, 500);
  };

  const handleElementById = (e, x) => {
    if (isOpen) {
      document.getElementById(e).style.display = "block";
      document.getElementById(x).style.display = "block";
      setIsOpen(false);
    } else {
      document.getElementById(e).style.display = "none";
      document.getElementById(x).style.display = "none";
      setIsOpen(true);
    }
  };
  const selectoption3 = (data) => {
    let sum = false;
    allapplicable.map((e, index) => {
      if (e.name == data) {
        sum = true;
        allapplicable.splice(index, 1);
      }
    });
    if (sum == false) {
      allapplicable.push({ name: data });
    }
    setTimeout(() => {
      setallapplicable([...allapplicable]);
    }, 500);
  };
  const selectoption6 = (data) => {
    let sum = false;
    setallapplicable2.map((e, index) => {
      if (e.name == data) {
        sum = true;
        setallapplicable2.splice(index, 1);
      }
    });
    if (sum == false) {
      setallapplicable2.push({ name: data });
    }
    setTimeout(() => {
      setsetallapplicable2([...setallapplicable2]);
    }, 500);
  };


  const hadnleLanguageSelect = (data) => {
    let sum = false;
    let x = data.substr(data.lastIndexOf("\\") + 1).split("_")[0];
    languages.map((e, index) => {
      if (e.name.substr(e.name.lastIndexOf("\\") + 1).split("_")[0] == x) {
        sum = true;
        e.name = data;
      }
    });
    if (sum == false) {
      languages.push({ name: data });
    }
    setTimeout(() => {
      setLanguages([...languages]);
    }, 500);
  };

  const selectoption = (data) => {
    let sum = false;
    selectList.map((e, index) => {
      if (e.name == data) {
        sum = true;
        selectList.splice(index, 1);
      }
    });
    if (sum == false) {
      selectList.push({ name: data });
    }
    setTimeout(() => {
      setSelectList([...selectList]);
    }, 500);
  };
  
  const selectoption4 = (data) => {
    let sum = false;
    childrenAge.map((e, index) => {
      if (e.name == data) {
        sum = true;
        childrenAge.splice(index, 1);
      }
    });
    if (sum == false) {
      childrenAge.push({ name: data });
    }
    setTimeout(() => {
      setchildrenAge([...childrenAge]);
    }, 500);
  };

  const [detail, setDetail] = useState({});

  const [generalChildrenAge, setGeneralChildrenAge] = useState([]);
  const [generalChildren, setGeneralChildred] = useState(0);
  const [selectList, setSelectList] = useState([]);

  const [profession, setProfession] = useState({});
  const [activeProfession, setActiveProfession] = useState(
    JSON.parse(sessionStorage.getItem("service"))
      ? JSON.parse(sessionStorage.getItem("service"))
      : {}
  );

  const [nannyRate, setNannyRate] = useState({
    min: 0,
    max: 0,
  });
  const [rate, setRate] = useState({
    min: 0,
    max: 0,
  });
  const [perRate, setPerRate] = useState({
    min: 0,
    max: 0,
  });
  const [qualifications, setQualifications] = useState({
    English: "",
    Serbian: "",
    Mathematics: "",
    Physics: "",
    Chemistry: "",
    Other: "",
  });
  const [isConditionWait, setIsConditionWait] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState("");

  const [isCode, setIsCode] = useState(true);
  const [errorField, setErrorField] = useState({
    phone: "",
    email: "",
  });
  const [failed, setFailed] = useState(0);
  const [habit, setHabit] = useState({
    smoke: "",
    licence: "",
    kids: "",
    housework: "",
    family: "",
  });

  const handleCodeSelect = () => {
    if (isCode) {
      setIsCode(false);
    } else {
      setIsCode(true);
    }
  };
  const [image, setImage] = useState(null);
  const [photo, setPhoto] = useState("");
  const [contactCode, setContactCode] = useState({
    code: "",
    flag: "",
  });
  const [errorFields, setErrorFields] = useState({
    about: "",
    profile_headline: "",
    file_path: "",
    educationlevel: "",
    school: "",
    yearawarded: "",
    recommendationsfile: "",
    qualificationscertificatesname: "",
    cv: "",
    qualificationscertificatesdoc: "",
    nanynewbornexp: "",
    nanyyearexp: "",
    setareaexperties: "",
    setexpmethods: "",
    setexpiep: "",
    setyearexp: range,
    setallapplicable: setallapplicable2,
    nanyexpwithkid: "",
    tutorexp: tutorExperience,
    sepexpwithkid: "",
    nanyallapplicable: allapplicable,
    sepallapplicable: sepallapplicable,
    sepexpmethods: allMethods,
    sepworkexp: workExperience,
    nanyperhrrate: nannyRate.min,
    tutorliketoteach: "",
    tutorintrestedonlinecls: "",
    tutorintrestedinschool: "",
    tutorallapplicable: selectList,
    tutorperhrrate: tutorRate.min,
    tutorworkwithnochild: generalChildren,
    tutorprefchildage: generalChildrenAge,
    tutorstartdate: "",
    tutorintrestedin: "",
    nanyhrrate: nannyRate.min,
    nanyintrestedinschool: "",
    nanyworkwithnochild: nchildren,
    nanystartdate: "",
    nanyprefchildage: childrenAge,
    nanyintrestedin: "",
    fulltime: {},
    beforeschool: {},
    afterschool: {},
    overnight: {},
    weekends: {},
    englishlevel: "",
    italianlevel: "",
    spanishlevel: "",
    germanlevel: "",
    chineselevel: "",
    frenchlevel: "",
    otherlangname: "",
    otherlevel: "",
    phone: "",
    backgrounddoc: "",
    backgroundstatus: "",
    smoke: habit.smoke,
    carorlicence: habit.licence,
    cooking: habit.kids,
    lighthousework: habit.housework,
    traveling: habit.family,
    workingabroad: "",
    livewithfamily: "",
    anyallergies: "",
    anyallergiesdescription: "",
    medicalcondition: "",
    medicalconditiondescription: "",
    plateformsocialmedia: "",
    seperhrrate: "",
    seterhrrate: "",
    yearofexpasteacher: "",
  });
  const [detailProvider, setDetailProvider] = useState({
    about: "",
    profile_headline: "",
    video: "",
    file_path: "",
    educationlevel: "",
    school: "",
    yearawarded: "",
    recommendationsfile: "",
    qualificationscertificatesname: "",
    cv: "",
    qualificationscertificatesdoc: "",
    nanynewbornexp: "",
    nanyyearexp: "",
    setareaexperties: "",
    setexpmethods: "",
    setexpiep: "",
    setyearexp: range,
    setallapplicable: setallapplicable2,
    nanyexpwithkid: "",
    tutorexp: tutorExperience,
    sepexpwithkid: "",
    nanyallapplicable: allapplicable,
    sepallapplicable: sepallapplicable,
    sepexpmethods: allMethods,
    sepworkexp: workExperience,
    nanyperhrrate: nannyRate.min,
    tutorliketoteach: qualifications,
    tutorintrestedonlinecls: "",
    tutorintrestedinschool: "",
    tutorallapplicable: selectList,
    tutorperhrrate: tutorRate.min,
    tutorworkwithnochild: "",
    tutorprefchildage: "",
    tutorstartdate: "",
    tutorintrestedin: "",
    nanyhrrate: nannyRate.min,
    nanyintrestedinschool: "",
    nanyworkwithnochild: nchildren,
    nanystartdate: "",
    nanyprefchildage: childrenAge,
    nanyintrestedin: "",
    fulltime: {},
    beforeschool: {},
    afterschool: {},
    overnight: {},
    weekends: {},
    countrycode: "",
    englishlevel: "",
    italianlevel: "",
    spanishlevel: "",
    germanlevel: "",
    chineselevel: "",
    frenchlevel: "",
    otherlangname: "",
    otherlevel: "",
    phone: "",
    facebookverify: "",
    twitterverify: "",
    linkdinverify: "",
    instaverify: "",
    backgrounddoc: "",
    backgroundstatus: "",
    smoke: habit.smoke,
    carorlicence: habit.licence,
    cooking: habit.kids,
    lighthousework: habit.housework,
    traveling: habit.family,
    workingabroad: "",
    livewithfamily: "",
    anyallergies: "",
    anyallergiesdescription: "",
    medicalcondition: "",
    medicalconditiondescription: "",
    plateformsocialmedia: "",
    seperhrrate: "",
    seterhrrate: "",
    yearofexpasteacher: 0,
    alertJobposts: "",
    profileactions: "",
    calanderlastupdate: "0",
  });

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result.data);
        if (result.data.qualificationscertificatesname && result.data.qualificationscertificatesname[0]) {
          const iterator = result.data.qualificationscertificatesname.values();
          let y = {};
          for (let elements of iterator) {
            if (elements == "First Aid") {
              y = { ...y, qualificationscertificatesname: elements };
            } else if (elements == "Montessori") {
              y = { ...y, qualificationscertificatesname2: elements };
            } else if (elements == "Board Certified Nurse") {
              y = { ...y, qualificationscertificatesname3: elements };
            } else if (
              elements != "First Aid" &&
              elements != "Montessori" &&
              elements != "Board Certified Nurse"
            ) {
              setCertificates({
                ...certificates,
                ...y,
                qualificationscertificatesname4:
                  elements != null ? elements : "",
              });
            }
          }
        }

        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });

        if (result.data.nanyintrestedin) {
          const parsedInterestedIn = JSON.parse(result.data.nanyintrestedin || {
            fulltime: result.data.nanyintrestedin == t('full-time') ? t('full-time') : "",
            parttime: result.data.nanyintrestedin == t('part-time') ? t('part-time') : "",
            occasionally: result.data.nanyintrestedin == t('occasionally') ? t('occasionally') : "",
          });
          setTutorTime(parsedInterestedIn);
        } else if (result.data.tutorintrestedin) {
          const parsedInterestedIn = JSON.parse(result.data.tutorintrestedin || {
            fulltime: result.data.tutorintrestedin == t('full-time') ? t('full-time') : "",
            parttime: result.data.tutorintrestedin == t('part-time') ? t('part-time') : "",
            occasionally: result.data.tutorintrestedin == t('occasionally') ? t('occasionally') : "",
          });
          setTutorTime(parsedInterestedIn);
        }

        setTimeout(() => {
          const x = Object.keys(detailProvider).forEach(function (key) {
            detailProvider[key] =
              result.data[key] != null ? result.data[key] : "";
            setDetailProvider({ ...detailProvider });
          });
        }, 500);
        setExperience(
          result.data.nanyyearexp != null ? result.data.nanyyearexp : 0
        );
        setRange(result.data.setyearexp != null ? result.data.setyearexp : 0);
        setWorkExperience(
          result.data.sepworkexp != null ? result.data.sepworkexp : 0
        );
        setTutorExperience(
          result.data.tutorexp != null ? result.data.tutorexp : 0
        );
        setMethods(JSON.parse(result.data.setexpmethods[0] || {
          setexpmethods: "",
          setexpmethods2: "",
          setexpmethods3: "",
          setexpmethods4: "",
        }));
        setallapplicable(
          result.data.nanyallapplicable != null
            ? result.data.nanyallapplicable
            : []
        );
        setNannyRate({
          min:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setRate({
          min:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setPerRate({
          min:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setnchildren(
          result.data.nanyworkwithnochild != null
            ? result.data.nanyworkwithnochild
            : ""
        );
        setGeneralChildrenAge(
          result.data.tutorprefchildage != null
            ? result.data.tutorprefchildage
            : []
        );
        setGeneralChildred(
          result.data.nanyworkwithnochild != null
            ? result.data.tutorworkwithnochild
            : ""
        );
        setSelectList(
          result.data.tutorallapplicable != null
            ? result.data.tutorallapplicable
            : []
        );
        setchildrenAge(
          result.data.nanyprefchildage != null
            ? result.data.nanyprefchildage
            : []
        );
        setsepallapplicable(
          result.data.sepallapplicable != null
            ? result.data.sepallapplicable
            : []
        );
        setsetallapplicable2(
          result.data.setallapplicable != null
            ? result.data.setallapplicable
            : []
        );
        setQualifications(
          result.data.tutorliketoteach != null
            ? result.data.tutorliketoteach
            : {}
        );
        setHabit({
          smoke: result.data.smoke ? result.data.smoke : "",
          licence: result.data.carorlicence ? result.data.carorlicence : "",
          kids: result.data.cooking ? result.data.cooking : "",
          housework: result.data.lighthousework
            ? result.data.lighthousework
            : "",
          family: result.data.traveling ? result.data.traveling : "",
        });
        if (typeof result.data.setexpmethods === "string") {
          setAllMethods(
              result.data.setexpmethods
                  ? [result.data.setexpmethods]
                  : allMethods
          );
        } else {
          setAllMethods(
              result.data.setexpmethods
                  ? JSON.parse(result.data.setexpmethods)
                  : allMethods
          );
        }
        setProfession(
          result.data.inactive_service != null
            ? result.data.inactive_service
            : {}
        );

        setActiveProfession(result.data.active_service);

        result.data.prefcityforpromo
          ? setPromoCity(result.data.prefcityforpromo)
          : setPromoCity(promoCity);
        result.data.promotionaloffersfor
          ? setOfferPromotional(result.data.promotionaloffersfor)
          : setOfferPromotional(offerPromotion);
        result.data.educationalproductoffersfor
          ? setOfferEducation(
              result.data.educationalproductoffersfor
            )
          : setOfferEducation(offerEducation);
        sessionStorage.setItem(
          "service",
          JSON.stringify(result.data.service_type)
        );
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    sessionStorage.setItem(
        "service",
        JSON.stringify(activeProfession)
    );
  }, [activeProfession]);

  const handleMobileVerify = () => {
    if (detailProvider.phone.length > 7) {
      setIsConditionWait(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonenoforverify:
          contactCode.code != ""
            ? contactCode.code + detailProvider.phone
            : "+" + detailProvider.countrycode + detailProvider.phone,
        phone: detailProvider.phone,
        countrycode:
          contactCode.code != ""
            ? contactCode.code
            : detailProvider.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/sendotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsConditionWait(false);
          console.log(result);
        })
        .catch((error) => {
          setIsConditionWait(false);
          console.log("error", error);
        });
    } else {
      setErrorField({
        ...errorField,
        phone: "error",
      });
    }
  };

  const handleOtpVerify = () => {
    if (verifyOtp.length > 3) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonetoken: verifyOtp,
        phone: detailProvider.phone,
        countrycode:
          contactCode.code != ""
            ? contactCode.code
            : detailProvider.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/verifyotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setFailed(failed + 1);
          result.success == true
            ? (document.getElementById("success").style.display = "block")
            : (document.getElementById("success").style.display = "none");
          result.message == "failed" && failed == 0
            ? (document.getElementById("success4").style.display = "block")
            : (document.getElementById("success4").style.display = "none");
          result.message == "failed" && failed == 1
            ? (document.getElementById("success3").style.display = "block")
            : (document.getElementById("success3").style.display = "none");
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorField({
        ...errorField,
        otperror: "error",
      });
    }
  };

  const handleProfileUpdate = () => {
    localStorage.setItem("search", "Profile");
    localStorage.setItem("search2", "setting");

    setIsConditionWait(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("about", detailProvider.about);
    formdata.append("profile_headline", detailProvider.profile_headline);
    // detailProvider.video.name
    //   ? formdata.append("video", detailProvider.video)
    //   : formdata.append("about", detailProvider.about);
    detailProvider.file_path.name
      ? formdata.append("file_path", detailProvider.file_path)
      : formdata.append("about", detailProvider.about);
    formdata.append("educationlevel", detailProvider.educationlevel);
    formdata.append("school", detailProvider.school);
    formdata.append("yearawarded", detailProvider.yearawarded);
    detailProvider.recommendationsfile.name
      ? formdata.append(
          "recommendationsfile",
          detailProvider.recommendationsfile
        )
      : formdata.append("about", detailProvider.about);
    formdata.append(
      "qualificationscertificatesname[]",
      certificates.qualificationscertificatesname
    );
    formdata.append(
      "qualificationscertificatesname[]",
      certificates.qualificationscertificatesname2
    );
    formdata.append(
      "qualificationscertificatesname[]",
      certificates.qualificationscertificatesname3
    );
    formdata.append(
      "qualificationscertificatesname[]",
      certificates.qualificationscertificatesname4
    );
    detailProvider.cv.name
      ? formdata.append("cv", detailProvider.cv)
      : formdata.append("about", detailProvider.about);
    detailProvider.qualificationscertificatesdoc.name
      ? formdata.append(
          "qualificationscertificatesdoc[]",
          detailProvider.qualificationscertificatesdoc
        )
      : formdata.append("about", detailProvider.about);
    formdata.append("nanynewbornexp", detailProvider.nanynewbornexp);
    formdata.append("nanyyearexp", experience);
    formdata.append("setareaexperties", detailProvider.setareaexperties);
    formdata.append("setexpmethods[]", JSON.stringify(methods));
    formdata.append("setexpiep", detailProvider.setexpiep);
    formdata.append("setyearexp", range);
    formdata.append("setallapplicable", JSON.stringify(setallapplicable2));
    formdata.append("nanyexpwithkid", detailProvider.nanyexpwithkid);
    formdata.append("tutorexp", tutorExperience);
    formdata.append("sepexpwithkid", detailProvider.sepexpwithkid);
    formdata.append("nanyallapplicable", JSON.stringify(allapplicable));
    formdata.append("sepallapplicable", JSON.stringify(sepallapplicable));
    formdata.append("sepexpmethods", JSON.stringify(allMethods));
    formdata.append("sepworkexp", workExperience);
    formdata.append("countrycode", contactCode.code);
    formdata.append("nanyperhrrate", nannyRate.min + "-" + nannyRate.max);
    formdata.append("tutorliketoteach", JSON.stringify(qualifications));
    formdata.append(
      "tutorintrestedonlinecls",
      detailProvider.tutorintrestedonlinecls
    );
    formdata.append(
      "tutorintrestedinschool",
      detailProvider.tutorintrestedinschool
    );
    formdata.append("tutorallapplicable", JSON.stringify(selectList));
    formdata.append("tutorperhrrate", tutorRate.min + "-" + tutorRate.max);
    formdata.append("tutorworkwithnochild", generalChildren);
    formdata.append("tutorprefchildage", JSON.stringify(generalChildrenAge));
    formdata.append("tutorstartdate", detailProvider.tutorstartdate);
    formdata.append("nanyhrrate", nannyRate.min + "-" + nannyRate.max);
    formdata.append("nanyintrestedin", JSON.stringify(tutorTime));
    formdata.append(
      "nanyintrestedinschool",
      detailProvider.nanyintrestedinschool
    );
    formdata.append("nanyworkwithnochild", nchildren);
    formdata.append("nanystartdate", detailProvider.nanystartdate);
    formdata.append("nanyprefchildage", JSON.stringify(childrenAge));
    formdata.append("fulltime", JSON.stringify(detailProvider.fulltime));
    formdata.append(
      "beforeschool",
      JSON.stringify(detailProvider.beforeschool)
    );
    formdata.append("afterschool", JSON.stringify(detailProvider.afterschool));
    formdata.append("overnight", JSON.stringify(detailProvider.overnight));
    formdata.append("weekends", JSON.stringify(detailProvider.weekends));
    formdata.append("englishlevel", detailProvider.englishlevel);
    formdata.append("italianlevel", detailProvider.italianlevel);
    formdata.append("spanishlevel", detailProvider.spanishlevel);
    formdata.append("germanlevel", detailProvider.germanlevel);
    formdata.append("chineselevel", detailProvider.chineselevel);
    formdata.append("frenchlevel", detailProvider.frenchlevel);
    formdata.append("otherlangname", detailProvider.otherlangname);
    formdata.append("otherlevel", detailProvider.otherlevel);
    // formdata.append("phoneno", detailprovider.phone);
    detailProvider.backgrounddoc.name
      ? formdata.append("backgrounddoc", detailProvider.backgrounddoc)
      : formdata.append("about", detailProvider.about);
    formdata.append("backgroundstatus", detailProvider.backgroundstatus);
    formdata.append("smoke", habit.smoke);
    formdata.append("carorlicence", habit.licence);
    formdata.append("cooking", habit.kids);
    formdata.append("lighthousework", habit.housework);
    formdata.append("traveling", habit.family);
    formdata.append("workingabroad", detailProvider.workingabroad);
    formdata.append("livewithfamily", detailProvider.livewithfamily);
    formdata.append("anyallergies", detailProvider.anyallergies);
    formdata.append(
      "anyallergiesdescription",
      detailProvider.anyallergiesdescription
    );
    formdata.append("medicalcondition", detailProvider.medicalcondition);
    formdata.append(
      "medicalconditiondescription",
      detailProvider.medicalconditiondescription
    );
    formdata.append(
      "plateformsocialmedia",
      detailProvider.plateformsocialmedia
    );
    formdata.append("facebookverify", detailProvider.facebookverify);
    formdata.append("twitterverify", detailProvider.twitterverify);
    formdata.append("linkdinverify", detailProvider.linkdinverify);
    formdata.append("instaverify", detailProvider.instaverify);
    formdata.append("seterhrrate", rate.min + "-" + rate.max);
    formdata.append("seperhrrate", perRate.min + "-" + perRate.max);
    formdata.append("yearofexpasteacher", detailProvider.yearofexpasteacher);

    formdata.append(
      "active_service",
      Object.keys(activeProfession).length > 0
        ? JSON.stringify(activeProfession)
        : ""
    );
    formdata.append(
      "inactive_service",
      Object.keys(profession).length > 0
        ? JSON.stringify(profession)
        : ""
    );
    formdata.append(
      "alertJobposts",
      detailProvider.alertJobposts != "" ? detailProvider.alertJobposts : "yes"
    );
    formdata.append("calanderlastupdate", detailProvider.calanderlastupdate);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateprovider", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setIsConditionWait(false);
        console.log("error", error);
      });
  };

  const handleProfileUpdateNew = () => {
    setUploadPhoto(false);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("file_path", photo);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setUploadPhoto(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
      handleProfileData();
  }, []);

  const handleCalendarData = (name, e) => {
    console.log(name, e);
    setDetailProvider({ ...detailProvider, [name]: e });
  };
  
  const handleResponse = (data) => {
    if (data.profile.type) {
      setDetailProvider({ ...detailProvider, linkdinverify: true });
    } else {
      setDetailProvider({ ...detailProvider, facebookverify: true });
    }
  };

  const handleErrorFields = (error) => {
    console.log({ error });
  };

  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setPhoto(event.target.files[0]);
    }
  };

  return (
    <div className="Account daskshow editview providerprofile">
      <h2>{t("Profile Overview")}</h2>
      <h3>
        <MarkRoundIcon width={20} height={20} />
        {t(
          "chance-getting-hire"
        )}
      </h3>
      <div className="profile_full left_side_section ">
        <div class="profile_box">
          <h2>{t("Profile Overview")}</h2>
          <div class="profile_box_social">
            <div class="profile_box_social_sec1">
              <img
                src={window.location.origin + "/images/share_icon.svg"}
                alt=""
              />
              {detail.phoneVerifiedStatus == 1 ? (
                <img
                  src={window.location.origin + "/images/nany_phone.svg"}
                  alt=""
                />
              ) : (
                <PhoneIcon />
              )}
              <img
                src={window.location.origin + "/images/nany_msg.svg"}
                alt=""
              />
              {detail.facebookverify != null || detail.linkdinverify != null ? (
                <img
                  src={window.location.origin + "/images/nany_cont.svg"}
                  alt=""
                />
              ) : (
                <ServiceIcon />
              )}
              {detail.docsStatus == "Yes" ? (
                <img src={window.location.origin + "/images/ok.svg"} alt="" />
              ) : (
                <ConfirmIcon />
              )}
            </div>
            {localStorage.getItem("user_type") == "parents" ? (
              ""
            ) : (
              <div class="profile_box_social_sec2">
                {detail.service_type && detail.service_type.tab1 == "Nanny" ? (
                  <img
                    src={window.location.origin + "/images/nany_pur.svg"}
                    alt=""
                  />
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab2 ? (
                  <img
                    src={
                      window.location.origin + "/images/special_education.svg"
                    }
                    alt=""
                  />
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab3 ? (
                  <img
                    src={window.location.origin + "/images/professional.svg"}
                    alt=""
                  />
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab4 ? (
                  <img
                    src={window.location.origin + "/images/tutorform.svg"}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div class="profile_pic_sec">
            {/* <img src="img/left_pic.png" alt="" /> */}
            <div class="profile_pic">
              <img
                src={api + "/assets/images/users/" + detail.file_path}
                width="80"
                height="80"
                alt=""
              />
              <div class="edit_icon">
                <Link to="" onClick={(e) => setUploadPhoto(true)}>
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          <div class="profile_detail">
            <p>
              <VideoIcon color={"#C8B483"} />
              {detail.first_name + " " + detail.last_name}
              <img
                src={window.location.origin + "/images/img_heart.svg"}
                alt=""
              />
            </p>
            <div class="profilestart_video">
              {detail?.reviewAvg ? (
                <>
                  {[...Array(detail?.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <StarIcon />;
                  })}
                  {[...Array(5 - detail?.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <WhiteStarIcon />;
                  })}
                </>
              ) : (
                ""
              )}
              <span> ({detail.reviewcount})</span>
            </div>
          </div>
          <div class="profile_functions">
            <div class="members">
              <h5>
                {new Date().getFullYear() -
                  new Date(detail.created_at).getFullYear() >
                0
                  ? new Date().getFullYear() -
                    new Date(detail.created_at).getFullYear() +
                    t(" YRS")
                  : 0 + t(" YRS")}
              </h5>
              <h6>{t("Member")}</h6>
            </div>
            <div class="vi"></div>
            {localStorage.getItem("user_type") == "parents" ? (
              <div class="applications">
                <h5>{detail.jobs}</h5>
                <h6>{t("Job posts")}</h6>
              </div>
            ) : (
              <div class="applications">
                <h5>{detail.jobApplicationcount}</h5>
                <h6>{t("text-applications")}</h6>
              </div>
            )}
            <div class="vi"></div>
            <div class="hiring">
              <h5>{detail.hiringcount}</h5>
              <h6>{t("Hirings")}</h6>
            </div>
          </div>
        </div>
      </div>

      <div className="about_edit">
        <p>
          <h4>{t("About me_provider")}</h4>
          <PawsIcon />
          <span>{detail.about}</span>
        </p>
        <div className="rating">
          <h5>{t("Average rating")}</h5>

          <div className="score" style={{ borderColor: "#7D2B8B" }}>
            <span style={{ color: "#7D2B8B" }}>
              {detail.reviewAvg} <br />
              {detail?.reviewcount ? (
                <>
                  {[...Array(detail?.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <StarIcon />;
                  })}
                  {[...Array(5 - detail?.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <WhiteStarIcon />;
                  })}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
          <p>
            {detail?.reviewAvg == 5
              ? t(
                  "excellent-outstanding-cooperation"
                )
              : detail?.reviewAvg == 4
              ? t(
                  "visit-profile-candidate"
                )
              : detail?.reviewAvg == 3
              ? t(
                  "remember-outstanding"
                )
              : detail?.reviewAvg <= 2
              ? t(
                  "post-falls-about-stars"
                )
              : ""}
          </p>
        </div>
        <div className="rating">
          <h5>{t("Profile views")}</h5>
          <div className="score">
            <span>{detail.visitorcount}</span>
          </div>
          <p>
            <strong>{t("Tip:")}</strong>
            {t(
              "keep-profile-views"
            )}
          </p>
        </div>
      </div>
      <div
        className={generalInfo.About == "active" ? "active personal" : "personal"}
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              About: generalInfo.About == "" ? "active" : "",
            })
          }
        >
          {t("text-about")}
        </h3>
        {generalInfo.About == "active" ? (
          <div className="editabout editkids edit-about">
            {editGeneralIngo.About == "" ? (
              <>
                <button onClick={(e) => setEditGeneralInfo({ ...editGeneralIngo, About: "edit" })}>
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                <label>
                  <span className="half">{t("Your Profile headline")}</span>
                  <span> <strong>{detail.profile_headline} </strong> </span>
                </label>

                <label>
                  <span className="half">{t("About me_provider")}</span>
                  <span> <strong> {detail.about} </strong> </span>
                </label>
              </>
            ) : (
                <div className="Profile_complete">
                <div className="detail parents1 stap1">
                  <div className="form_group full">
                    <label>
                      {t("Your profile headline")}
                      <span
                        className={
                          errorFields.profile_headline != "" ? "starred" : ""
                        }
                      >
                        *
                      </span>
                    </label>
                    <textarea
                      rows={2}
                      className={
                        errorFields.profile_headline != "" ? "bordererror" : ""
                      }
                      placeholder={
                        detail.service_type
                          ? t("text-experience-gap") +
                            Object.values(detail.service_type)[0] +
                            t("with 10 years of experience.")
                          : ""
                      }
                      maxlength="50"
                      name="message"
                      defaultValue={detailProvider.profile_headline}
                      onChange={(e) => {
                        setErrorFields({ ...errorFields, profile_headline: "" });
                        setDetailProvider({
                          ...detailProvider,
                          profile_headline: e.target.value,
                        });
                      }}
                    ></textarea>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                    <span>
                      {t("number-characters")}
                      {50 - detailProvider.profile_headline.length}
                    </span>
                  </div>
                  <div className="form_group full sec">
                    <label>
                      {t("Tell us more about yourself")}
                      <span className={errorFields.about != "" ? "starred" : ""}>
                        *
                      </span>
                    </label>
                    <textarea
                      rows={4}
                      className={errorFields.about != "" ? "bordererror" : ""}
                      placeholder={
                        detail.service_type
                          ? Object.keys(detail.service_type).filter(
                              (e) => e == "tab2"
                            )[0]
                            ? t(
                                "myNameElena"
                              )
                            : Object.keys(detail.service_type).filter(
                                (e) => e == "tab1"
                              )[0]
                            ? t(
                                "workingMyPassion"
                              )
                            : Object.keys(detail.service_type).filter(
                                (e) => e == "tab4"
                              )[0]
                            ? t(
                                "elenaTutor"
                              )
                            : Object.keys(detail.service_type).filter(
                                (e) => e == "tab3"
                              )[0]
                            ? t(
                                "elenaSpecialEducator"
                              )
                            : ""
                          : ""
                      }
                      maxlength="500"
                      name="message"
                      defaultValue={detailProvider.about}
                      onChange={(e) => {
                        setErrorFields({ ...errorFields, about: "" });
                        setDetailProvider({
                          ...detailProvider,
                          about: e.target.value,
                        });
                      }}
                    ></textarea>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                    <span>
                      {t("number-characters")}
                      {500 - detailProvider.about.length}
                    </span>
                  </div>
                  <div className="form_group">
                    <label>
                      {t("Upload your profile picture")}
                      <span className="smallpop">
                        <strong>{t("text-choice")}</strong> {t("And")}
                        <strong>{t("text-premium")}</strong> {t("upload-photo")}
                      </span>
                      <span
                        className={errorFields.file_path != "" ? "starred" : ""}
                      >
                        *
                      </span>
                    </label>
                    <div className="upload">
                      <input
                        type="file"
                        className={
                          errorFields.file_path != "" ? "bordererror" : ""
                        }
                        placeholder={t("choose-file")}
                        onChange={(e) => {
                          setErrorFields({ ...errorFields, file_path: "" });
                          setDetailProvider({
                            ...detailProvider,
                            file_path: e.target.files[0],
                          });
                        }}
                        accept="image/*"
                      />
                      <span>
                        {detailProvider.file_path != ""
                          ? detailProvider.file_path.name
                            ? detailProvider.file_path.name
                            : detailProvider.file_path
                          : t("choose-file")}
                      </span>
                      <button>
                        <UploadIcon />
                      </button>
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                  {/*<div className="form_group">*/}
                  {/*  <label>*/}
                  {/*    {t("Upload video")}*/}
                  {/*    <span className="smallpop">*/}
                  {/*      {t("feature-available")}*/}
                  {/*      <strong>{t("text-premium")}</strong> {t("members.")}*/}
                  {/*    </span>*/}
                  {/*    {t("presentation of yourself")}*/}
                  {/*  </label>*/}
                  {/*  <div className="upload">*/}
                  {/*    <input*/}
                  {/*      type="file"*/}
                  {/*      placeholder={t("choose-file")}*/}
                  {/*      onChange={(e) =>*/}
                  {/*        setDetailProvider({*/}
                  {/*          ...detailProvider,*/}
                  {/*          video: e.target.files[0],*/}
                  {/*        })*/}
                  {/*      }*/}
                  {/*      accept="video/*"*/}
                  {/*    />*/}
                  {/*    <span>*/}
                  {/*      {detailProvider.video != ""*/}
                  {/*        ? detailProvider.video.name*/}
                  {/*          ? detailProvider.video.name*/}
                  {/*          : detailProvider.video*/}
                  {/*        : t("choose-file")}*/}
                  {/*    </span>*/}
                  {/*    <button>*/}
                  {/*      <UploadIcon />*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*  /!* <div className='errorfield'>{error.message}</div>*!/*/}
                  {/*</div>*/}
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={generalInfo.kids == "active" ? "active personal" : "personal"}
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({ ...generalInfo, kids: generalInfo.kids == "" ? "active" : "" })
          }
        >
          {t("Education")}
        </h3>
        {generalInfo.kids == "active" ? (
          <div className="editkids ">
            {editGeneralIngo.kids == "" ? (
              <>
                <button onClick={(e) => setEditGeneralInfo({ ...editGeneralIngo, kids: "edit" })}>
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>
                <div className="educationun">
                  <div class="left2">
                    <label>
                      <span class="half">{t("Education Level")} </span>
                      <span className="">
                        <strong>{t(detail.educationlevel)}</strong>
                      </span>
                    </label>
                    <label>
                      <span class="half">{t("School/ university")} </span>
                      <span>
                        <strong>{t(detail.school)}</strong>
                      </span>
                    </label>
                    <label>
                      <span class="half">{t("Year awarded")} </span>
                      <span>
                        <strong>{t(detail.yearawarded)}</strong>
                      </span>
                    </label>
                  </div>
                  {(certificates.qualificationscertificatesname ||
                      certificates.qualificationscertificatesname2 ||
                      certificates.qualificationscertificatesname3 ||
                      certificates.qualificationscertificatesname4) && (
                      <div className="right2">
                        <label>{t("Qualifications and certificates")} </label>
                        <label>
                          <br/>
                          <span>
                        {certificates.qualificationscertificatesname ==
                        "First Aid" ? (
                            <strong>
                              <DoneIcon/>
                              {t(certificates.qualificationscertificatesname)}
                            </strong>
                        ) : (
                            ""
                        )}
                            {certificates.qualificationscertificatesname3 ==
                            "Board Certified Nurse" ? (
                                <strong>
                                  <DoneIcon/>
                                  {t(certificates.qualificationscertificatesname3)}
                                </strong>
                            ) : (
                                ""
                            )}
                            {certificates.qualificationscertificatesname2 ==
                            "Montessori" ? (
                                <strong>
                                  <DoneIcon/>
                                  {t(certificates.qualificationscertificatesname2)}
                                </strong>
                            ) : (
                                ""
                            )}
                            {certificates.qualificationscertificatesname4 ? (
                                <strong>
                                  <DoneIcon/>
                                  {t(certificates.qualificationscertificatesname4)}
                                </strong>
                            ) : (
                                ""
                            )}
                      </span>
                        </label>
                      </div>
                  )
                  }
                </div>
                <br/>
              </>
            ) : (
                <div className="Profile_complete">
                  <div className="detail job_performance your_kids stap2">
                    <div className="form_group">
                      <label>
                        {t("Education Level")}
                        <span
                            className={
                              errorFields.educationlevel != "" ? "starred" : ""
                            }
                        >
                        *
                      </span>
                      </label>
                      <div className="text">
                      <span
                          onClick={(e) => {
                            setErrorFields({...errorFields, educationlevel: ""});
                            setlavelo(laveo ? false : true);
                          }}
                      >
                        {detailProvider.educationlevel
                            ? detailProvider.educationlevel
                            : t("choose-list")}
                      </span>
                        <div classes="select_data">
                          <ul
                              style={
                                laveo ? {display: "block"} : {display: "none"}
                              }
                          >
                            <li
                                onClick={(e) => {
                                  setlavelo(laveo ? false : true);
                                  setDetailProvider({
                                    ...detailProvider,
                                    educationlevel: t("High school"),
                                  });
                                }}
                            >
                            {t("High school")}
                          </li>
                          <li
                            onClick={(e) => {
                              setlavelo(laveo ? false : true);
                              setDetailProvider({
                                ...detailProvider,
                                educationlevel: t("Bachelor"),
                              });
                            }}
                          >
                            {t("Bachelor")}
                          </li>
                          <li
                            onClick={(e) => {
                              setlavelo(laveo ? false : true);
                              setDetailProvider({
                                ...detailProvider,
                                educationlevel: t("Master"),
                              });
                            }}
                          >
                            {t("Master")}
                          </li>
                          <li
                            onClick={(e) => {
                              setlavelo(laveo ? false : true);
                              setDetailProvider({
                                ...detailProvider,
                                educationlevel: t("PhD"),
                              });
                            }}
                          >
                            {t("PhD")}
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                  <div className="form_group">
                    <label>
                      {t("Name of your school/university")}
                      <span
                        className={errorFields.school != "" ? "starred" : ""}
                      >
                        *
                      </span>
                    </label>
                    <div className="text">
                      <input
                        type="text"
                        placeholder={t("text-type")}
                        onChange={(e) => {
                          setErrorFields({ ...errorFields, school: "" });
                          setDetailProvider({
                            ...detailProvider,
                            school: e.target.value,
                          });
                        }}
                        defaultValue={detailProvider.school}
                      />
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                  <div className="form_group">
                    <label>
                      {t("Graduation Status")}
                      <span
                        className={
                          errorFields.yearawarded != "" ? "starred" : ""
                        }
                      >
                        *
                      </span>
                    </label>
                    <div className="text">
                      <span
                        onClick={(e) => {
                          setErrorFields({ ...errorFields, yearawarded: "" });
                          setawarded(awarded ? false : true);
                        }}
                      >
                        {detailProvider.yearawarded
                          ? detailProvider.yearawarded
                          : t("choose-list")}
                      </span>
                      <div classes="select_data">
                        <ul
                          style={
                            awarded ? { display: "block" } : { display: "none" }
                          }
                        >
                          <li
                            onClick={(e) => {
                              setawarded(awarded ? false : true);
                              setDetailProvider({
                                ...detailProvider,
                                yearawarded: t("in-process"),
                              });
                            }}
                          >
                            {t("in-process")}
                          </li>
                          <li
                            onClick={(e) => {
                              setawarded(awarded ? false : true);
                              setDetailProvider({
                                ...detailProvider,
                                yearawarded: t("text-graduated"),
                              });
                            }}
                          >
                            {t("text-graduated")}
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                  <div className="form_group qualification set">
                    <label>
                      {t("professional-qualifications-certificates")}
                    </label>
                    <div className="checkbox">
                      <ul
                        onClick={(e) =>
                          setErrorFields({
                            ...errorFields,
                            qualificationscertificatesname: "",
                          })
                        }
                      >
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            onClick={(e) => {
                              if (e.target.checked) {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname:
                                    t("First Aid"),
                                });
                              } else {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname: "",
                                });
                              }
                            }}
                            checked={
                              certificates.qualificationscertificatesname ==
                              t("First Aid")
                                ? true
                                : false
                            }
                          />
                          <span> {t("First Aid")}</span>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            onClick={(e) => {
                              if (e.target.checked) {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname3: t(
                                    "Board Certified Nurse"
                                  ),
                                });
                              } else {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname3: "",
                                });
                              }
                            }}
                            checked={
                              certificates.qualificationscertificatesname3 ==
                              t("Board Certified Nurse")
                                ? true
                                : false
                            }
                          />
                          <span> {t("Board Certified Nurse")}</span>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            onClick={(e) => {
                              if (e.target.checked) {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname2:
                                    t("Montessori"),
                                });
                              } else {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname2: "",
                                });
                              }
                            }}
                            checked={
                              certificates.qualificationscertificatesname2 ==
                              t("Montessori")
                                ? true
                                : false
                            }
                          />
                          <span> {t("Montessori")}</span>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name=""
                            onClick={(e) => {
                              if (e.target.checked) {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname4: "e",
                                });
                              } else {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname4: "",
                                });
                              }
                            }}
                            checked={
                              certificates.qualificationscertificatesname4 != ""
                                ? true
                                : false
                            }
                          />

                          <span>
                            
                            <input
                              type="text"
                              placeholder={t("text-other")}
                              onChange={(e) => {
                                setCertificates({
                                  ...certificates,
                                  qualificationscertificatesname4:
                                    e.target.value,
                                });
                              }}
                              defaultValue={
                                certificates.qualificationscertificatesname4
                              }
                              className={
                                certificates.qualificationscertificatesname4 ==
                                "e"
                                  ? "bordererror"
                                  : ""
                              }
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          generalInfo.Experience == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              Experience: generalInfo.Experience == "" ? "active" : "",
            })
          }
        >
          {t("Work Experience")}
        </h3>
        {generalInfo.Experience == "active" ? (
          <div className="editkids editabout">
            {editGeneralIngo.Experience == "" ? (
              <>
                <button
                  onClick={(e) => setEditGeneralInfo({ ...editGeneralIngo, Experience: "edit" })}
                >
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                {detail.service_type && detail.service_type.tab1 ? (
                  <div>
                    <h2 class="border" style={{
                      color: profession && profession.tab1 ? 'gray' : '#7D2B8B',
                    }}>
                      <img
                        src={
                            profession && profession.tab1 ?
                            window.location.origin + "/images/nany_pur_gray.svg"    :
                            window.location.origin + "/images/nany_pur.svg"
                      }
                      />
                      {t("text-nanny")}
                    </h2>
                    {detail.nanynewbornexp == "Yes" ? (
                      <label>
                        
                        <span>
                          <strong>
                            { profession && profession.tab1 ? (
                                <DoneIconGray />
                            ) : (
                                <DoneIcon />
                            )
                            }
                            {t("Experience with newborns")}
                          </strong>
                        </span>
                      </label>
                    ) : (
                      ""
                    )}
                    {detail.nanyyearexp ? (
                      <label>
                        {t("Years of work experience")}
                        <span className="cir" style={{
                          background: profession && profession.tab1 ? 'gray' : '#7D2B8B',
                        }}>{detail.nanyyearexp}</span>
                      </label>
                    ) : (
                      ""
                    )}
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab2 ? (
                  <div className="nannyediy">
                    <h2 class="border" style={{
                      color: profession && profession.tab2 ? 'gray' : '#7D2B8B',
                    }}>
                      <img
                        src={
                          profession && profession.tab2 ?
                              window.location.origin + "/images/special_education_gray.svg" :
                              window.location.origin + "/images/special_education.svg"
                        }
                      />
                      {t("spec-education-teacher")}
                    </h2>
                    <label>{t("Experience in Special Education Field")} </label>
                    <label style={{
                      marginBottom: "1rem",
                    }}>
                      <br />
                      <span>
                        {detail.setallapplicable.map((e) => {
                          return (
                            <strong>
                              { profession && profession.tab2 ? (
                                  <DoneIconGray />
                                  ) : (
                                  <DoneIcon />
                                )
                              }
                              {t(e.name)}
                            </strong>
                          );
                        })}
                      </span>
                    </label>
                    <label style={{
                      marginBottom: "1rem",
                    }}>
                      {t("Experience with following methods")}:
                      <br/>
                      {methods.setexpmethods && (
                          <span>
                              <strong>
                                <DoneIcon/>
                                {t(methods.setexpmethods)}
                              </strong>
                      </span>
                      )}
                      {methods.setexpmethods2 && (
                          <span>
                              <strong>
                                { profession && profession.tab2 ? (
                                    <DoneIconGray />
                                ) : (
                                    <DoneIcon />
                                )
                                }
                                {t(methods.setexpmethods2)}
                              </strong>
                      </span>
                      )}
                      {methods.setexpmethods3 && (
                          <span>
                              <strong>
                                { profession && profession.tab2 ? (
                                    <DoneIconGray />
                                ) : (
                                    <DoneIcon />
                                )
                                }
                                {t(methods.setexpmethods3)}
                              </strong>
                      </span>
                      )}
                      {methods.setexpmethods4 && (
                          <span>
                              <strong>
                                { profession && profession.tab2 ? (
                                    <DoneIconGray />
                                ) : (
                                    <DoneIcon />
                                )
                                }
                                {t(methods.setexpmethods4)}
                              </strong>
                      </span>
                      )}
                    </label>
                    <label style={{
                      marginBottom: "1rem",
                    }}>
                      {t("IEP development")}:
                      <span className="cir" style={{
                        background: profession && profession.tab2 ? 'gray' : '#7D2B8B',
                      }}>{detail.setexpiep ? t("Yes") : t("No")}</span>
                    </label>
                    {detail.setyearexp ? (
                        <label style={{
                          marginBottom: "1rem",
                        }}>
                          {t("Years of work experience")}
                          <span className="cir" style={{
                            background: profession && profession.tab2 ? 'gray' : '#7D2B8B',
                          }}>{detail.setyearexp}</span>
                        </label>
                    ) : (
                        ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {detail.service_type && detail.service_type.tab3 ? (
                  <div className="nannyediy">
                    <h2 class="border" style={{
                      color: profession && profession.tab3 ? 'gray' : '#7D2B8B',
                    }}>
                      <img
                          src={
                            profession && profession.tab3 ?
                                window.location.origin + "/images/professional_gray.svg" :
                                window.location.origin + "/images/professional.svg"
                          }
                      />
                      {t("spec-education-paraprofessional")}
                    </h2>
                    <label>{t("Experience in Special Education Field")} </label>
                    <label>
                      <br/>
                      <span>
                        {detail.sepallapplicable &&
                            detail.sepallapplicable.map((e) => {
                              return (
                                  <strong>
                                    { profession && profession.tab3 ? (
                                        <DoneIconGray />
                                    ) : (
                                        <DoneIcon />
                                    )
                                    }
                                {e.name}
                              </strong>
                            );
                          })}
                      </span>
                    </label>
                    {detail.sepworkexp ? (
                      <label>
                        {t("Years of work experience")}
                        <span className="cir" style={{
                          background: profession && profession.tab3 ? 'gray' : '#7D2B8B',
                        }}>{detail.sepworkexp}</span>
                      </label>
                    ) : (
                      ""
                    )}
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}

                {detail.service_type && detail.service_type.tab4 ? (
                  <div className="nannyediy">
                    <h2 class="border" style={{
                      color: profession && profession.tab4 ? 'gray' : '#7D2B8B',
                    }}>
                      <img
                          src={
                            profession && profession.tab4 ?
                                window.location.origin + "/images/tuter_gray.svg" :
                                window.location.origin + "/images/tuter.svg"
                          }
                      />
                      {t("text-tutor")}
                    </h2>
                    {detail.tutorexp ? (
                        <label>
                          {t("Years of work experience")}
                          <span className="cir" style={{
                            background: profession && profession.tab4 ? 'gray' : '#7D2B8B',
                          }}>{detail.tutorexp}</span>
                        </label>
                    ) : (
                        ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="Profile_complete charactor">
                <div className="detail your_kids work-experience parents1 stap1">
                  {detail.service_type && detail.service_type.tab1 ? (
                    <div className="nanny">
                      <h2 className="border">
                        <img
                          src={window.location.origin + "/images/nany_pur.svg"}
                        />
                        {t("text-nanny")}
                        <div class="personal_preferences">
                          <label class="switchedit">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                setShowModal(true);
                                setIsDisable(1);
                              }}
                              checked={
                                Object.keys(profession).filter(
                                  (e) => e == "tab1"
                                )[0]
                                  ? false
                                  : true
                              }
                            />
                            <span class="slideredit roundedit"></span>
                          </label>
                        </div>
                      </h2>
                      <div className="form_group full space">
                        <label>
                          {t("Do you have experience with newborns?")}
                          <span
                            className={
                              errorFields.nanynewbornexp != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox">
                          <ul
                            onClick={(w) =>
                              setErrorFields({
                                ...errorFields,
                                nanynewbornexp: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name="quality"
                                checked={
                                  detailProvider.nanynewbornexp == "Yes"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    nanynewbornexp: "Yes",
                                  })
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="quality"
                                checked={
                                  detailProvider.nanynewbornexp == "No"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    nanynewbornexp: "No",
                                  })
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                      {detail.service_type &&
                      !detail.service_type.tab2 &&
                      !detail.service_type.tab3 ? (
                        <>
                          <div className="form_group full space">
                            <label>
                              {t(
                                "experience-working-kids"
                              )}
                              <span
                                className={
                                  errorFields.nanyexpwithkid != ""
                                    ? "starred"
                                    : ""
                                }
                              >
                                *
                              </span>
                            </label>
                            <div className="checkbox">
                              <ul
                                onClick={(e) =>
                                  setErrorFields({
                                    ...errorFields,
                                    nanyexpwithkid: "",
                                  })
                                }
                              >
                                <li>
                                  <input
                                    type="radio"
                                    name="quality2"
                                    checked={
                                      detailProvider.nanyexpwithkid === "Yes"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      setDetailProvider({
                                        ...detailProvider,
                                        nanyexpwithkid: "Yes",
                                      })
                                    }
                                  />
                                  <span> {t("confirm")}</span>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name="quality2"
                                    checked={
                                      detailProvider.nanyexpwithkid === "No"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      setDetailProvider({
                                        ...detailProvider,
                                        nanyexpwithkid: "No",
                                      })
                                    }
                                  />
                                  <span> {t("objection")}</span>
                                </li>
                              </ul>
                            </div>
                            {/* <div className='errorfield'>{error.message}</div>*/}
                          </div>
                          {detailProvider.nanyexpwithkid == "Yes" ? (
                            <div className="job_performance">
                              <div className="form_group full">
                                <label>
                                  {t("select-applicable")}
                                  <span
                                    className={
                                      errorFields.nanyallapplicable != ""
                                        ? "starred"
                                        : ""
                                    }
                                  >
                                    *
                                  </span>
                                </label>
                                <div className="customselect inp">
                                  <input
                                    className="keyword"
                                    type="text"
                                    placeholder={t("choose-list")}
                                    value={allapplicable.map((e) => {
                                      return e.name;
                                    })}
                                  />
                                  <div
                                    className="overflow"
                                    id="over"
                                    onClick={(e) => handleElementById("cate2", "over")}
                                  ></div>

                                  <div
                                    className="option"
                                    id="cate2"
                                    onClick={(e) =>
                                      setErrorFields({
                                        ...errorFields,
                                        nanyallapplicable: "",
                                      })
                                    }
                                  >
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("text-adhd"))
                                        }
                                      />
                                      <h3>{t("text-adhd")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(
                                            t("auditory-impairment")
                                          )
                                        }
                                      />
                                      <h3>{t("auditory-impairment")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("autism"))
                                        }
                                      />
                                      <h3>{t("autism")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("cerebral-palsy"))
                                        }
                                      />
                                      <h3>{t("cerebral-palsy")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("down-syndrome"))
                                        }
                                      />
                                      <h3>{t("down-syndrome")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("dyslexia"))
                                        }
                                      />
                                      <h3>{t("dyslexia")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(
                                            t("intellectual-disability")
                                          )
                                        }
                                      />
                                      <h3>{t("intellectual-disability")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(
                                            t("moderate-disability")
                                          )
                                        }
                                      />
                                      <h3>{t(t("moderate-disability"))} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(
                                            t("orthopedic-impairment")
                                          )
                                        }
                                      />
                                      <h3>{t("orthopedic-impairment")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("learn-impairment"))
                                        }
                                      />
                                      <h3>{t("learn-impairment")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("learn-disabilities"))
                                        }
                                      />
                                      <h3>{t("learn-disabilities")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("visual-impairment"))
                                        }
                                      />
                                      <h3>{t("visual-impairment")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption3(t("text-other"))
                                        }
                                      />
                                      <h3>{t("text-other")} </h3>
                                      <span></span>
                                    </p>
                                  </div>

                                  <span
                                    onClick={(e) => handleElementById("cate2", "over")}
                                  >
                                    <ArrowUpIcon />
                                  </span>
                                </div>
                                {/* <div className='errorfield'>{error.message}</div>*/}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <div className="form_group full">
                        <label>
                          {t("Years of work experience")}
                          <span
                            className={
                              errorFields.nanyyearexp != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="rang">
                          <div
                            class="slider"
                            onClick={(w) =>
                              setErrorFields({ ...errorFields, nanyyearexp: "" })
                            }
                          >
                            <input
                              type="range"
                              min="0"
                              max="60"
                              onChange={(e) => {
                                setExperience(e.target.value);
                                setDetailProvider({
                                  ...detailProvider,
                                  nanyyearexp: parseInt(e.target.value),
                                });
                              }}
                              style={{
                                "background-size":
                                  (experience * 100) / 60 + "% 100% ",
                              }}
                              value={experience}
                            />
                            <ul>
                              <li>0</li>
                              <li>15</li>
                              <li>30</li>
                              <li>45</li>
                              <li>60</li>
                            </ul>
                          </div>
                          <span id="rangeValue">{experience}</span>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                      <br />
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  {detail.service_type && detail.service_type.tab2 ? (
                    <div className="special_education">
                      <h2 className="border">
                        <img
                          src={
                            window.location.origin +
                            "/images/special_education.svg"
                          }
                        />
                        {t("education-teacher")}
                        <div class="personal_preferences">
                          <label class="switchedit">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                // if (e.target.checked) {
                                //     setinactiveprofessional({})
                                //     setactiveprofession(JSON.parse(sessionStorage.getItem("service")))
                                // }
                                setShowModal(true);
                                setIsDisable(2);
                              }}
                              checked={
                                Object.keys(profession).filter(
                                  (e) => e == "tab2"
                                )[0]
                                  ? false
                                  : true
                              }
                            />
                            <span class="slideredit roundedit"></span>
                          </label>
                        </div>
                      </h2>
                      <div className="job_performance">
                        <div className="form_group">
                          <label>
                            {t("Area of expertise (select all applicable)")}
                            <span
                              className={
                                errorFields.setallapplicable != ""
                                  ? "starred"
                                  : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="customselect inp">
                            <input
                              className="keyword"
                              type="text"
                              placeholder={t("choose-list")}
                              value={setallapplicable2.map((e) => {
                                return e.name;
                              })}
                            />
                            <div
                              className="overflow"
                              id="over29"
                              onClick={(e) => handleElementById("cate8", "over29")}
                            ></div>

                            <div
                              className="option"
                              id="cate8"
                              onClick={(e) =>
                                setErrorFields({
                                  ...errorFields,
                                  setallapplicable: "",
                                })
                              }
                            >
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("text-adhd")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) => selectoption6(t("text-adhd"))}
                                />
                                <h3>{t("text-adhd")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("auditory-impairment")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("auditory-impairment"))
                                  }
                                />
                                <h3>{t("auditory-impairment")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("autism")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) => selectoption6(t("autism"))}
                                />
                                <h3>{t("autism")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("cerebral-palsy")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("cerebral-palsy"))
                                  }
                                />
                                <h3>{t("cerebral-palsy")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("down-syndrome")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("down-syndrome"))
                                  }
                                />
                                <h3>{t("down-syndrome")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("dyslexia")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) => selectoption6(t("dyslexia"))}
                                />
                                <h3>{t("dyslexia")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) =>
                                        e.name == t("intellectual-disability")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("intellectual-disability"))
                                  }
                                />
                                <h3>{t("intellectual-disability")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("moderate-disability")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("moderate-disability"))
                                  }
                                />
                                <h3>{t("moderate-disability")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) =>
                                        e.name == t("orthopedic-impairment")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("orthopedic-impairment"))
                                  }
                                />
                                <h3>{t("orthopedic-impairment")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("learn-impairment")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("learn-impairment"))
                                  }
                                />
                                <h3>{t("learn-impairment")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("learn-disabilities")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("learn-disabilities"))
                                  }
                                />
                                <h3>{t("learn-disabilities")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("visual-impairment")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("visual-impairment"))
                                  }
                                />
                                <h3>{t("visual-impairment")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    setallapplicable2.filter(
                                      (e) => e.name == t("text-other")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption6(t("text-other"))
                                  }
                                />
                                <h3>{t("text-other")} </h3>
                                <span></span>
                              </p>
                            </div>

                            <span onClick={(e) => handleElementById("cate8", "over29")}>
                              <ArrowUpIcon />
                            </span>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                      </div>
                      <div className="form_group full qualification methad">
                        <label>
                          {t(
                            "experience-follow-methods"
                          )}
                          <span
                            className={
                              errorFields.setexpmethods != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox">
                          <ul
                            onClick={(e) =>
                              setErrorFields({
                                ...errorFields,
                                setexpmethods: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                checked={
                                  methods.setexpmethods &&
                                  methods.setexpmethods == "ABA"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setMethods({
                                      ...methods,
                                      setexpmethods: "ABA",
                                    });
                                  } else {
                                    setMethods({
                                      ...methods,
                                      setexpmethods: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("ABA")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                checked={
                                  !!(methods.setexpmethods2 &&
                                      methods.setexpmethods2 == "PECS")
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setMethods({
                                      ...methods,
                                      setexpmethods2: "PECS",
                                    });
                                  } else {
                                    setMethods({
                                      ...methods,
                                      setexpmethods2: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("PECS")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                checked={
                                  methods.setexpmethods3 &&
                                  methods.setexpmethods3 == "Floortime"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setMethods({
                                      ...methods,
                                      setexpmethods3: "Floortime",
                                    });
                                  } else {
                                    setMethods({
                                      ...methods,
                                      setexpmethods3: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("Floortime")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                checked={
                                  methods.setexpmethods4 &&
                                  methods.setexpmethods4 == "None"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setMethods({
                                      ...methods,
                                      setexpmethods4: "None",
                                    });
                                  } else {
                                    setMethods({
                                      ...methods,
                                      setexpmethods4: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("None")}</span>
                            </li>
                          </ul>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("Do you have an experience in")}
                          {" "}
                          <a>{t("text-iep")} </a>
                          <span className="smallpop">
                            {t("individual-education")}
                          </span>
                          {" "}
                          {t("development?")}
                          <span
                            className={
                              errorFields.setexpiep != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox">
                          <ul
                            onClick={(e) =>
                              setErrorFields({ ...errorFields, setexpiep: "" })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name="quality6"
                                checked={
                                  detailProvider.setexpiep == "Yes"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    setexpiep: "Yes",
                                  })
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="quality6"
                                checked={
                                  detailProvider.setexpiep == "No"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    setexpiep: "No",
                                  })
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                      <div className="form_group full">
                        <label>
                          {t("Years of work experience")}
                          <span
                            className={
                              errorFields.setyearexp != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="rang">
                          <div
                            class="slider"
                            onClick={(w) =>
                              setErrorFields({ ...errorFields, setyearexp: "" })
                            }
                          >
                            <input
                              type="range"
                              min="0"
                              max="60"
                              onChange={(e) => setRange(e.target.value)}
                              style={{
                                "background-size":
                                  (range * 100) / 60 + "% 100% ",
                              }}
                              value={range}
                            />
                            <ul>
                              <li>0</li>
                              <li>15</li>
                              <li>30</li>
                              <li>45</li>
                              <li>60</li>
                            </ul>
                          </div>
                          <span id="rangeValue">{range}</span>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                      <br />
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  {detail.service_type && detail.service_type.tab3 ? (
                    <div className="special_education">
                      <h2 className="border">
                        <img
                          src={
                            window.location.origin + "/images/professional.svg"
                          }
                        />
                        {t("education-paraprofessional")}
                        <div class="personal_preferences">
                          <label class="switchedit">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                // if (e.target.checked) {
                                //     setinactiveprofessional({})
                                //     setactiveprofession(JSON.parse(sessionStorage.getItem("service")))
                                // }
                                setShowModal(true);
                                setIsDisable(3);
                              }}
                              checked={
                                Object.keys(profession).filter(
                                  (e) => e == "tab3"
                                )[0]
                                  ? false
                                  : true
                              }
                            />
                            <span class="slideredit roundedit"></span>
                          </label>
                        </div>
                      </h2>
                      {detail.service_type && !detail.service_type.tab2 ? (
                        <>
                          <div className="form_group full">
                            <label>
                              {t(
                                "experience-working-kids"
                              )}
                              <span
                                className={
                                  errorFields.sepexpwithkid != ""
                                    ? "starred"
                                    : ""
                                }
                              >
                                *
                              </span>
                            </label>
                            <div className="checkbox">
                              <ul
                                onClick={(e) =>
                                  setErrorFields({
                                    ...errorFields,
                                    sepexpwithkid: "",
                                  })
                                }
                              >
                                <li>
                                  <input
                                    type="radio"
                                    name="quality3"
                                    checked={
                                      detailProvider.sepexpwithkid == "Yes"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      setDetailProvider({
                                        ...detailProvider,
                                        sepexpwithkid: "Yes",
                                      })
                                    }
                                  />
                                  <span> {t("confirm")}</span>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name="quality3"
                                    checked={
                                      detailProvider.sepexpwithkid == "No"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      setDetailProvider({
                                        ...detailProvider,
                                        sepexpwithkid: "No",
                                      })
                                    }
                                  />
                                  <span> {t("objection")}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {detailProvider.sepexpwithkid == "Yes" ? (
                            <div className="job_performance">
                              <div className="form_group">
                                <label>
                                  {t(
                                    "Area of expertise (select all applicable)"
                                  )}
                                  <span
                                    className={
                                      errorFields.sepallapplicable != ""
                                        ? "starred"
                                        : ""
                                    }
                                  >
                                    *
                                  </span>
                                </label>
                                <div className="customselect inp">
                                  <input
                                    className="keyword"
                                    type="text"
                                    placeholder={t("choose-list")}
                                    value={sepallapplicable.map((e) => {
                                      return e.name;
                                    })}
                                  />
                                  <div
                                    className="overflow"
                                    id="over31"
                                    onClick={(e) => handleElementById("cate75", "over31")}
                                  ></div>

                                  <div
                                    className="option"
                                    id="cate75"
                                    onClick={(e) =>
                                      setErrorFields({
                                        ...errorFields,
                                        setallapplicable: "",
                                      })
                                    }
                                  >
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("text-adhd"))
                                        }
                                      />
                                      <h3>{t("text-adhd")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(
                                            t("auditory-impairment")
                                          )
                                        }
                                      />
                                      <h3>{t("auditory-impairment")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("autism"))
                                        }
                                      />
                                      <h3>{t("autism")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("cerebral-palsy"))
                                        }
                                      />
                                      <h3>{t("cerebral-palsy")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("down-syndrome"))
                                        }
                                      />
                                      <h3>{t("down-syndrome")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("dyslexia"))
                                        }
                                      />
                                      <h3>{t("dyslexia")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(
                                            t("intellectual-disability")
                                          )
                                        }
                                      />
                                      <h3>{t("intellectual-disability")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(
                                            t("moderate-disability")
                                          )
                                        }
                                      />
                                      <h3>{t("moderate-disability")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(
                                            t("orthopedic-impairment")
                                          )
                                        }
                                      />
                                      <h3>{t("orthopedic-impairment")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("learn-impairment"))
                                        }
                                      />
                                      <h3>{t("learn-impairment")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("learn-disabilities"))
                                        }
                                      />
                                      <h3>{t("learn-disabilities")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("visual-impairment"))
                                        }
                                      />
                                      <h3>{t("visual-impairment")} </h3>
                                      <span></span>
                                    </p>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onClick={(a) =>
                                          selectoption5(t("text-other"))
                                        }
                                      />
                                      <h3>{t("text-other")} </h3>
                                      <span></span>
                                    </p>
                                  </div>

                                  <span
                                    onClick={(e) => handleElementById("cate75", "over31")}
                                  >
                                    <ArrowUpIcon />
                                  </span>
                                </div>
                                {/* <div className='errorfield'>{error.message}</div>*/}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form_group full">
                            <label>
                              {t(
                                "experience-follow-methods"
                              )}
                              <span
                                className={
                                  errorFields.sepexpmethods != ""
                                    ? "starred"
                                    : ""
                                }
                              >
                                *
                              </span>
                            </label>
                            <div className="checkbox">
                              <ul
                                onClick={(e) =>
                                  setErrorFields({
                                    ...errorFields,
                                    sepexpmethods: "",
                                  })
                                }
                              >
                                <li>
                                  <input
                                    type="radio"
                                    name="quality4"
                                    checked={
                                      allMethods.includes(t("ABA"))
                                    }
                                    onClick={(e) =>
                                        addNewMethod(t("ABA"))
                                    }
                                  />
                                  <span> {t("ABA")}</span>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name="quality4"
                                    checked={
                                      allMethods.includes(t("PECS"))
                                    }
                                    onClick={(e) =>
                                        addNewMethod(t("PECS"))
                                    }
                                  />
                                  <span> {t("PECS")}</span>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name="quality4"
                                    checked={
                                      allMethods.includes(t("Floortime"))
                                    }
                                    onClick={(e) =>
                                        addNewMethod(t("Floortime"))
                                    }
                                  />
                                  <span> {t("Floortime")}</span>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name="quality4"
                                    checked={
                                      allMethods.includes(t("None"))
                                    }
                                    onClick={(e) =>
                                        addNewMethod(t("None"))
                                    }
                                  />
                                  <span> {t("None")}</span>
                                </li>
                              </ul>
                            </div>
                            {/* <div className='errorfield'>{error.message}</div>*/}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="form_group full">
                        <label>
                          {t("Years of work experience")}
                          <span
                            className={
                              errorFields.sepworkexp != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="rang">
                          <div
                            class="slider"
                            onClick={(w) =>
                              setErrorFields({ ...errorFields, sepworkexp: "" })
                            }
                          >
                            <input
                              type="range"
                              min="0"
                              max="60"
                              onChange={(e) => setWorkExperience(e.target.value)}
                              style={{
                                "background-size":
                                  (workExperience * 100) / 60 + "% 100% ",
                              }}
                              value={workExperience}
                            />
                            <ul>
                              <li>0</li>
                              <li>15</li>
                              <li>30</li>
                              <li>45</li>
                              <li>60</li>
                            </ul>
                          </div>
                          <span id="rangeValue">{workExperience}</span>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                      <br />
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  {detail.service_type && detail.service_type.tab4 ? (
                    <div className="nanny tutor">
                      <h2 className="border">
                        <img
                          src={window.location.origin + "/images/tutorform.svg"}
                        />
                        {t("text-tutor")}
                        <div class="personal_preferences">
                          <label class="switchedit">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                // if (e.target.checked) {
                                //     setinactiveprofessional({})
                                //     setactiveprofession(JSON.parse(sessionStorage.getItem("service")))
                                // }
                                setShowModal(true);
                                setIsDisable(4);
                              }}
                              checked={
                                Object.keys(profession).filter(
                                  (e) => e == "tab4"
                                )[0]
                                  ? false
                                  : true
                              }
                            />
                            <span class="slideredit roundedit"></span>
                          </label>
                        </div>
                      </h2>
                      <div className="form_group full">
                        <label>
                          {t("Years of work experience")}
                          <span
                            className={
                              errorFields.tutorexp != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="rang">
                          <div
                            class="slider"
                            onClick={(w) =>
                              setErrorFields({ ...errorFields, tutorexp: "" })
                            }
                          >
                            <input
                              type="range"
                              min="0"
                              max="60"
                              onChange={(e) =>
                                setTutorExperience(e.target.value)
                              }
                              style={{
                                "background-size":
                                  (tutorExperience * 100) / 60 + "% 100% ",
                              }}
                              value={tutorExperience}
                            />
                            <ul>
                              <li>0</li>
                              <li>15</li>
                              <li>30</li>
                              <li>45</li>
                              <li>60</li>
                            </ul>
                          </div>
                          <span id="rangeValue">{tutorExperience}</span>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*<div className="noteedit">*/}
                  {/*  <ProviderProfession />*/}

                  {/*  <MarkRoundIcon color={"#7D2B8B"} />*/}
                  {/*  {t(*/}
                  {/*    "activate-deactivate-profession"*/}
                  {/*  )}*/}
                  {/*</div>*/}
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={generalInfo.job == "active" ? "active personal" : "personal"}>
        <h3
          onClick={(e) =>
            setGeneralInfo({ ...generalInfo, job: generalInfo.job == "" ? "active" : "" })
          }
        >
          {t("job-preferences")}
        </h3>
        {generalInfo.job == "active" ? (
          <div className="editkids editabout">
            {editGeneralIngo.job == "" ? (
              <>
                <button onClick={(e) => setEditGeneralInfo({ ...editGeneralIngo, job: "edit" })}>
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>
                {detail.service_type && detail.service_type.tab1 ? (
                  <div className="nannyediy">
                    <h2 class="border">
                      <img
                        src={window.location.origin + "/images/nany_pur.svg"}
                      />
                      {t("text-nanny")}
                    </h2>
                    <label>
                      {t("Rate per hour")}
                      <span>
                        <strong>
                          <b>
                            {language == 'sr'
                              ? +nannyRate.min * 100 +
                                " - " +
                                nannyRate.max * 100 +
                                " " +
                                "RSD"
                              : "$" + nannyRate.min + " - " + nannyRate.max}
                          </b>
                        </strong>
                      </span>
                    </label>
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}
                {detail.service_type && detail.service_type.tab2 ? (
                  <div className="nannyediy">
                    <h2 class="border">
                      <img
                        src={
                          window.location.origin +
                          "/images/special_education.svg"
                        }
                      />
                      {t("spec-education-teacher")}
                    </h2>
                    <label>
                      {t("Rate per hour")}
                      <span>
                        <strong>
                          <b>
                            {language == 'sr'
                              ? rate.min * 100 +
                                " - " +
                                rate.max * 100 +
                                " " +
                                "RSD"
                              : "$" + rate.min + " - " + rate.max}
                          </b>
                        </strong>
                      </span>
                    </label>
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}

                {detail.service_type && detail.service_type.tab3 ? (
                  <div className="nannyediy">
                    <h2 class="border">
                      <img
                        src={
                          window.location.origin + "/images/professional.svg"
                        }
                      />
                      {t("spec-education-paraprofessional")}
                    </h2>
                    <label>
                      {t("Rate per hour")}
                      <span>
                        <strong>
                          <b>
                            {language == 'sr'
                              ? +perRate.min * 100 +
                                " - " +
                                perRate.max * 100 +
                                " " +
                                "RSD"
                              : "$" + perRate.min + " - " + perRate.max}
                          </b>
                        </strong>
                      </span>
                    </label>
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}

                {detail.service_type && detail.service_type.tab4 ? (
                  <div className="nannyediy">
                    <h2 class="border">
                      <img src={window.location.origin + "/images/tuter.svg"} />
                      {t("text-tutor")}
                    </h2>
                    <label>
                      {t("Rate per hour")}
                      <span>
                        <strong>
                          <b>
                            {language == 'sr'
                              ? +tutorRate.min * 100 +
                                " - " +
                                tutorRate.max * 100 +
                                " " +
                                "RSD"
                              : "$" + tutorRate.min + " - " + tutorRate.max}
                          </b>
                        </strong>
                      </span>
                    </label>
                    <label>
                      {t("Experience in Tutoring")}
                      {qualifications.English &&
                      qualifications.English != "" ? (
                        <span style={{
                          marginLeft: "10px",
                        }}>
                          <strong>
                            <DoneIcon />
                            {t("english")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Serbian &&
                      qualifications.Serbian != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("serbian")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Mathematics &&
                      qualifications.Mathematics != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("mathematics")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Physics &&
                      qualifications.Physics != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("physics")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Chemistry &&
                      qualifications.Chemistry != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("chemistry")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                      {qualifications.Other && qualifications.Other != "" ? (
                        <span>
                          <strong>
                            <DoneIcon />
                            {t("text-other")}
                          </strong>
                        </span>
                      ) : (
                        ""
                      )}
                    </label>

                    <label>
                      {t("Providing online classes")}
                      <span>
                        <strong>
                          <b>
                            <DoneIcon />
                            {detail.tutorintrestedonlinecls
                              ? detail.tutorintrestedonlinecls
                              : ""}
                          </b>
                        </strong>
                      </span>
                    </label>
                    <br />
                  </div>
                ) : (
                  ""
                )}
                {/*<div className="preferred_school_jobs tutor">*/}
                {/*  <h2 className="border">{t("preffer-jobs")}</h2>*/}
                {/*  <label>*/}
                {/*    <span>*/}
                {/*      {(detail.nanyintrestedinschool === t('Yes') || detail.tutorintrestedinschool === t('Yes')) ?*/}
                {/*          detail.tutorallapplicable.map((e) => {*/}
                {/*        return (*/}
                {/*          <>*/}
                {/*            <strong style={{ marginBottom: "8px", marginLeft: "0" }}>*/}
                {/*              <DoneIcon />*/}

                {/*              {t(e.name)}*/}
                {/*            </strong>*/}
                {/*          </>*/}
                {/*        );*/}
                {/*      }) : ''}*/}
                {/*    </span>*/}
                {/*  </label>*/}
                {/*  {(detail.nanyintrestedinschool === t('Yes') || detail.tutorintrestedinschool === t('Yes')) ?*/}
                {/*  detail.yearofexpasteacher ? (*/}
                {/*    <label>*/}
                {/*      {t("Years of experience as a teacher")}*/}
                {/*      <span className="cir">{detail.yearofexpasteacher}</span>*/}
                {/*    </label>*/}
                {/*  ) : (*/}
                {/*    ""*/}
                {/*  ) : ""}*/}
                {/*  <br />*/}
                {/*</div>*/}
                <div className="gernaledt">
                  <h2 class="border ">{t("general-info")}</h2>
                  <div className="left2" style={{
                    marginLeft: "0",
                  }}>
                    <label>
                      {t("Preferred number of children to work with")}
                      <span
                        className={`cir ${
                          detail.nanyworkwithnochild === "twins" &&
                          language === "sr"
                            ? "specialStyle"
                            : ""
                        }`}
                      >
                        {t(detail.nanyworkwithnochild || detail.tutorworkwithnochild)}
                      </span>
                    </label>
                    {detail.nanyprefchildage[0] ? (
                      <label>
                        {t("Preferred child’s age")}
                        <span>
                          <strong>
                            <b>
                              {detail.nanyprefchildage &&
                                detail.nanyprefchildage.map((e, index) => {
                                  const modifiedName = t(e.name);
                                  return (
                                    modifiedName +
                                    (detail.nanyprefchildage.length - 1 >=
                                    index + 1
                                      ? ", "
                                      : "")
                                  );
                                })}
                            </b>
                          </strong>
                        </span>
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="right2">
                    <label>
                      {t("start-date")}
                      <span style={{
                        marginLeft: "5px",
                      }}>
                        <strong>
                          <b>{detail.tutorstartdate || detail.nanystartdate}</b>
                        </strong>
                      </span>
                    </label>
                    <label>
                      {t("text-frequency")}
                      <span>
                        <strong style={{
                          marginRight: "0",
                        }}>
                          {tutorTime.fulltime ? (
                          <b style={{
                            marginRight: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                          }}>
                            <DoneIcon/>
                            <p>
                            {tutorTime.fulltime
                                ? tutorTime.fulltime
                                : ""}
                            </p>
                          </b>
                              ) : ''}
                          {tutorTime.parttime ? (
                          <b style={{
                            marginRight: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                          }}>
                            <DoneIcon/>
                            <p>
                            {tutorTime.parttime
                                ? tutorTime.parttime
                                : ""}
                            </p>
                          </b>) : ''}
                          {tutorTime.occasionally ? (
                          <b style={{
                            display: "flex",
                            alignItems: "center",
                          }}>
                            <DoneIcon/>
                            <p>
                            {tutorTime.occasionally
                                ? tutorTime.occasionally
                                : ""}
                            </p>
                          </b>) : ""}
                        </strong>
                      </span>
                    </label>
                  </div>
                </div>
              </>
            ) : (
                <div className="Profile_complete charactor">
                  <div className="detail preferences   work-experience job_performance setp3">
                  {detail.service_type && detail.service_type.tab1 ? (
                    <div className="nanny">
                      <h2 className="border">
                        <img
                          src={window.location.origin + "/images/nany_pur.svg"}
                        />
                        {t("text-nanny")}
                      </h2>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {language == 'sr' ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorFields.nanyperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>

                        {language == 'sr' ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={nannyRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (nannyRate.max > 0 ? nannyRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setNannyRate({
                                      ...nannyRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={nannyRate.max == 0 ? 50 / 10 : nannyRate.max / 10}
                                onChange={(e) => {
                                  if (
                                    nannyRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setNannyRate({
                                      ...nannyRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={nannyRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (nannyRate.max > 0 ? nannyRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setNannyRate({
                                      ...nannyRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={nannyRate.max == 0 ? 50 / 10 : nannyRate.max / 10}
                                onChange={(e) => {
                                  if (
                                    nannyRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setNannyRate({
                                      ...nannyRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {language == 'sr'
                            ? nannyRate.min * 100 + " - " + nannyRate.max * 100
                            : nannyRate.min + " - " + nannyRate.max}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {detail.service_type && detail.service_type.tab2 ? (
                    <div className="special_education big">
                      <h2 className="border">
                        <img
                          src={
                            window.location.origin +
                            "/images/special_education.svg"
                          }
                        />
                        {t("education-teacher")}
                      </h2>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {language == 'sr' ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorFields.nanyperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {language == 'sr' ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={rate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (rate.max > 0 ? rate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setRate({
                                      ...rate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                                onChange={(e) => {
                                  if (
                                    rate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setRate({
                                      ...rate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={rate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (rate.max > 0 ? rate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setRate({
                                      ...rate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                                onChange={(e) => {
                                  if (
                                    rate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setRate({
                                      ...rate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {language == 'sr'
                            ? rate.min * 100 + " - " + rate.max * 100
                            : rate.min + " - " + rate.max}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {detail.service_type && detail.service_type.tab3 ? (
                    <div className="special_education big">
                      <h2 className="border">
                        <img
                          src={
                            window.location.origin + "/images/professional.svg"
                          }
                        />
                        {t("education-paraprofessional")}
                      </h2>
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {language == 'sr' ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorFields.nanyperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {language == 'sr' ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      perRate.min >= value ||
                                      (perRate.max < value && perRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      perRate.min >= value ||
                                      (perRate.max < value && perRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={perRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (perRate.max > 0 ? perRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setPerRate({
                                      ...perRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={perRate.max == 0 ? 50 / 10 : perRate.max / 10}
                                onChange={(e) => {
                                  if (
                                    perRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setPerRate({
                                      ...perRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      perRate.min >= value ||
                                      (perRate.max < value && perRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      perRate.min >= value ||
                                      (perRate.max < value && perRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={perRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (perRate.max > 0 ? perRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setPerRate({
                                      ...perRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={perRate.max == 0 ? 50 / 10 : perRate.max / 10}
                                onChange={(e) => {
                                  if (
                                    perRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setPerRate({
                                      ...perRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {language == 'sr'
                            ? perRate.min * 100 + " - " + perRate.max * 100
                            : perRate.min + " - " + perRate.max}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {detail.service_type && detail.service_type.tab4 ? (
                    <div className="tutor">
                      <h2 className="border">
                        <img
                          src={window.location.origin + "/images/tuter.svg"}
                        />
                        Tutor
                      </h2>
                      {detail.service_type &&
                      !detail.service_type.tab2 &&
                      !detail.service_type.tab3 ? (
                        <>
                          <div className="form_group full qualification tutorteach">
                            <label>
                              {t("Classes you would like to teach")}
                              <span
                                className={
                                  errorFields.tutorliketoteach == ""
                                    ? ""
                                    : "starred"
                                }
                              >
                                *
                              </span>
                            </label>
                            <div className="checkbox">
                              <ul
                                onClick={(e) =>
                                  setErrorFields({
                                    ...errorFields,
                                    tutorliketoteach: "",
                                  })
                                }
                              >
                                <li>
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked={
                                      qualifications.Mathematics ==
                                      "Mathematics"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        setQualifications({
                                          ...qualifications,
                                          Mathematics: "Mathematics",
                                        });
                                      } else {
                                        setQualifications({
                                          ...qualifications,
                                          Mathematics: "",
                                        });
                                      }
                                    }}
                                  />
                                  <span> {t("mathematics")}</span>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked={
                                      qualifications.Physics == "Physics"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        setQualifications({
                                          ...qualifications,
                                          Physics: "Physics",
                                        });
                                      } else {
                                        setQualifications({
                                          ...qualifications,
                                          Physics: "",
                                        });
                                      }
                                    }}
                                  />
                                  <span> {t("physics")}</span>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked={
                                      qualifications.English == "English"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        setQualifications({
                                          ...qualifications,
                                          English: "English",
                                        });
                                      } else {
                                        setQualifications({
                                          ...qualifications,
                                          English: "",
                                        });
                                      }
                                    }}
                                  />
                                  <span> {t("english")}</span>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked={
                                      qualifications.Serbian == "Serbian"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        setQualifications({
                                          ...qualifications,
                                          Serbian: "Serbian",
                                        });
                                      } else {
                                        setQualifications({
                                          ...qualifications,
                                          Serbian: "",
                                        });
                                      }
                                    }}
                                  />
                                  <span> {t("serbian")}</span>
                                </li>

                                <li>
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked={
                                      qualifications.Chemistry == "Chemistry"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        setQualifications({
                                          ...qualifications,
                                          Chemistry: "Chemistry",
                                        });
                                      } else {
                                        setQualifications({
                                          ...qualifications,
                                          Chemistry: "",
                                        });
                                      }
                                    }}
                                  />
                                  <span> {t("chemistry")}</span>
                                </li>
                                <li>
                                  <input type="checkbox" name="" />
                                  <span>
                                    {t("text-other")}
                                    <input
                                      type="text"
                                      placeholder={t("text-other")}
                                      onChange={(e) => {
                                        setQualifications({
                                          ...qualifications,
                                          Other: e.target.value,
                                        });
                                      }}
                                      defaultValue={
                                        qualifications.Other
                                          ? qualifications.Other
                                          : " "
                                      }
                                    />
                                  </span>
                                </li>
                              </ul>
                            </div>
                            {/* <div className='errorfield'>{error.message}</div>*/}
                          </div>
                          <div className="form_group full">
                            <label>
                              {t("I am interested in providing online classes")}
                              <span
                                className={
                                  errorFields.tutorintrestedonlinecls != ""
                                    ? "starred"
                                    : ""
                                }
                              >
                                *
                              </span>
                            </label>
                            <div className="checkbox create">
                              <ul
                                onClick={(e) =>
                                  setErrorFields({
                                    ...errorFields,
                                    tutorintrestedonlinecls: "",
                                  })
                                }
                              >
                                <li>
                                  <input
                                    type="radio"
                                    name="yes"
                                    checked={
                                      detailProvider.tutorintrestedonlinecls ===
                                      "Yes"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      setDetailProvider({
                                        ...detailProvider,
                                        tutorintrestedonlinecls: "Yes",
                                      })
                                    }
                                  />
                                  <span> {t("confirm")}</span>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name="yes"
                                    checked={
                                      detailProvider.tutorintrestedonlinecls ===
                                      "No"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      setDetailProvider({
                                        ...detailProvider,
                                        tutorintrestedonlinecls: "No",
                                      })
                                    }
                                  />
                                  <span> {t("objection")}</span>
                                </li>
                              </ul>
                            </div>
                            {/* <div className='errorfield'>{error.message}</div>*/}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="form_group full">
                        <label>
                          {t("Rate per hour")}
                          {language == 'sr' ? "(RSD)" : "(USD)"}
                          <span
                            className={
                              errorFields.nanyperhrrate != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        {language == 'sr' ? (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={tutorRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (tutorRate.max > 0 ? tutorRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  tutorRate.max == 0
                                    ? 50 / 10
                                    : tutorRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    tutorRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper rang">
                            <div class="container_slide">
                              <div class="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div class="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={tutorRate.min / 10}
                                onChange={(e) => {
                                  if (
                                    (tutorRate.max > 0 ? tutorRate.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={
                                  tutorRate.max == 0
                                    ? 50 / 10
                                    : tutorRate.max / 10
                                }
                                onChange={(e) => {
                                  if (
                                    tutorRate.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setTutorRate({
                                      ...tutorRate,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div class="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span class="price">
                          {language == 'sr'
                            ? tutorRate.min * 100 + " - " + tutorRate.max * 100
                            : tutorRate.min + " - " + tutorRate.max}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="nany2">
            {/*        <div className="preferred_school_jobs tutor">*/}
            {/*          <h2 className="border">{t("preffer-jobs")}</h2>*/}
            {/*          <div className="form_group full">*/}
            {/*            <label>*/}
            {/*              {t("I am interested in working in school")}*/}
            {/*              <span*/}
            {/*                className={*/}
            {/*                  errorFields.nanyintrestedinschool != ""*/}
            {/*                    ? "starred"*/}
            {/*                    : ""*/}
            {/*                }*/}
            {/*              >*/}
            {/*                **/}
            {/*              </span>*/}
            {/*            </label>*/}
            {/*            <div className="checkbox create">*/}
            {/*              <ul*/}
            {/*                onClick={(e) =>*/}
            {/*                  setErrorFields({*/}
            {/*                    ...errorFields,*/}
            {/*                    nanyintrestedinschool: "",*/}
            {/*                  })*/}
            {/*                }*/}
            {/*              >*/}
            {/*                <li>*/}
            {/*                  <input*/}
            {/*                    type="radio"*/}
            {/*                    name="school"*/}
            {/*                    checked={*/}
            {/*                      detailProvider.nanyintrestedinschool == "Yes"*/}
            {/*                        ? true*/}
            {/*                        : false*/}
            {/*                    }*/}
            {/*                    onClick={(e) =>*/}
            {/*                      setDetailProvider({*/}
            {/*                        ...detailProvider,*/}
            {/*                        nanyintrestedinschool: "Yes",*/}
            {/*                      })*/}
            {/*                    }*/}
            {/*                  />*/}
            {/*                  <span> {t("confirm")}</span>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                  <input*/}
            {/*                    type="radio"*/}
            {/*                    name="school"*/}
            {/*                    checked={*/}
            {/*                      detailProvider.nanyintrestedinschool == "No"*/}
            {/*                        ? true*/}
            {/*                        : false*/}
            {/*                    }*/}
            {/*                    onClick={(e) =>*/}
            {/*                      setDetailProvider({*/}
            {/*                        ...detailProvider,*/}
            {/*                        nanyintrestedinschool: "No",*/}
            {/*                      })*/}
            {/*                    }*/}
            {/*                  />*/}
            {/*                  <span> {t("objection")}</span>*/}
            {/*                </li>*/}
            {/*              </ul>*/}
            {/*            </div>*/}
            {/*            /!* <div className='errorfield'>{error.message}</div>*!/*/}
            {/*          </div>*/}
            {/*          {detailProvider.nanyintrestedinschool == "Yes" ? (*/}
            {/*            <>*/}
            {/*              <div className="form_group full">*/}
            {/*                <label>{t("select-applicable")}</label>*/}
            {/*                <div className="customselect inp">*/}
            {/*                  <input*/}
            {/*                    className="keyword"*/}
            {/*                    type="text"*/}
            {/*                    placeholder={t("choose-list")}*/}
            {/*                  />*/}
            {/*                  <div*/}
            {/*                    className="overflow"*/}
            {/*                    id="over6"*/}
            {/*                    onClick={(e) => handleElementById("cate9", "over6")}*/}
            {/*                  ></div>*/}

            {/*                  <div className="option" id="cate9">*/}
            {/*                    <p>*/}
            {/*                      <input*/}
            {/*                        type="checkbox"*/}
            {/*                        onClick={(a) =>*/}
            {/*                          selectoption(t("kindergarten-teacher"))*/}
            {/*                        }*/}
            {/*                        checked={*/}
            {/*                          selectList.filter(*/}
            {/*                            (e) =>*/}
            {/*                              e.name == t("kindergarten-teacher")*/}
            {/*                          )[0]*/}
            {/*                            ? true*/}
            {/*                            : false*/}
            {/*                        }*/}
            {/*                      />*/}
            {/*                      <h3>{t("kindergarten-teacher")} </h3>*/}
            {/*                      <span></span>*/}
            {/*                    </p>*/}
            {/*                    <p>*/}
            {/*                      <input*/}
            {/*                        type="checkbox"*/}
            {/*                        onClick={(a) =>*/}
            {/*                          selectoption(t("elementary-teacher"))*/}
            {/*                        }*/}
            {/*                        checked={*/}
            {/*                          selectList.filter(*/}
            {/*                            (e) => e.name == t("elementary-teacher")*/}
            {/*                          )[0]*/}
            {/*                            ? true*/}
            {/*                            : false*/}
            {/*                        }*/}
            {/*                      />*/}
            {/*                      <h3>{t("elementary-teacher")} </h3>*/}
            {/*                      <span></span>*/}
            {/*                    </p>*/}
            {/*                    <p>*/}
            {/*                      <input*/}
            {/*                        type="checkbox"*/}
            {/*                        onClick={(a) =>*/}
            {/*                          selectoption(t("high-teacher"))*/}
            {/*                        }*/}
            {/*                        checked={*/}
            {/*                          selectList.filter(*/}
            {/*                            (e) => e.name == t("high-teacher")*/}
            {/*                          )[0]*/}
            {/*                            ? true*/}
            {/*                            : false*/}
            {/*                        }*/}
            {/*                      />*/}
            {/*                      <h3>{t("high-teacher")} </h3>*/}
            {/*                      <span></span>*/}
            {/*                    </p>*/}
            {/*                    <p>*/}
            {/*                      <input*/}
            {/*                        type="checkbox"*/}
            {/*                        onClick={(a) =>*/}
            {/*                          selectoption(t("education-teacher"))*/}
            {/*                        }*/}
            {/*                        checked={*/}
            {/*                          selectList.filter(*/}
            {/*                            (e) => e.name == t("education-teacher")*/}
            {/*                          )[0]*/}
            {/*                            ? true*/}
            {/*                            : false*/}
            {/*                        }*/}
            {/*                      />*/}
            {/*                      <h3>{t("education-teacher")} </h3>*/}
            {/*                      <span></span>*/}
            {/*                    </p>*/}
            {/*                    <p>*/}
            {/*                      <input*/}
            {/*                        type="checkbox"*/}
            {/*                        onClick={(a) =>*/}
            {/*                          selectoption(*/}
            {/*                            t("education-paraprofessional")*/}
            {/*                          )*/}
            {/*                        }*/}
            {/*                        checked={*/}
            {/*                          selectList.filter(*/}
            {/*                            (e) =>*/}
            {/*                              e.name ==*/}
            {/*                              t(*/}
            {/*                                "Special Education Paraprofessional"*/}
            {/*                              )*/}
            {/*                          )[0]*/}
            {/*                            ? true*/}
            {/*                            : false*/}
            {/*                        }*/}
            {/*                      />*/}
            {/*                      <h3>{t("education-paraprofessional")} </h3>*/}
            {/*                      <span></span>*/}
            {/*                    </p>*/}
            {/*                    <p>*/}
            {/*                      <input*/}
            {/*                        type="checkbox"*/}
            {/*                        onClick={(a) => {}}*/}
            {/*                      />*/}
            {/*                      <h3>{t("None")} </h3>*/}
            {/*                      <span></span>*/}
            {/*                    </p>*/}
            {/*                    /!* <div className='clr inp' onClick={e => {*/}
            {/*    setselectcat([])*/}
            {/*    window.location.reload()*/}
            {/*}}>Clear All <span>+</span></div>*!/*/}
            {/*                  </div>*/}

            {/*                  <span onClick={(e) => handleElementById("cate9", "over6")}>*/}
            {/*                    <ArrowUpIcon />*/}
            {/*                  </span>*/}
            {/*                </div>*/}
            {/*                /!* <div className='errorfield'>{error.message}</div>*!/*/}
            {/*              </div>*/}
            {/*              <label>*/}
            {/*                <span>*/}
            {/*                  {selectList.map((e) => {*/}
            {/*                    return (*/}
            {/*                      <>*/}
            {/*                        <strong style={{ marginBottom: "8px" }}>*/}
            {/*                          <DoneIcon />*/}
            {/*                          {e.name}*/}
            {/*                        </strong>*/}
            {/*                      </>*/}
            {/*                    );*/}
            {/*                  })}*/}
            {/*                </span>*/}
            {/*              </label>*/}

            {/*              <div className="form_group full">*/}
            {/*                <label>*/}
            {/*                  {t("Years of work experience as a teacher")}*/}
            {/*                  <span*/}
            {/*                    className={*/}
            {/*                      errorFields.yearofexpasteacher != ""*/}
            {/*                        ? "starred"*/}
            {/*                        : ""*/}
            {/*                    }*/}
            {/*                  >*/}
            {/*                    **/}
            {/*                  </span>*/}
            {/*                </label>*/}
            {/*                <div className="rang">*/}
            {/*                  <div*/}
            {/*                    class="slider"*/}
            {/*                    onClick={(e) =>*/}
            {/*                      setErrorFields({*/}
            {/*                        ...errorFields,*/}
            {/*                        yearofexpasteacher: "",*/}
            {/*                      })*/}
            {/*                    }*/}
            {/*                  >*/}
            {/*                    <input*/}
            {/*                      type="range"*/}
            {/*                      min="0"*/}
            {/*                      max="60"*/}
            {/*                      onChange={(e) =>*/}
            {/*                        setDetailProvider({*/}
            {/*                          ...detailProvider,*/}
            {/*                          yearofexpasteacher: e.target.value,*/}
            {/*                        })*/}
            {/*                      }*/}
            {/*                      style={{*/}
            {/*                        "background-size":*/}
            {/*                          ((detailProvider.yearofexpasteacher == ""*/}
            {/*                            ? 0*/}
            {/*                            : detailProvider.yearofexpasteacher) **/}
            {/*                            100) /*/}
            {/*                            60 +*/}
            {/*                          "% 100% ",*/}
            {/*                      }}*/}
            {/*                      value={*/}
            {/*                        detailProvider.yearofexpasteacher == ""*/}
            {/*                          ? 0*/}
            {/*                          : detailProvider.yearofexpasteacher*/}
            {/*                      }*/}
            {/*                    />*/}
            {/*                    <ul>*/}
            {/*                      <li>0</li>*/}
            {/*                      <li>15</li>*/}
            {/*                      <li>30</li>*/}
            {/*                      <li>45</li>*/}
            {/*                      <li>60</li>*/}
            {/*                    </ul>*/}
            {/*                  </div>*/}
            {/*                  <span id="rangeValue">*/}
            {/*                    {detailProvider.yearofexpasteacher == ""*/}
            {/*                      ? 0*/}
            {/*                      : detailProvider.yearofexpasteacher}*/}
            {/*                  </span>*/}
            {/*                </div>*/}
            {/*                /!* <div className='errorfield'>{error.message}</div>*!/*/}
            {/*              </div>*/}
            {/*            </>*/}
            {/*          ) : (*/}
            {/*            ""*/}
            {/*          )}*/}
            {/*        </div>*/}
                    <div className="general_info tutor  preferred_school_jobs gernaledt">
                      <h2 className="border">{t("general-info")}</h2>
                      <div className="left2">
                        <div className="form_group full">
                          <label>
                            {t("Number of children you prefer to work with?")}
                            <span
                              className={
                                errorFields.nanyworkwithnochild == ""
                                  ? ""
                                  : "starred"
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="children_number">
                            <ul
                              onClick={(e) =>
                                setErrorFields({
                                  ...errorFields,
                                  nanyworkwithnochild: "",
                                })
                              }
                            >
                              <li
                                onClick={(e) => setnchildren(1)}
                                className={nchildren == 1 ? "active" : ""}
                              >
                                1
                              </li>
                              <li
                                onClick={(e) => setnchildren(2)}
                                className={nchildren == 2 ? "active" : ""}
                              >
                                2
                              </li>
                              <li
                                onClick={(e) => setnchildren("twins")}
                                className={nchildren == "twins" ? "active" : ""}
                              >
                                {t("text-twins")}
                              </li>
                              <li
                                onClick={(e) => setnchildren(3)}
                                className={nchildren == 3 ? "active" : ""}
                              >
                                3+
                              </li>
                            </ul>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                        <div
                          className="form_group  full"
                          style={{ clear: "both" }}
                        >
                          <label>
                            {t("Preferred child’s age")}
                            <span
                              className={
                                errorFields.nanyprefchildage != ""
                                  ? "starred"
                                  : ""
                              }
                            ></span>
                          </label>
                          <div className="customselect inp">
                            <input
                              className="keyword"
                              type="text"
                              placeholder={t("choose-list")}
                              value={childrenAge.map((e) => {
                                return t(e.name);
                              })}
                            />
                            <div
                              className="overflow"
                              id="over7"
                              onClick={(e) => handleElementById("cate66", "over7")}
                            ></div>

                            <div
                              className="option"
                              id="cate66"
                              onClick={(e) =>
                                setErrorFields({
                                  ...errorFields,
                                  nanyprefchildage: "",
                                })
                              }
                            >
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    childrenAge.filter(
                                      (e) => e.name == t("years-0-1")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) => selectoption4(t("years-0-1"))}
                                />
                                <h3>{t("years-0-1")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    childrenAge.filter(
                                      (e) => e.name == t("years-4-7")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) => selectoption4(t("years-4-7"))}
                                />
                                <h3>{t("years-4-7")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    childrenAge.filter(
                                      (e) => e.name == t("years-8-10")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption4(t("years-8-10"))
                                  }
                                />
                                <h3>{t("years-8-10")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    childrenAge.filter(
                                      (e) => e.name == t("years-11-15")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) =>
                                    selectoption4(t("years-11-15"))
                                  }
                                />
                                <h3>{t("years-11-15")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  checked={
                                    childrenAge.filter(
                                      (e) => e.name == t("years-16")
                                    )[0]
                                      ? true
                                      : false
                                  }
                                  onClick={(a) => selectoption4(t("years-16"))}
                                />
                                <h3>{t("years-16")} </h3>
                                <span></span>
                              </p>
                            </div>

                            <span onClick={(e) => handleElementById("cate66", "over7")}>
                              <ArrowUpIcon />
                            </span>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                      </div>
                      <div className="right2">
                        <div className="form_group full">
                          <label>
                            {t("start-date")}
                            <span
                              className={
                                errorFields.nanystartdate != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="date_cal">
                            <span>
                              <DateIcon />
                            </span>
                            <DatePicker
                              className={
                                errorFields.nanystartdate != ""
                                  ? "bordererror"
                                  : ""
                              }
                              minDate={today}
                              selected={
                                detailProvider.nanystartdate != null &&
                                detailProvider.nanystartdate != ""
                                    ? new Date(`${detailProvider.nanystartdate}T00:00:00`)
                                    : today
                              }
                              onChange={(date) => {
                                const selectedDate = new Date(
                                    date.getFullYear(),
                                    date.getMonth(),
                                    date.getDate()
                                );
                                setErrorFields({
                                  ...errorFields,
                                  nanystartdate: "",
                                });
                                setDetailProvider({
                                  ...detailProvider,
                                  nanystartdate:
                                      selectedDate.getFullYear() +
                                    "-" +
                                    String(selectedDate.getMonth() + 1).padStart(
                                      2,
                                      "0"
                                    ) +
                                    "-" +
                                    String(selectedDate.getDate()).padStart(2, "0"),
                                });
                              }}
                            />
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                        <div className="form_group full qualification ">
                          <label>
                            {t("frequency")}
                            <span
                              className={
                                errorFields.nanyintrestedin != ""
                                  ? "starred"
                                  : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="checkbox create flex">
                            <ul
                              onClick={(e) =>
                                setErrorFields({
                                  ...errorFields,
                                  nanyintrestedin: "",
                                })
                              }
                            >
                              <li>
                                <input
                                  type="radio"
                                  name=""
                                  checked={
                                    tutorTime.fulltime != ""
                                        ? true
                                        : false
                                  }
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setTutorTime({ ...tutorTime, fulltime: t('full-time') })
                                    } else {
                                      setTutorTime({ ...tutorTime, fulltime: "" })
                                    }
                                  }}
                                />
                                <span> {t("full-time")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name=""
                                  checked={
                                    tutorTime.parttime != ""
                                        ? true
                                        : false
                                  }
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setTutorTime({ ...tutorTime, parttime: t('part-time') })
                                    } else {
                                      setTutorTime({ ...tutorTime, parttime: "" })
                                    }
                                  }}
                                />
                                <span> {t("part-time")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name=""
                                  checked={
                                    tutorTime.occasionally != ""
                                      ? true
                                      : false
                                  }
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setTutorTime({ ...tutorTime, occasionally: t('occasionally') })
                                    } else {
                                      setTutorTime({ ...tutorTime, occasionally: "" })
                                    }
                                  }}
                                />
                                <span> {t("occasionally")}</span>
                              </li>
                            </ul>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          generalInfo.availability == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) => {
            setGeneralInfo({
              ...generalInfo,
              availability: generalInfo.availability === "" ? "active" : "",
            });
          }}
        >
          {t("availability")}
        </h3>
        {generalInfo.availability == "active" ? (
          <div className="calendershow">
            {editGeneralIngo.availability == "" ? (
              <>
                <button
                  onClick={(e) => setEditGeneralInfo({ ...editGeneralIngo, availability: "edit" })}
                >
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                <div className="calander">
                  <div className="form_group full">
                    <label>{t("Availability and working hours")}</label>
                  </div>
                  <div className="legend">
                    <ul>
                      {detail.calanderlastupdate != null &&
                      parseInt(detail.calanderlastupdate) == 1 ? (
                        <li
                          className={
                            detail.calanderlastupdate != null &&
                            parseInt(detail.calanderlastupdate) == 1
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("full-time")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {detail.calanderlastupdate != null &&
                      parseInt(detail.calanderlastupdate) == 2 ? (
                        <li
                          className={
                            detail.calanderlastupdate != null &&
                            parseInt(detail.calanderlastupdate) == 2
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("before-school")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {detail.calanderlastupdate != null &&
                      parseInt(detail.calanderlastupdate) == 5 ? (
                        <li
                          className={
                            detail.calanderlastupdate != null &&
                            parseInt(detail.calanderlastupdate) == 5
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("text-weekends")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {detail.calanderlastupdate != null &&
                      parseInt(detail.calanderlastupdate) == 3 ? (
                        <li
                          className={
                            detail.calanderlastupdate != null &&
                            parseInt(detail.calanderlastupdate) == 3
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("after-school")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      {detail.calanderlastupdate != null &&
                      parseInt(detail.calanderlastupdate) == 4 ? (
                        <li
                          className={
                            detail.calanderlastupdate != null &&
                            parseInt(detail.calanderlastupdate) == 4
                              ? "active"
                              : ""
                          }
                        >
                          <span>{t("text-overnight")}</span>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    <div className="form_group full">
                      <label className="border">{t("text-legend")}</label>
                      <ul>
                        <li>
                          <strong>{t("full-time")}</strong>
                          <br />
                          {t("mon-fri-9-6")}
                        </li>
                        <li>
                          <strong>{t("before-school")}</strong>
                          <br />
                          {t("mon-fri-6-9")}
                        </li>
                        <li>
                          <strong>{t("after-school")}</strong>
                          <br />
                          {t("mon-fri-3-9")}
                        </li>
                        <li>
                          <strong>{t("text-overnight")}</strong>
                          <br />
                          {t("mon-fri-9-6-am")}
                        </li>
                        <li>
                          <strong>{t("text-weekends")}</strong>
                          <br />
                          {t("saturday-sunday")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="calanderfull">
                    {detailProvider.calanderlastupdate == 1 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.fulltime}
                        data2={"fulltime"}
                      />
                    ) : (
                      ""
                    )}
                    {detailProvider.calanderlastupdate == 2 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.beforeschool}
                        data2={"beforeschool"}
                      />
                    ) : (
                      ""
                    )}
                    {detailProvider.calanderlastupdate == 3 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.afterschool}
                        data2={"afterschool"}
                      />
                    ) : (
                      ""
                    )}
                    {detailProvider.calanderlastupdate == 4 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.overnight}
                        data2={"overnight"}
                      />
                    ) : (
                      ""
                    )}
                    {detailProvider.calanderlastupdate == 5 ? (
                      <Calendar
                        data={handleCalendarData}
                        data1={detailProvider.weekends}
                        data2={"weekends"}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="legend legend2">
                    <div className="form_group full">
                      <label className="border">{t("text-legend")}</label>
                      <ul>
                        <li>
                          <strong>{t("full-time")}</strong>
                          <br />
                          {t("mon-fri-9-6")}
                        </li>
                        <li>
                          <strong>{t("before-school")}</strong>
                          <br />
                          {t("mon-fri-6-9")}
                        </li>
                        <li>
                          <strong>{t("after-school")}</strong>
                          <br />
                          {t("mon-fri-3-9")}
                        </li>
                        <li>
                          <strong>{t("text-overnight")}</strong>
                          <br />
                          {t("mon-fri-9-6-am")}
                        </li>
                        <li>
                          <strong>{t("text-weekends")}</strong>
                          <br />
                          {t("saturday-sunday")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="Profile_complete">
                <div className="detail availability setp5">
                  <div className="form_group full">
                    <label>{t("availability-working")}</label>
                    <p>
                      {t(
                        "share-availability-families"
                      )}
                    </p>
                  </div>
                  <div className="form_group full">
                    <label>{t("auto-calendar")}</label>
                    <ul>
                      <li
                        onClick={(e) => setDetailProvider({
                          ...detailProvider,
                          calanderlastupdate: "1",
                        })}
                        className={detailProvider.calanderlastupdate == 1 ? "active" : ""}
                      >
                        <span>{t("full-time")}</span>
                      </li>
                      <li
                          onClick={(e) => setDetailProvider({
                            ...detailProvider,
                            calanderlastupdate: "2",
                          })}
                          className={detailProvider.calanderlastupdate == 2 ? "active" : ""}
                      >
                        <span>{t("before-school")}</span>
                      </li>

                      <li
                          onClick={(e) => setDetailProvider({
                            ...detailProvider,
                            calanderlastupdate: "3",
                          })}
                          className={detailProvider.calanderlastupdate == 3 ? "active" : ""}
                      >
                        <span>{t("after-school")}</span>
                      </li>
                      <li
                          onClick={(e) => setDetailProvider({
                            ...detailProvider,
                            calanderlastupdate: "4",
                          })}
                          className={detailProvider.calanderlastupdate == 4 ? "active" : ""}
                      >
                        <span>{t("text-overnight")}</span>
                      </li>
                      <li
                          onClick={(e) => setDetailProvider({
                            ...detailProvider,
                            calanderlastupdate: "5",
                          })}
                          className={detailProvider.calanderlastupdate == 5 ? "active" : ""}
                      >
                        <span>{t("text-weekends")}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="calander">
                    <div className="form_group full">
                      <label>{t("select-days")}</label>
                    </div>
                    <div className="calanderfull">
                      {detailProvider.calanderlastupdate == 1 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailProvider.fulltime}
                          data2={"fulltime"}
                        />
                      ) : (
                        ""
                      )}
                      {detailProvider.calanderlastupdate == 2 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailProvider.beforeschool}
                          data2={"beforeschool"}
                        />
                      ) : (
                        ""
                      )}
                      {detailProvider.calanderlastupdate == 3 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailProvider.afterschool}
                          data2={"afterschool"}
                        />
                      ) : (
                        ""
                      )}
                      {detailProvider.calanderlastupdate == 4 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailProvider.overnight}
                          data2={"overnight"}
                        />
                      ) : (
                        ""
                      )}
                      {detailProvider.calanderlastupdate == 5 ? (
                        <Calendar
                          data={handleCalendarData}
                          data1={detailProvider.weekends}
                          data2={"weekends"}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="legend">
                      <div className="form_group full">
                        <label className="border">{t("text-legend")}</label>
                        <ul>
                          <li>
                            <strong>{t("full-time")}</strong>
                            <br />
                            {t("mon-fri-9-6")}
                          </li>
                          <li>
                            <strong>{t("before-school")}</strong>
                            <br />
                            {t("mon-fri-6-9")}
                          </li>
                          <li>
                            <strong>{t("after-school")}</strong>
                            <br />
                            {t("mon-fri-3-9")}
                          </li>
                          <li>
                            <strong>{t("text-overnight")}</strong>
                            <br />
                            {t("mon-fri-9-6-am")}
                          </li>
                          <li>
                            <strong>{t("text-weekends")}</strong>
                            <br />
                            {t("saturday-sunday")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={generalInfo.info == "active" ? "active personal" : "personal"}
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({ ...generalInfo, info: generalInfo.info == "" ? "active" : "" })
          }
        >
          {t("additional-info")}
        </h3>
        {generalInfo.info == "active" ? (
          <div className="editkids editabout editinfo ">
            {editGeneralIngo.info == "" ? (
              <>
                <button onClick={(e) => setEditGeneralInfo({ ...editGeneralIngo, info: "edit" })}>
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                <div className="Profile_complete">
                  <div className="detail  additional_info">
                    <div className="form_group full">
                      <label style={{
                        color: "#B7B7B7",
                      }}>{t("Languages")}</label>
                      {detail.englishlevel ? (
                        <div className="language">
                          <strong>{t("english")}</strong>
                          <ul>
                            <li
                              className={
                                detail.englishlevel == "Beginner"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Beginner")}
                            </li>
                            <li
                              className={
                                detail.englishlevel == "Intermediate"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Intermediate")}
                            </li>
                            <li
                              className={
                                detail.englishlevel == "Fluent" ? "active" : ""
                              }
                            >
                              {t("Fluent")}
                            </li>
                            <li
                              className={
                                detail.englishlevel == "Native" ? "active" : ""
                              }
                            >
                              {t("Native")}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {detail.frenchlevel ? (
                        <div className="language">
                          <strong>{t("french")}</strong>
                          <ul>
                            <li
                              className={
                                detail.frenchlevel == "Beginner" ? "active" : ""
                              }
                            >
                              {t("Beginner")}
                            </li>
                            <li
                              className={
                                detail.frenchlevel == "Intermediate"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Intermediate")}
                            </li>
                            <li
                              className={
                                detail.frenchlevel == "Fluent" ? "active" : ""
                              }
                            >
                              {t("Fluent")}
                            </li>
                            <li
                              className={
                                detail.frenchlevel == "Native" ? "active" : ""
                              }
                            >
                              {t("Native")}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {detail.italianlevel ? (
                        <div className="language">
                          <strong>{t("italian")}</strong>
                          <ul>
                            <li
                              className={
                                detail.italianlevel == "Beginner"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Beginner")}
                            </li>
                            <li
                              className={
                                detail.italianlevel == "Intermediate"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Intermediate")}
                            </li>
                            <li
                              className={
                                detail.italianlevel == "Fluent" ? "active" : ""
                              }
                            >
                              {t("Fluent")}
                            </li>
                            <li
                              className={
                                detail.italianlevel == "Native" ? "active" : ""
                              }
                            >
                              {t("Native")}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {detail.germanlevel ? (
                        <div className="language">
                          <strong>{t("german")}</strong>
                          <ul>
                            <li
                              className={
                                detail.germanlevel == "Beginner" ? "active" : ""
                              }
                            >
                              {t("Beginner")}
                            </li>
                            <li
                              className={
                                detail.germanlevel == "Intermediate"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Intermediate")}
                            </li>
                            <li
                              className={
                                detail.germanlevel == "Fluent" ? "active" : ""
                              }
                            >
                              {t("Fluent")}
                            </li>
                            <li
                              className={
                                detail.germanlevel == "Native" ? "active" : ""
                              }
                            >
                              {t("Native")}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {detail.spanishlevel ? (
                        <div className="language">
                          <strong>{t("spanish")}</strong>
                          <ul>
                            <li
                              className={
                                detail.spanishlevel == "Beginner"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Beginner")}
                            </li>
                            <li
                              className={
                                detail.spanishlevel == "Intermediate"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Intermediate")}
                            </li>
                            <li
                              className={
                                detail.spanishlevel == "Fluent" ? "active" : ""
                              }
                            >
                              {t("Fluent")}
                            </li>
                            <li
                              className={
                                detail.spanishlevel == "Native" ? "active" : ""
                              }
                            >
                              {t("Native")}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {detail.chineselevel ? (
                        <div className="language">
                          <strong>{t("chinese")}</strong>
                          <ul>
                            <li
                              className={
                                detail.chineselevel == "Beginner"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Beginner")}
                            </li>
                            <li
                              className={
                                detail.chineselevel == "Intermediate"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Intermediate")}
                            </li>
                            <li
                              className={
                                detail.chineselevel == "Fluent" ? "active" : ""
                              }
                            >
                              {t("Fluent")}
                            </li>
                            <li
                              className={
                                detail.chineselevel == "Native" ? "active" : ""
                              }
                            >
                              {t("Native")}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {detail.otherlevel ? (
                        <div className="language">
                          <strong>
                            {t("Other language")}
                            <input
                              type="text"
                              placeholder={t("text-type")}
                              value={
                                detail.otherlangname ? detail.otherlangname : ""
                              }
                            />
                          </strong>
                          <ul>
                            <li
                              className={
                                detail.otherlevel == "Beginner" ? "active" : ""
                              }
                            >
                              {t("Beginner")}
                            </li>
                            <li
                              className={
                                detail.otherlevel == "Intermediate"
                                  ? "active"
                                  : ""
                              }
                            >
                              {t("Intermediate")}
                            </li>
                            <li
                              className={
                                detail.otherlevel == "Fluent" ? "active" : ""
                              }
                            >
                              {t("Fluent")}
                            </li>
                            <li
                              className={
                                detail.otherlevel == "Native" ? "active" : ""
                              }
                            >
                              {t("Native")}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {detail.service_type && detail.service_type.tab1 ? (
                  <div className="icon">
                    <ul>
                      <li>
                        {t("Smoke?")}
                        <div className="icons">
                          {habit.smoke == "false" ? (
                            <BanSmokeIcon
                              color={"#7D2B8B"}
                              colorCircle={"#7D2B8B"}
                              colorPath={"#fff"}
                            />
                          ) : (
                            <SmokeIcon
                              color={"#7D2B8B"}
                              colorCircle={"#7D2B8B"}
                              colorPath={"#fff"}
                            />
                          )}
                        </div>
                      </li>
                      <li>
                        {t("cooking-kids")}
                        <div className="icons">
                          {habit.kids == "false" ? (
                            <BanCookIcon
                              color={"#7D2B8B"}
                              colorCircle={"#7D2B8B"}
                              colorPath={"#fff"}
                            />
                          ) : (
                            <CookIcon
                              color={"#7D2B8B"}
                              colorCircle={"#7D2B8B"}
                              colorPath={"#fff"}
                            />
                          )}
                        </div>
                      </li>
                      <li>
                        {t("light-housework")}
                        <div className="icons">
                          {habit.housework == "false" ? (
                            <BanHouseIcon
                              color={"#7D2B8B"}
                              colorPath={"#fff"}
                              colorCircle={"#7D2B8B"}
                            />
                          ) : (
                            <HouseIcon
                              color={"#7D2B8B"}
                              colorPath={"#fff"}
                              colorCircle={"#7D2B8B"}
                            />
                          )}
                        </div>
                      </li>
                      <li>
                        {t("Car or licence")}
                        <div className="icons">
                          {habit.licence == "false" ? (
                            <BanCarIcon
                              color={"#7D2B8B"}
                              colorCircle={"#7D2B8B"}
                              colorPath={"#fff"}
                            />
                          ) : (
                            <CarIcon
                              color={"#7D2B8B"}
                              colorCircle={"#7D2B8B"}
                              colorPath={"#fff"}
                            />
                          )}
                        </div>
                      </li>
                      <li>
                        {t("Traveling with family")}
                        <div className="icons">
                          {habit.family == "false" ? (
                            <BanPlaneIcon
                              color={"#7D2B8B"}
                              colorCircle={"#7D2B8B"}
                              colorPath={"#fff"}
                            />
                          ) : (
                            <PlaneIcon
                              color={"#7D2B8B"}
                              colorCircle={"#7D2B8B"}
                              colorPath={"#fff"}
                            />
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                ) : (
                  ""
                )}
                <div className="nannyediy">
                  <div className="abrod">
                    <label className="abrodd">
                    <ThingIcon />
                      {t("Work abroad")} <span>{t(detail.workingabroad) || t('No')}</span>
                    </label>
                  </div>
                  {detailProvider.livewithfamily ? (
                    <div className="abrod">
                      <label className="abrodd">
                         <HomeIcon />
                        {t("Living with the family")}
                        <span>{t(detail.livewithfamily)}</span>
                      </label>
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {detail.anyallergies == "Yes" ? (
                  <label>
                    <span>
                      <strong>
                        <DoneIcon />
                        {t("Allergies")}
                      </strong>
                    </span>
                  </label>
                ) : (
                  ""
                )}
                {detail.medicalcondition == "Yes" ? (
                  <label>
                    <span>
                      <strong>
                        <DoneIcon />
                        {t("Medical conditions")}
                      </strong>
                    </span>
                  </label>
                ) : (
                  ""
                )}

                <br />
              </>
            ) : (
              <div className="Profile_complete new">
                <div className="detail  additional_info info_parents setp6">
                  <div
                    className="form_group full"
                    onClick={(e) =>
                      setErrorFields({
                        ...errorFields,
                        englishlevel: "",
                        frenchlevel: "",
                        italianlevel: "",
                        germanlevel: "",
                        spanishlevel: "",
                        chineselevel: "",
                        otherlevel: "",
                      })
                    }
                  >
                    <label>
                      {t("What languages do you speak?")}
                      <span
                        className={
                          errorFields.englishlevel != "" ||
                          errorFields.frenchlevel != "" ||
                          errorFields.italianlevel != "" ||
                          errorFields.germanlevel != "" ||
                          errorFields.spanishlevel != "" ||
                          errorFields.chineselevel != "" ||
                          errorFields.otherlevel != ""
                            ? "starred"
                            : ""
                        }
                      >
                        *
                      </span>
                    </label>
                    <div className="language">
                      <strong>{t("english")}</strong>
                      <ul>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "English_1") ==
                              ""
                            ) {
                              hadnleLanguageSelect("English_1");
                              setDetailProvider({
                                ...detailProvider,
                                englishlevel: "Beginner",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                englishlevel: "",
                              });
                              hadnleLanguageSelect("English_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "English_1") !=
                              "" || detailProvider.englishlevel == "Beginner"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Beginner")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "English_2") ==
                              ""
                            ) {
                              hadnleLanguageSelect("English_2");
                              setDetailProvider({
                                ...detailProvider,
                                englishlevel: "Intermediate",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                englishlevel: "",
                              });
                              hadnleLanguageSelect("English_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "English_2") !=
                              "" ||
                            detailProvider.englishlevel == "Intermediate"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Intermediate")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "English_3") ==
                              ""
                            ) {
                              hadnleLanguageSelect("English_3");
                              setDetailProvider({
                                ...detailProvider,
                                englishlevel: "Fluent",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                englishlevel: "",
                              });
                              hadnleLanguageSelect("English_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "English_3") !=
                              "" || detailProvider.englishlevel == "Fluent"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Fluent")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "English_4") ==
                              ""
                            ) {
                              hadnleLanguageSelect("English_4");
                              setDetailProvider({
                                ...detailProvider,
                                englishlevel: "Native",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                englishlevel: "",
                              });
                              hadnleLanguageSelect("English_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "English_4") !=
                              "" || detailProvider.englishlevel == "Native"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Native")}
                        </li>
                      </ul>
                    </div>
                    <div className="language">
                      <strong>{t("french")}</strong>
                      <ul>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "French_1") ==
                              ""
                            ) {
                              hadnleLanguageSelect("French_1");
                              setDetailProvider({
                                ...detailProvider,
                                frenchlevel: "Beginner",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                frenchlevel: "",
                              });
                              hadnleLanguageSelect("French_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "French_1") !=
                              "" || detailProvider.frenchlevel == "Beginner"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Beginner")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "French_2") ==
                              ""
                            ) {
                              hadnleLanguageSelect("French_2");
                              setDetailProvider({
                                ...detailProvider,
                                frenchlevel: "Intermediate",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                frenchlevel: "",
                              });
                              hadnleLanguageSelect("French_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "French_2") !=
                              "" || detailProvider.frenchlevel == "Intermediate"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Intermediate")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "French_3") ==
                              ""
                            ) {
                              hadnleLanguageSelect("French_3");
                              setDetailProvider({
                                ...detailProvider,
                                frenchlevel: "Fluent",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                frenchlevel: "",
                              });
                              hadnleLanguageSelect("French_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "French_3") !=
                              "" || detailProvider.frenchlevel == "Fluent"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Fluent")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "French_4") ==
                              ""
                            ) {
                              hadnleLanguageSelect("French_4");
                              setDetailProvider({
                                ...detailProvider,
                                frenchlevel: "Native",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                frenchlevel: "",
                              });
                              hadnleLanguageSelect("French_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "French_4") !=
                              "" || detailProvider.frenchlevel == "Native"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Native")}
                        </li>
                      </ul>
                    </div>
                    <div className="language">
                      <strong>{t("italian")}</strong>
                      <ul>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Italian_1") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Italian_1");
                              setDetailProvider({
                                ...detailProvider,
                                italianlevel: "Beginner",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                italianlevel: "",
                              });
                              hadnleLanguageSelect("Italian_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Italian_1") !=
                              "" || detailProvider.italianlevel == "Beginner"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Beginner")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Italian_2") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Italian_2");
                              setDetailProvider({
                                ...detailProvider,
                                italianlevel: "Intermediate",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                italianlevel: "",
                              });
                              hadnleLanguageSelect("Italian_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Italian_2") !=
                              "" ||
                            detailProvider.italianlevel == "Intermediate"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Intermediate")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Italian_3") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Italian_3");
                              setDetailProvider({
                                ...detailProvider,
                                italianlevel: "Fluent",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                italianlevel: "",
                              });
                              hadnleLanguageSelect("Italian_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Italian_3") !=
                              "" || detailProvider.italianlevel == "Fluent"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Fluent")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Italian_4") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Italian_4");
                              setDetailProvider({
                                ...detailProvider,
                                italianlevel: "Native",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                italianlevel: "",
                              });
                              hadnleLanguageSelect("Italian_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Italian_4") !=
                              "" || detailProvider.italianlevel == "Native"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Native")}
                        </li>
                      </ul>
                    </div>
                    <div className="language">
                      <strong>{t("german")}</strong>
                      <ul>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "German_1") ==
                              ""
                            ) {
                              hadnleLanguageSelect("German_1");
                              setDetailProvider({
                                ...detailProvider,
                                germanlevel: "Beginner",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                germanlevel: "",
                              });
                              hadnleLanguageSelect("German_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "German_1") !=
                              "" || detailProvider.germanlevel == "Beginner"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Beginner")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "German_2") ==
                              ""
                            ) {
                              hadnleLanguageSelect("German_2");
                              setDetailProvider({
                                ...detailProvider,
                                germanlevel: "Intermediate",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                germanlevel: "",
                              });
                              hadnleLanguageSelect("German_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "German_2") !=
                              "" || detailProvider.germanlevel == "Intermediate"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Intermediate")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "German_3") ==
                              ""
                            ) {
                              hadnleLanguageSelect("German_3");
                              setDetailProvider({
                                ...detailProvider,
                                germanlevel: "Fluent",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                germanlevel: "",
                              });
                              hadnleLanguageSelect("German_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "German_3") !=
                              "" || detailProvider.germanlevel == "Fluent"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Fluent")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "German_4") ==
                              ""
                            ) {
                              hadnleLanguageSelect("German_4");
                              setDetailProvider({
                                ...detailProvider,
                                germanlevel: "Native",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                germanlevel: "",
                              });
                              hadnleLanguageSelect("German_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "German_4") !=
                              "" || detailProvider.germanlevel == "Native"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Native")}
                        </li>
                      </ul>
                    </div>
                    <div className="language">
                      <strong>{t("spanish")}</strong>
                      <ul>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Spanish_1") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Spanish_1");
                              setDetailProvider({
                                ...detailProvider,
                                spanishlevel: "Beginner",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                spanishlevel: "",
                              });
                              hadnleLanguageSelect("Spanish_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Spanish_1") !=
                              "" || detailProvider.spanishlevel == "Beginner"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Beginner")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Spanish_2") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Spanish_2");
                              setDetailProvider({
                                ...detailProvider,
                                spanishlevel: "Intermediate",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                spanishlevel: "",
                              });
                              hadnleLanguageSelect("Spanish_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Spanish_2") !=
                              "" ||
                            detailProvider.spanishlevel == "Intermediate"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Intermediate")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Spanish_3") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Spanish_3");
                              setDetailProvider({
                                ...detailProvider,
                                spanishlevel: "Fluent",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                spanishlevel: "",
                              });
                              hadnleLanguageSelect("Spanish_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Spanish_3") !=
                              "" || detailProvider.spanishlevel == "Fluent"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Fluent")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Spanish_4") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Spanish_4");
                              setDetailProvider({
                                ...detailProvider,
                                spanishlevel: "Native",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                spanishlevel: "",
                              });
                              hadnleLanguageSelect("Spanish_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Spanish_4") !=
                              "" || detailProvider.spanishlevel == "Native"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Native")}
                        </li>
                      </ul>
                    </div>
                    <div className="language">
                      <strong>{t("chinese")}</strong>
                      <ul>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Chinese_1") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Chinese_1");
                              setDetailProvider({
                                ...detailProvider,
                                chineselevel: "Beginner",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                chineselevel: "",
                              });
                              hadnleLanguageSelect("Chinese_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Chinese_1") !=
                              "" || detailProvider.chineselevel == "Beginner"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Beginner")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Chinese_2") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Chinese_2");
                              setDetailProvider({
                                ...detailProvider,
                                chineselevel: "Intermediate",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                chineselevel: "",
                              });
                              hadnleLanguageSelect("Chinese_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Chinese_2") !=
                              "" ||
                            detailProvider.chineselevel == "Intermediate"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Intermediate")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Chinese_3") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Chinese_3");
                              setDetailProvider({
                                ...detailProvider,
                                chineselevel: "Fluent",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                chineselevel: "",
                              });
                              hadnleLanguageSelect("Chinese_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Chinese_3") !=
                              "" || detailProvider.chineselevel == "Fluent"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Fluent")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Chinese_4") ==
                              ""
                            ) {
                              hadnleLanguageSelect("Chinese_4");
                              setDetailProvider({
                                ...detailProvider,
                                chineselevel: "Native",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                chineselevel: "",
                              });
                              hadnleLanguageSelect("Chinese_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Chinese_4") !=
                              "" || detailProvider.chineselevel == "Native"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Native")}
                        </li>
                      </ul>
                    </div>
                    <div className="language">
                      <strong>
                        {t("Other language")}
                        <input
                          type="text"
                          placeholder={t("text-type")}
                          onChange={(e) =>
                            setDetailProvider({
                              ...detailProvider,
                              otherlangname: e.target.value,
                            })
                          }
                          defaultValue={
                            detailProvider.otherlangname != null
                              ? detailProvider.otherlangname
                              : ""
                          }
                        />
                      </strong>
                      <ul>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Other_1") == ""
                            ) {
                              hadnleLanguageSelect("Other_1");
                              setDetailProvider({
                                ...detailProvider,
                                otherlevel: "Beginner",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                otherlevel: "",
                              });
                              hadnleLanguageSelect("Other_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Other_1") !=
                              "" || detailProvider.otherlevel == "Beginner"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Beginner")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Other_2") == ""
                            ) {
                              hadnleLanguageSelect("Other_2");
                              setDetailProvider({
                                ...detailProvider,
                                otherlevel: "Intermediate",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                otherlevel: "",
                              });
                              hadnleLanguageSelect("Other_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Other_2") !=
                              "" || detailProvider.otherlevel == "Intermediate"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Intermediate")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Other_3") == ""
                            ) {
                              hadnleLanguageSelect("Other_3");
                              setDetailProvider({
                                ...detailProvider,
                                otherlevel: "Fluent",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                otherlevel: "",
                              });
                              hadnleLanguageSelect("Other_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Other_3") !=
                              "" || detailProvider.otherlevel == "Fluent"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Fluent")}
                        </li>
                        <li
                          onClick={(e) => {
                            if (
                              languages.filter((e) => e.name == "Other_4") == ""
                            ) {
                              hadnleLanguageSelect("Other_4");
                              setDetailProvider({
                                ...detailProvider,
                                otherlevel: "Native",
                              });
                            } else {
                              setDetailProvider({
                                ...detailProvider,
                                otherlevel: "",
                              });
                              hadnleLanguageSelect("Other_5");
                            }
                          }}
                          className={
                            languages.filter((e) => e.name == "Other_4") !=
                              "" || detailProvider.otherlevel == "Native"
                              ? "active"
                              : ""
                          }
                        >
                          {t("Native")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="iconsec">
                    <div className="left2">
                      {detail.service_type &&
                      Object.keys(detail.service_type).length == 1 &&
                      detail.service_type.tab1 ? (
                        ""
                      ) : (
                        <div
                          className="form_group qualification full set "
                          style={{ marginBottom: "0" }}
                        >
                          <label>
                            {t("Do you have any allergies?")}
                            <span
                              className={
                                errorFields.anyallergies != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="checkbox create">
                            <ul
                              onClick={(e) =>
                                setErrorFields({
                                  ...errorFields,
                                  anyallergies: "",
                                })
                              }
                            >
                              <li>
                                <input
                                  type="radio"
                                  name="allergies"
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      anyallergies: "Yes",
                                    })
                                  }
                                  checked={
                                    detailProvider.anyallergies == "Yes"
                                      ? true
                                      : false
                                  }
                                />
                                <span> {t("confirm")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="allergies"
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      anyallergies: "No",
                                    })
                                  }
                                  checked={
                                    detailProvider.anyallergies == "No"
                                      ? true
                                      : false
                                  }
                                />
                                <span> {t("objection")}</span>
                              </li>
                            </ul>
                            {detailProvider.anyallergies == "Yes" && false ? (
                              <>
                                <textarea
                                  rows="2"
                                  placeholder={t("short-description")}
                                  maxlength="300"
                                  name="message"
                                  onChange={(e) => {
                                    setErrorFields({
                                      ...errorFields,
                                      anyallergiesdescription: "",
                                    });
                                    setDetailProvider({
                                      ...detailProvider,
                                      anyallergiesdescription: e.target.value,
                                    });
                                  }}
                                  defaultValue={
                                    detailProvider.anyallergiesdescription !=
                                    null
                                      ? detailProvider.anyallergiesdescription
                                      : ""
                                  }
                                  className={
                                    errorFields.anyallergiesdescription != ""
                                      ? "bordererror"
                                      : ""
                                  }
                                ></textarea>
                                <span>
                                  {t("number-characters")}
                                  {300 -
                                    detailProvider.anyallergiesdescription
                                      .length}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                      )}
                    </div>
                    <div className="right2">
                      <div className="form_group qualification full set">
                        <label>
                          {t("Do you have any")}
                          {" "}
                          <a>{t("medical condition")} </a>
                          <span className="smallpop">
                            {t("informationAbout")}
                          </span>
                          {" "}
                          {t(
                            "which could interfere with your work performance?"
                          )}
                          <span
                            className={
                              errorFields.medicalcondition != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox create">
                          <ul
                            onClick={(e) =>
                              setErrorFields({
                                ...errorFields,
                                medicalcondition: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name="medical"
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    medicalcondition: "Yes",
                                  })
                                }
                                checked={
                                  detailProvider.medicalcondition == "Yes"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="medical"
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    medicalcondition: "No",
                                  })
                                }
                                checked={
                                  detailProvider.medicalcondition == "No"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                          </ul>
                          {detailProvider.medicalcondition == "Yes" && false ? (
                            <>
                              <textarea
                                rows="2"
                                placeholder={t("short-description")}
                                maxlength="300"
                                name="message"
                                onChange={(e) => {
                                  setErrorFields({
                                    ...errorFields,
                                    medicalconditiondescription: "",
                                  });
                                  setDetailProvider({
                                    ...detailProvider,
                                    medicalconditiondescription: e.target.value,
                                  });
                                }}
                                defaultValue={
                                  detailProvider.medicalconditiondescription !=
                                  null
                                    ? detailProvider.medicalconditiondescription
                                    : ""
                                }
                                className={
                                  errorFields.medicalconditiondescription != ""
                                    ? "bordererror"
                                    : ""
                                }
                              ></textarea>
                              <span>
                                {t("number-characters")}
                                {300 -
                                  detailProvider.medicalconditiondescription
                                    .length}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                    </div>

                    <div className="left2" style={{ clear: "both" }}>
                      {detail.service_type && detail.service_type.tab1 ? (
                        <div className="icon">
                          <ul>
                            <>
                              <li
                                onClick={(e) =>
                                  setErrorFields({ ...errorFields, smoke: "" })
                                }
                                className={
                                  errorFields.smoke != "" ? "starred" : ""
                                }
                              >
                                {t("Do you smoke?")}
                                <div className="icons">
                                  <BanSmokeIcon
                                    className={
                                      habit.smoke == "false" ? "active" : ""
                                    }
                                    onClick={(e) => {
                                      setHabit({ ...habit, smoke: "false" });
                                    }}
                                  />

                                  <SmokeIcon
                                    className={
                                      habit.smoke == "true" ? "active" : ""
                                    }
                                    onClick={(e) =>
                                      setHabit({ ...habit, smoke: "true" })
                                    }
                                  />
                                </div>
                              </li>
                              <li
                                onClick={(e) =>
                                  setErrorFields({
                                    ...errorFields,
                                    carorlicence: "",
                                  })
                                }
                                className={
                                  errorFields.carorlicence != "" ? "starred" : ""
                                }
                              >
                                {t("Do you own a car or licence?")}
                                <div className="icons">
                                  <BanCarIcon
                                    className={
                                      habit.licence == "false" ? "active" : ""
                                    }
                                    onClick={(e) => {
                                      setHabit({ ...habit, licence: "false" });
                                    }}
                                  />

                                  <BanCarIcon
                                    className={
                                      habit.licence == "true" ? "active" : ""
                                    }
                                    onClick={(e) =>
                                      setHabit({ ...habit, licence: "true" })
                                    }
                                  />
                                </div>
                              </li>
                              <li
                                onClick={(e) =>
                                  setErrorFields({ ...errorFields, cooking: "" })
                                }
                                className={
                                  errorFields.cooking != "" ? "starred" : ""
                                }
                              >
                                {t("cooking-kids")}
                                <div className="icons">
                                  <BanCookIcon
                                    className={
                                      habit.kids == "false" ? "active" : ""
                                    }
                                    onClick={(e) => {
                                      setHabit({ ...habit, kids: "false" });
                                    }}
                                  />

                                  <CookIcon
                                    className={
                                      habit.kids == "true" ? "active" : ""
                                    }
                                    onClick={(e) =>
                                      setHabit({ ...habit, kids: "true" })
                                    }
                                  />
                                </div>
                              </li>
                              <li
                                onClick={(e) =>
                                  setErrorFields({
                                    ...errorFields,
                                    lighthousework: "",
                                  })
                                }
                                className={
                                  errorFields.lighthousework != ""
                                    ? "starred"
                                    : ""
                                }
                              >
                                {t("light-housework")}
                                <div className="icons">
                                  <BanHouseIcon
                                    className={
                                      habit.housework == "false" ? "active" : ""
                                    }
                                    onClick={(e) =>
                                      setHabit({ ...habit, housework: "false" })
                                    }
                                  />

                                  <HouseIcon
                                    className={
                                      habit.housework == "true" ? "active" : ""
                                    }
                                    onClick={(e) =>
                                      setHabit({ ...habit, housework: "true" })
                                    }
                                  />
                                </div>
                              </li>
                              <li
                                onClick={(e) =>
                                  setErrorFields({
                                    ...errorFields,
                                    traveling: "",
                                  })
                                }
                                className={
                                  errorFields.traveling != "" ? "starred" : ""
                                }
                              >
                                {t("Traveling with family")}
                                <div className="icons">
                                  <BanPlaneIcon
                                    className={
                                      habit.family == "false" ? "active" : ""
                                    }
                                    onClick={(e) => {
                                      setHabit({ ...habit, family: "false" });
                                    }}
                                  />

                                  <PlaneIcon
                                    className={
                                      habit.family == "true" ? "active" : ""
                                    }
                                    onClick={(e) =>
                                      setHabit({ ...habit, family: "true" })
                                    }
                                  />
                                </div>
                              </li>
                            </>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="form_group full"
                        style={{ marginTop: "20px" }}
                      >
                        <label>
                          {t("Interested in working abroad")}
                          <span
                            className={
                              errorFields.workingabroad != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="checkbox create">
                          <ul
                            onClick={(e) =>
                              setErrorFields({
                                ...errorFields,
                                workingabroad: "",
                              })
                            }
                          >
                            <li>
                              <input
                                type="radio"
                                name="abroad"
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    workingabroad: "Yes",
                                  })
                                }
                                checked={
                                  detailProvider.workingabroad == "Yes"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("confirm")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="abroad"
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    workingabroad: "No",
                                  })
                                }
                                checked={
                                  detailProvider.workingabroad == "No"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("objection")}</span>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="abroad"
                                onClick={(e) =>
                                  setDetailProvider({
                                    ...detailProvider,
                                    workingabroad: "Open to offers",
                                  })
                                }
                                checked={
                                  detailProvider.workingabroad ==
                                  "Open to offers"
                                    ? true
                                    : false
                                }
                              />
                              <span> {t("Open to offers")}</span>
                            </li>
                          </ul>
                        </div>
                        {/* <div className='errorfield'>{error.message}</div>*/}
                      </div>
                      {detail.service_type &&
                      detail.service_type.tab1 &&
                      Object.keys(detail.service_type).length == 1 ? (
                        <div className="form_group full">
                          <label>
                            {t("Would you consider living with the family?")}
                            <span
                              className={
                                errorFields.livewithfamily != "" ? "starred" : ""
                              }
                            >
                              *
                            </span>
                          </label>
                          <div className="checkbox create">
                            <ul
                              onClick={(e) =>
                                setErrorFields({
                                  ...errorFields,
                                  livewithfamily: "",
                                })
                              }
                            >
                              <li>
                                <input
                                  type="radio"
                                  name="family"
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      livewithfamily: "Yes",
                                    })
                                  }
                                  checked={
                                    detailProvider.livewithfamily == "Yes"
                                      ? true
                                      : false
                                  }
                                />
                                <span> {t("confirm")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="family"
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      livewithfamily: "No",
                                    })
                                  }
                                  checked={
                                    detailProvider.livewithfamily == "No"
                                      ? true
                                      : false
                                  }
                                />
                                <span> {t("objection")}</span>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="family"
                                  onClick={(e) =>
                                    setDetailProvider({
                                      ...detailProvider,
                                      livewithfamily: "Open to offers",
                                    })
                                  }
                                  checked={
                                    detailProvider.livewithfamily ==
                                    "Open to offers"
                                      ? true
                                      : false
                                  }
                                />
                                <span>{t("Open to offers")}</span>
                              </li>
                            </ul>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div>*/}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          generalInfo.security == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              security: generalInfo.security == "" ? "active" : "",
            })
          }
        >
          {t("security-verification")}
        </h3>
        {generalInfo.security == "active" ? (
          <div className="editkids security">
            {editGeneralIngo.security == "" ? (
              <>
                <button onClick={(e) => setEditGeneralInfo({ ...editGeneralIngo, security: "edit" })}>
                  <img
                    src={window.location.origin + "/images/edit.svg"}
                    alt=""
                  />
                </button>

                <label>
                  {t("Verified accounts")}
                  <span>
                    <strong>
                      <div className="social_verify">
                        <FacebookIcon
                          className={
                            detailProvider.facebookverify ? "active" : ""
                          }
                          fiiColor={"#fff"}
                          color={"#B7B7B7"}
                          textColor={"#B7B7B7"}
                        />

                        <LinkedinIcon
                          className={
                            detailProvider.linkdinverify ? "active" : ""
                          }
                          color={"#B7B7B7"}
                        />
                      </div>
                    </strong>
                  </span>
                </label>
              </>
            ) : (
              <div className="Profile_complete">
                <div className="detail  verification  setp7 mg editkids security">
                  <div className="form_group">
                    <label>{t("verify-accounts")}</label>
                    <div className="social_verify">
                      <FacebookProvider appId="587997313887434">
                        <LoginButton
                          scope="email"
                          onCompleted={handleResponse}
                          onError={handleErrorFields}
                          className="facebook"
                        >
                          <span>
                          <FacebookIcon
                          className={
                            detailProvider.facebookverify ? "active" : ""
                          }
                          fiiColor={"none"}
                          color={"#B7B7B7"}
                          textColor={"#B7B7B7"}
                        />
                          </span>
                        </LoginButton>
                      </FacebookProvider>
                      {console.log(detailProvider)}
                      <LinkedinContent
                        handleResponse={handleResponse}
                        profile={"icon"}
                        status={detailProvider.linkdinverify}
                      />
                    </div>
                  </div>
                  <div className="form_group fs">
                    <div className="verification_number ">
                      <label>
                        <b>{t("Verified phone number")}</b>
                      </label>
                      <div className="number">
                        <input
                          type="text"
                          placeholder={t("enter-number")}
                          value={
                            detail.phone != null
                              ? "+" + detail.countrycode + " - " + detail.phone
                              : ""
                          }
                          disabled
                        />

                        <button>
                          {t("Verified")}
                          <DoneIcon />
                        </button>
                      </div>
                      <br />
                      <label>{t("Enter new number for verification")}</label>
                      <div className="number profilecretate">
                        <div
                          className="country_flag"
                          onClick={(e) => handleCodeSelect()}
                        >
                          {/* <img src={contact_code.flag} /> {contact_code.code} */}
                          <img
                            src={
                              contactCode.flag &&
                              `${window.location.origin}/${contactCode.flag}`
                            }
                          />
                          {contactCode.code}
                        </div>
                        <ul style={isCode ? { display: "none" } : {}}>
                          {DATA_COUNTRY.data.map((e) => {
                            return (
                              <li
                                onClick={(a) => {
                                  handleCodeSelect();
                                  setContactCode({
                                    flag: e.flag,
                                    code: e.dial_code,
                                  });
                                }}
                              >
                                <img
                                  src={window.location.origin + "/" + e.flag}
                                />
                                {e.country + " " + " " + e.dial_code}
                              </li>
                            );
                          })}
                        </ul>
                        <input
                          type="number"
                          className={
                            errorField.phone && errorField.phone.length > 2
                              ? "bordererror"
                              : ""
                          }
                          placeholder={t("enter-number")}
                          onChange={(e) => {
                            setDetailProvider({
                              ...detailProvider,
                              phone: e.target.value,
                            });
                            detailProvider.phone.length > 7
                              ? setErrorField({ ...errorField, phone: "" })
                              : setErrorField({ ...errorField, phone: "error" });
                          }}
                        />

                        <button
                          onClick={(e) => handleMobileVerify()}
                          disabled={
                            detail.phone != detailProvider.phone &&
                            detailProvider.phone.length == 8
                              ? false
                              : true
                          }
                        >
                          {isConditionWait ? t("text-wait") : t("get-code")}
                        </button>
                      </div>
                      <br />
                      <label>{t("enter-code-verify")}</label>
                      <div className="number">
                        <input
                          type="number"
                          className={
                            errorField.otperror && errorField.otperror.length > 2
                              ? "bordererror"
                              : ""
                          }
                          placeholder={t("enter-code")}
                          onChange={(e) => {
                            setVerifyOtp(e.target.value);
                            verifyOtp.length > 2
                              ? setErrorField({ ...errorField, otperror: "" })
                              : setErrorField({ ...errorField, otperror: "error" });
                          }}
                        />
                        <button
                          onClick={(e) => handleOtpVerify()}
                          style={
                            detail.phoneVerifiedStatus != null &&
                            detail.phoneVerifiedStatus == 1
                              ? { display: "none" }
                              : {}
                          }
                        >
                          {t("text-verify")}
                        </button>
                      </div>
                    </div>
                    <div
                      className="success"
                      id="success"
                      style={
                        detail.phoneVerifiedStatus != null &&
                        detail.phoneVerifiedStatus == 1
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <FlagIcon />

                      {t("verification-thank")}
                    </div>
                    <div
                      className="success2"
                      id="success4"
                      style={{ display: "none" }}
                    >
                      <MarkIcon />
                      {t(
                        " Verification has failed. Please check if you have entered the correct number and try again in 60 seconds."
                      )}
                    </div>
                    <div
                      className="success2"
                      id="success3"
                      style={{ display: "none" }}
                    >
                      <MarkIcon />
                      {t("verification-fail-contact")}
                      <Link to="/contact-us">{t("tech-support")}</Link>
                      {t("further-assistance")}
                    </div>
                    {/* <div className='errorfield'>{error.message}</div>*/}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          generalInfo.personal == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setGeneralInfo({
              ...generalInfo,
              personal: generalInfo.personal == "" ? "active" : "",
            })
          }
        >
          {t("Personal Preferences")}
        </h3>
        {generalInfo.personal == "active" ? (
          <div className="personal_preferences">
            <div className="notification_edt">
              <h6>{t("Alerts and notifications")}</h6>
              <label>{t("Send alerts for")}</label>
              <ul>
                {/* <li>Deals
                                    <label class="switchedit">
                                        <input type="checkbox" onClick={e => setdetailprovider({ ...detailprovider, alertDeals: e.target.checked ? "yes" : "no" })} checked={detailprovider.alertDeals == "yes" ? true : false} />
                                        <span class="slideredit roundedit"></span>
                                    </label>
                                </li>
                                <li>EDU products
                                    <label class="switchedit">
                                        <input type="checkbox" onClick={e => setdetailprovider({ ...detailprovider, alertEDUproducts: e.target.checked ? "yes" : "no" })} checked={detailprovider.alertEDUproducts == "yes" ? true : false} />
                                        <span class="slideredit roundedit"></span>
                                    </label>
                                </li> */}
                <li>
                  {t("Job posts")}
                  <label class="switchedit">
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        setDetailProvider({
                          ...detailProvider,
                          alertJobposts: e.target.checked ? "yes" : "no",
                        })
                      }
                      checked={
                        detailProvider.alertJobposts == "yes" ||
                        detailProvider.alertJobposts == ""
                          ? true
                          : false
                      }
                    />
                    <span class="slideredit roundedit"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="notification_edt">
              <h6>{t("Profile actions")}</h6>
              <ul style={{ gap: "10px" }}>
                <li>
                  {t("Share profile")}
                  <label class="switchedit">
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        setDetailProvider({
                          ...detailProvider,
                          plateformsocialmedia: e.target.checked ? "Yes" : "No",
                        });
                      }}
                      checked={
                        detailProvider.plateformsocialmedia == "Yes" ||
                        detailProvider.plateformsocialmedia == ""
                          ? true
                          : false
                      }
                    />
                    <span class="slideredit roundedit"></span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
        <div class="button text-center">
          <div class="pull-right">
            <button
              class="btn"
              onClick={(e) => {
                localStorage.setItem("search", "Profile");
                window.location.reload();
              }}
            >
              {t("text-cancel")}
            </button>
          </div>
          <div class="pull-right">
            <button
              class="btn confirm"
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                errorField.email != "" ? setErrorField({ ...errorField }) : handleProfileUpdate();
              }}
            >
              {t("Save changes")}
            </button>
          </div>
        </div>
      </div>

      {showModal ? (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="promocode_content younger statuschange">
              <Link to="" onClick={handleCloseModal}>
                +
              </Link>
              <h5>{t("Change the status of profession")}</h5>
              <br />
              <p style={{ marginBottom: "0" }}>
                <b>
                  {t(
                    "selected-profession-active"
                  )}
                </b>
              </p>
              <div className="graybg">
                <p>
                  {t(
                    "post-senscare-profession"
                  )}
                </p>
                <div class="button text-center">
                  <div class="pull-right">
                    <button class="btn" onClick={handleCloseModal}>
                      {t("text-cancel")}
                    </button>
                  </div>
                  <div class="pull-right">
                    <button
                      class="btn confirm"
                      onClick={(e) => {
                        if (isDisable == 1) {
                            setProfession({ tab1: "Nanny" });
                            let x = JSON.parse(
                              sessionStorage.getItem("service")
                            );

                            if (activeProfession.tab1) {
                              delete x["tab1"];
                              setProfession({
                                tab1: "Nanny",
                              });
                            } else {
                              setProfession({});
                              x.tab1 = 'Nanny';
                            }

                            setActiveProfession(x);

                          handleCloseModal();
                        } else if (isDisable == 2) {
                            setProfession({
                              tab2: "Special Education Teacher",
                            });
                            let x = JSON.parse(
                              sessionStorage.getItem("service")
                            );
                            if (activeProfession.tab2) {
                              delete x["tab2"];
                              setProfession({
                                tab2: "Special Education Teacher",
                              });
                            } else {
                              setProfession({});
                              x.tab2 = 'Special Education Teacher';
                            }
                          setActiveProfession(x);



                          handleCloseModal();
                        } else if (isDisable == 3) {
                            setProfession({
                              tab3: "Special Education Paraprofessional",
                            });
                            let x = JSON.parse(
                              sessionStorage.getItem("service")
                            );
                            if (activeProfession.tab3) {
                              delete x["tab3"];
                              setProfession({
                                tab3: "Special Education Paraprofessional",
                              });
                            } else {
                              setProfession({});
                              x.tab3 = 'Special Education Paraprofessional';
                            }
                          setActiveProfession(x);


                          handleCloseModal();
                        } else if (isDisable == 4) {
                            setProfession({ tab4: "Tutor" });
                            let x = JSON.parse(
                              sessionStorage.getItem("service")
                            );

                            if (activeProfession.tab4) {
                              delete x["tab4"];
                              setProfession({
                                tab4: "Tutor",
                              });
                            } else {
                              setProfession({});
                              x.tab4 = 'Tutor';
                            }

                          setActiveProfession(x);

                          handleCloseModal();
                        }
                      }}
                    >
                      {t("Change status")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      {uploadPhoto ? (
        <Modal show={uploadPhoto} onHide={(e) => setUploadPhoto(false)}>
          <Modal.Body>
            <div className="promocode_content cancelmembership uploadphoto">
              <div className="">
                <p>
                  <strong>{t("Upload profile photos")}</strong>
                </p>
                <br />
                <ul>
                  <li>
                    {photo != "" ? (
                      <>
                        <img src={image} alt="preview image" />
                        <button onClick={(e) => setPhoto("")}>
                          <DumpIcon />
                        </button>
                      </>
                    ) : (
                      <>
                        <img
                          src={api + "/assets/images/users/" + detail.file_path}
                          alt="preview image"
                        />
                      </>
                    )}
                    <input
                      type={"file"}
                      onChange={handleChangeImage}
                      accept="image/*"
                    />
                  </li>
                  <li>
                    <AddIcon />
                    <button>
                      <DumpIcon />
                    </button>
                  </li>
                </ul>
                <ol>
                  <li>
                    <div className="select_photoprofile">
                      <input
                        type="radio"
                        id="photo"
                        name="photo"
                        checked="true"
                      />
                      <span>{t("Set as profile photo")}</span>
                    </div>
                  </li>
                  <li>
                    <div className="select_photoprofile">
                      <input type="radio" id="photo" name="photo" />
                      <span>{t("Set as profile photo")}</span>
                    </div>
                  </li>
                </ol>
                <br />
                <p className="giveus">
                  {t("upload-files")}
                </p>

                <div class="button text-center">
                  <div class="pull-right">
                    <button class="btn" onClick={(e) => setUploadPhoto()}>
                      {t("text-close-up")}
                    </button>
                  </div>
                  <div class="pull-right">
                    <button
                      class="btn confirm"
                      onClick={(e) => handleProfileUpdateNew()}
                    >
                      {t("Upload photo")}
                    </button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}
