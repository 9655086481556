import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RecommendationsParentsProfile } from "../RecommendationsParentsProfile";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
  REFINE_OPTIONS,
} from "../../../utils/variables";
import {
  fetchDeleteMultiplyRecommendations,
  fetchRecommendationRequest,
} from "../../../api/recommendationApi";

export function RecommendationsProfile() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [isCheck, setIsCheck] = useState(true);
  const [recommendationsData, setRecommendationsData] = useState([]);
  const [view, setView] = useState("");
  const [deleteList, setDeleteList] = useState([]);
  const [recived, setRecived] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [refine, setRefine] = useState({
    threedays: "",
    withinweek: "",
    twofourday: "",
    withinmonth: "",
  });

  const slugData = useParams();
  const navigate = useNavigate();

  let data = slugData.name;
  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleRecommendationData = async () => {
    setRecommendationsData([]);
    try {
      const result = fetchRecommendationRequest(refine);
      setRecommendationsData(result.data || []);
      console.log(result);
    } catch (error) {
      console.error("Error fetching recommendations data:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      handleRecommendationData();
      setIsCheck(false);
    }
    console.log(recommendationsData);

    setView(data ? data : "");
  }, [isCheck, recommendationsData, data, slugData]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }
  }, []);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    recommendationsData.map((data) => {
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };

  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteSelect = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
  };

  const handleDeleteRecommendation = async () => {
    try {
      await fetchDeleteMultiplyRecommendations(deleteList);
      handleRecommendationData();
    } catch (error) {
      console.error("Error deleting recommendations:", error);
    }
  };

  const handleRefineDay = (e) => {
    setRefine({
      threedays: e == "3" ? true : "",
      withinweek: e == "7" ? true : "",
      twofourday: e == "24" ? true : "",
      withinmonth: e == "30" ? true : "",
    });
    setIsCheck(true);
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("recommendation-requests")}</h2>
            <div class="mail-header-bar">
              <p>
                {recommendationsData.length}
                {t("text-recommendations")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(recived)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive && (
                    <ul>
                      {REFINE_OPTIONS.map((option) => (
                        <li
                          key={option.label}
                          onClick={() => {
                            setRecived(t(option.label));
                            handleRefineDay(option.value);
                            setIsActive(!isActive);
                          }}
                        >
                          {t(option.label)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div
                  class="delete-btn"
                  onClick={(e) => handleDeleteRecommendation()}
                >
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div
                  class="refresh-btn"
                  onClick={(e) => handleRecommendationData()}
                >
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-inbox table-hover">
            <tbody>
              {recommendationsData.map((data) => {
                return (
                  <tr class={data.read_status == 0 ? "unread" : ""}>
                    <td class="view-message  dont-show">
                      <input
                        type="checkbox"
                        class="mail-radio"
                        name="chk"
                        onChange={(e) => handleDeleteSelect(data.id)}
                      />
                      <span className="circle"></span>
                      <span
                        onClick={(e) => {
                          navigate(
                            "/search-parents/" + slugData.id + "/" + data.id
                          );
                          setView(data.id);
                        }}
                      >
                        
                        {data.read_status == 0 ? (
                          <span class="new-tag"> {t("text-new-up")}</span>
                        ) : (
                          ""
                        )}
                        <span
                          class="username-text"
                          onClick={(e) => {
                            navigate(
                              "/search-parents/" + slugData.id + "/" + data.id
                            );
                            setView(data.id);
                          }}
                        >
                          
                          {data.ProviderName}
                        </span>
                        {t("has just requested a recommendation from you")}
                      </span>
                    </td>
                    <td class="view-message  text-right">
                      {month[new Date(data.created_at).getMonth()] +
                        " " +
                        new Date(data.created_at).getDate()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div class="main-header">
          <button
            onClick={(e) => {
              navigate("/search-parents/" + slugData.id);
              setView("");
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <RecommendationsParentsProfile id={view} />
        </div>
      )}
    </div>
  );
}
