import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { MONTH } from "../../../utils/variables";

export const DetailInvitation = ({ parentsData, message }) => {
  const { t } = useTranslation();

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return `${
      MONTH[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}, ${date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  };

  const getDocumentNames = (documentName) => {
    const docName = documentName?.substr(documentName.lastIndexOf("\\") + 1);
    try {
      const parsedDocName = JSON.parse(docName);
      return parsedDocName
        .map((e, i) => t(e) + (i + 1 === parsedDocName.length ? " " : " , "))
        .join("");
    } catch (error) {
      return docName;
    }
  };

  return (
    <div className="detail_invit">
      <p>
        <span className="date">
          {parentsData &&
            parentsData[0] &&
            getFormattedDate(parentsData[0].created_at)}
        </span>
      </p>
      <h2>
        {t("text-dear")} <span>{message}</span>,
      </h2>
      <h2>
        <Link to={`/profile-parents/${parentsData[0]?.user_id}`}>
          {parentsData && parentsData[0] && parentsData[0].first_name}
        </Link>
          {" "}
        {t("just-request")}
        <strong style={{ marginLeft: "5px" }}>
          {parentsData &&
            parentsData[0] &&
            getDocumentNames(parentsData[0]?.documment_name)}
          {t("documents")}
            {" "}
        </strong>
        {t("text-from-you")}
      </h2>
      <br />
      <br />
    </div>
  );
};
