import { create } from "zustand";

export const useProfileDetailsStore = create((set) => ({
  qualifications: {
    English: "",
    Serbian: "",
    Mathematics: "",
    Physics: "",
    Chemistry: "",
    Other: "",
  },
  oralSpeak: JSON.parse(localStorage.getItem("oralSpeak"))  || {
    English: "",
    Spanish: "",
    French: "",
    Chinese: "",
    German: "",
    Italian: "",
    Other: "",
  },
  habit: {
    licence: "",
    kids: "",
    housework: "",
    family: "",
  },
  oralSpeakDefault: {
    English: "",
    Spanish: "",
    French: "",
    Chinese: "",
    German: "",
    Italian: "",
    Other: "",
  },

  setQualifications: (newQualifications) =>
    set({ qualifications: newQualifications }),
  setOralSpeak: (newOralSpeak) => {
    set((state) => {
      const updatedOralSpeak = {
        ...state.oralSpeak,
        ...newOralSpeak,
      };
      localStorage.setItem("oralSpeak", JSON.stringify(updatedOralSpeak));
      return { oralSpeak: updatedOralSpeak };
    });
  },
  setHabit: (newHabit) => set({ habit: newHabit }),
}));
