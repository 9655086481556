import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { FavoriteSelect } from "../FavoriteSelect";
import { SocialFacebook } from "../SocialFacebook";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";

import { getCountryDetails } from "../../../api/countryApi";
import {
  getProviderDocuments,
  historyRequestDocument,
  sendDocumentType,
} from "../../../api/documentApi";
import { useQualificationStore } from "../../../store/useQualificationStore";
import { DetailInvitation } from "./DetailInvitation";
import { CandidateBox } from "./CandidateBox";
import { ShareModal } from "./Modals/ShareModal";
import { DocumentModal } from "./Modals/DocumentModal";
import { SendModal } from "./Modals/SendModal";

export function DocumentRequest({ id }) {
  const { t } = useTranslation();

  const [parentsData, setParentsData] = useState([]);

  const [providerData, setProviderData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [profile, setProfile] = useState({});
  const [isElement, setIsElement] = useState(true);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(false);
  const [selectType, setSelectType] = useState("Mycomputer");
  const [document, setDocument] = useState({});
  const [submit, setSubmit] = useState("");
  const [showSendModal, setShowSendModal] = useState(false);

  const { detailProvider, setDetailProvider, resetQualificationState } =
    useQualificationStore();

  useEffect(() => {
    return () => {
      resetQualificationState();
    };
  }, []);

  const handleElementById = (e, x, z) => {
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const fetchProfileData = async () => {
    try {
      const result = await getCountryDetails();
      setProfile(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDocumentData = async () => {
    try {
      const providerResult = await getProviderDocuments();
      setProviderData(providerResult.data);

      const historyResult = await historyRequestDocument(id);

      setParentsData([historyResult.data.getparentsData]);
      setMessage(historyResult.data.currentUserName);
    } catch (error) {
      console.error("Error fetching document data:", error);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (isCheck) {
      fetchDocumentData();
      fetchProfileData();
      setIsCheck(false);
    }
  }, []);
// isCheck, selectType, detailProvider, document

  const handleSubmitDocument = async () => {
    setSubmit("h");

    try {
      const result = await sendDocumentType(
        document,
        parentsData,
        id,
        selectType
      );

      setShowSendModal(false);
      setShowDocumentModal(false);
      setIsShareModal(false);
      setStatus(true);
      setDocument({});
      setSubmit("");
      setDetailProvider({
        recommendationsfile: "",
        qualificationscertificatesname: "",
        cv: "",
        qualificationscertificatesdoc: "",
        backgrounddoc: "",
      });

      setTimeout(() => {
        setStatus(false);
      }, 4000);
    } catch (error) {
      console.error("Error sending document:", error);
      setError(error.message);
      setSubmit("");

      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  return (
    <>
      {status && (
        <div className="popup_status" id="popup_status">
          {t("post-successfully-sent")}
        </div>
      )}
      {parentsData && parentsData[0] ? (
        <>
          <h2 className="border"></h2>
          <DetailInvitation parentsData={parentsData} message={message} />
          <div class="right_side_section">
            <div class="looking_for_candidate">
              {parentsData.map((data, index) => {
                return (
                  <CandidateBox
                    key={index}
                    data={data}
                    index={index}
                    profile={profile}
                    setShowSendModal={setShowSendModal}
                  />
                );
              })}
              <div className="mobile">
                {parentsData.map((data, index) => {
                  if (index <= 5) {
                    return (
                      <>
                        <div
                          class="looking_for_candidate_boxs"
                          style={{ display: "block" }}
                        >
                          <div class="looking_for_candidate_box">
                            <div class="second_sec">
                              <div class="heading">
                                <h3>
                                  {data?.jobs[0]?.title != null
                                    ? data.jobs[0].title
                                    : ""}
                                </h3>
                                {data?.jobs[0]?.plateformonsocialmedia ==
                                  "Yes" && (
                                  <SocialFacebook
                                    link={
                                      window.location.origin +
                                      "/profile-parents/" +
                                      data.user_id
                                    }
                                  />
                                )}
                              </div>
                              <div class="post_general">
                                <h6>
                                  <Link
                                    to={
                                      "/profile-parents/" +
                                      parentsData[0]?.user_id
                                    }
                                  >
                                    {data.first_name != null
                                      ? data.first_name +
                                        " " +
                                        data.last_name +
                                        " "
                                      : ""}
                                  </Link>
                                  (
                                  {data.dob != undefined
                                    ? new Date().getFullYear() -
                                      parseInt(
                                        data.dob
                                          .substr(
                                            data.dob.lastIndexOf("\\") + 1
                                          )
                                          .split("-")[0]
                                      )
                                    : ""}
                                  )
                                </h6>
                                <p>
                                  {data.reviewAvg >= 0 ? (
                                    <>
                                      {[...Array(data.reviewAvg)].map(
                                        (star, index) => {
                                          index += 1;
                                          return <StarIcon />;
                                        }
                                      )}
                                      {[...Array(5 - data.reviewAvg)].map(
                                        (star, index) => {
                                          index += 1;
                                          return <WhiteStarIcon />;
                                        }
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <span> ({data.reviewcount})</span>
                                </p>
                              </div>
                              <div class="post_detail">
                                <div class="post">
                                  {JSON.parse(data.service_type) != null ? (
                                    Object.values(
                                      JSON.parse(data.service_type)
                                    )[0] == "Nanny" ? (
                                      <img
                                        src={
                                          window.location.origin +
                                          "/images/nany_post.svg"
                                        }
                                        alt=""
                                      />
                                    ) : Object.keys(
                                        JSON.parse(data.service_type)
                                      )[0] == "tab2" ? (
                                      <img
                                        src={
                                          window.location.origin +
                                          "/images/teacher_post.svg"
                                        }
                                        alt=""
                                      />
                                    ) : Object.keys(
                                        JSON.parse(data.service_type)
                                      )[0] == "tab3" ? (
                                      <img
                                        src={
                                          window.location.origin +
                                          "/images/education_post.svg"
                                        }
                                        alt=""
                                      />
                                    ) : Object.keys(
                                        JSON.parse(data.service_type)
                                      )[0] == "tab4" ? (
                                      <img
                                        src={
                                          window.location.origin +
                                          "/images/tutor_post.svg"
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                  <h5>
                                    {JSON.parse(data.service_type) != null
                                      ? Object.values(
                                          JSON.parse(data.service_type)
                                        )[0] ==
                                        "Special Education Paraprofessional"
                                        ? "SPED Paraprofessional"
                                        : Object.values(
                                            JSON.parse(data.service_type)
                                          )[0] == "Special Education Teacher"
                                        ? "SPED teacher"
                                        : Object.values(
                                            JSON.parse(data.service_type)
                                          )[0]
                                      : ""}
                                  </h5>
                                </div>
                                <div class="vi"></div>
                                <div class="post_pay">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/img/post_pay.png"
                                    }
                                    alt=""
                                  />
                                  {profile && profile.country == "Serbia" ? (
                                    <h5>
                                      {data.nanyperhrrate != null
                                        ? data.nanyperhrrate
                                            .substr(
                                              data.nanyperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[0] *
                                            100 +
                                          " - " +
                                          data.nanyperhrrate
                                            .substr(
                                              data.nanyperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[1] *
                                            100
                                        : data.tutorperhrrate
                                        ? data.tutorperhrrate
                                            .substr(
                                              data.tutorperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[0] *
                                            100 +
                                          " - " +
                                          data.tutorperhrrate
                                            .substr(
                                              data.tutorperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[1] *
                                            100
                                        : ""}
                                      {t("time-hour")}
                                    </h5>
                                  ) : (
                                    <h5>
                                      $
                                      {data.nanyperhrrate != null
                                        ? data.nanyperhrrate
                                        : data.tutorperhrrate
                                        ? data.tutorperhrrate
                                        : ""}
                                      {t("time-hour")}
                                    </h5>
                                  )}
                                </div>
                                <div class="vi"></div>
                                <div class="post_fet">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/post_fet.svg"
                                    }
                                    alt=""
                                  />
                                  <h5>
                                    {data.nanyintrestedin != null
                                      ? data.nanyintrestedin
                                      : "Full time"}
                                  </h5>
                                </div>
                                <div class="vi"></div>
                                <div class="post_cal">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/post_cal.svg"
                                    }
                                    alt=""
                                  />
                                  <h5>
                                    {data.nanystartdate != null
                                      ? data.nanystartdate
                                      : data.tutorstartdate}
                                  </h5>
                                </div>
                                <div class="vi"></div>
                                <div class="post_loc">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/post_loc.svg"
                                    }
                                    alt=""
                                  />
                                  <h5>
                                    {data.country != null ? data.country : ""} ,
                                    {data.city != null ? data.city : ""}
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div class="first_sec">
                              <div class="image_sec">
                                <img
                                  src={
                                    data.file_path != null
                                      ? "https://admin.stage.mysenscare.com/assets/images/users/" +
                                        data.file_path
                                      : window.location.origin +
                                        "/img/nany_img.png"
                                  }
                                  alt=""
                                />
                                <div class="heart_sec">
                                  <FavoriteSelect
                                    id={data.user_id}
                                    username={data.first_name}
                                  />
                                </div>
                              </div>
                              <div class="nany_social">
                                {data.phoneVerifiedStatus == 1 ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/nany_phone.svg"
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <PhoneIcon />
                                )}
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/nany_msg.svg"
                                  }
                                  alt=""
                                />
                                {data.facebookverify != null ||
                                data.linkdinverify != null ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/nany_cont.svg"
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <ServiceIcon />
                                )}
                              </div>
                            </div>
                            <div class="second_sec">
                              <p id={"half" + index}>
                                {data.jobs[0].description != null
                                  ? data.jobs[0].description.substr(0, 100)
                                  : ""}
                                {data.jobs[0].description != null &&
                                data.jobs[0].description.length > 100 ? (
                                  <span
                                    onClick={(e) =>
                                      handleElementById(
                                        "half" + index,
                                        "full" + index,
                                        "plink" + index
                                      )
                                    }
                                  >
                                    {t("text-more")}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                              <p id={"full" + index} className="hide">
                                {data.jobs[0].description}
                                <span
                                  onClick={(e) =>
                                    handleElementById(
                                      "full" + index,
                                      "half" + index,
                                      "plink" + index
                                    )
                                  }
                                >
                                  less
                                </span>
                              </p>
                            </div>
                            <div
                              class="view_profile_btn newadd visiterbtn"
                              style={{ float: "right" }}
                            >
                              <Link to={"/profile-parents/" + data.user_id}>
                                {t("visit-profile")}
                              </Link>
                              <a
                                onClick={(e) => setShowSendModal(true)}
                                className="scolor"
                              >
                                {t("send-documents")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          {showSendModal && (
            <SendModal
              showSendModal={showSendModal}
              setShowSendModal={setShowSendModal}
              setSelectType={setSelectType}
              setIsShareModal={setIsShareModal}
              error={error}
              setDocument={setDocument}
              handleSubmitDocument={handleSubmitDocument}
              setShowDocumentModal={setShowDocumentModal}
              document={document}
              documentName={parentsData[0].documment_name || ""}
            />
          )}
        </>
      ) : (
        ""
      )}

      {isShareModal && (
        <ShareModal
          providerData={providerData}
          isShareModal={isShareModal}
          setIsShareModal={setIsShareModal}
          handleSubmitDocument={handleSubmitDocument}
          setDocument={setDocument}
        />
      )}

      {showDocumentModal && (
        <DocumentModal
          parentsData={parentsData}
          showDocumentModal={showDocumentModal}
          setShowDocumentModal={setShowDocumentModal}
          error={error}
          submit={submit}
          handleSubmitDocument={handleSubmitDocument}
        />
      )}

      {submit != "" && (
        <Modal show={true} className="upload_doc_modul">
          <div className="loder_back">
            <div class="loader"></div>
          </div>
        </Modal>
      )}
    </>
  );
}
