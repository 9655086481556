import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FavoriteSelect } from "../FavoriteSelect";
import { FavoriteProfile } from "../FavoriteProfile";

import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";

export const InitialDataItem = ({
  data,
  handleDeleteVisitMe,
  received,
  profile,
}) => {
  const { t } = useTranslation();

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<StarIcon key={i} />);
      } else {
        stars.push(<WhiteStarIcon key={i} />);
      }
    }
    return stars;
  };

  const renderServiceIcon = (serviceType) => {
    switch (serviceType) {
      case "Nanny":
      case "Dadilja":
        return (
          <img
            src={window.location.origin + "/images/nany_post.svg"}
            alt="Nanny"
          />
        );
      case "Special Education Teacher":
        return (
          <img
            src={window.location.origin + "/images/teacher_post.svg"}
            alt="Teacher"
          />
        );
      case "Special Education Paraprofessional":
        return (
          <img
            src={window.location.origin + "/images/education_post.svg"}
            alt="Education"
          />
        );
      case "Tutor":
        return (
          <img
            src={window.location.origin + "/images/tutor_post.svg"}
            alt="Tutor"
          />
        );
      default:
        return null;
    }
  };

  const renderRate = (rate) => {
    if (profile && profile.country === "Serbia") {
      return rate != null
        ? rate.substr(rate.lastIndexOf("\\") + 1).split("-")[0] * 100 +
            " - " +
            rate.substr(rate.lastIndexOf("\\") + 1).split("-")[1] * 100
        : "";
    } else {
      return `$${rate != null ? rate : ""} ${t("time-hour")}`;
    }
  };

  return (
    <div className="interested_fam_box">
      <div className="image_sec">
        <div className="heart_sec delete">
          <RaffleIcon onClick={() => handleDeleteVisitMe(data.user_id)} />
        </div>
        <img
          src={
            data.file_path != null
              ? "https://admin.stage.mysenscare.com/assets/images/users/" +
                data.file_path
              : "img/nany_img.png"
          }
          alt=""
        />
        <div className="heart_sec">
          {localStorage.getItem("user_type") !== "parents" &&
          received === "Parents" ? (
            <FavoriteSelect
              id={data.jobsId?.JID}
              username={data.first_name}
              heart2={data.favOrnotjob}
            />
          ) : (
            <FavoriteProfile
              id={data.id}
              username={data.first_name}
              heart2={data.favOrnot}
              whovisitedme={data.first_name}
            />
          )}
        </div>
      </div>
      <div className="general_sec">
        <h4>
          <Link
            to={
              data.user_type === "parents"
                ? "/profile-parents/" + data.id
                : "/profile-provider/" + data.id
            }
          >
            {data.first_name + " " + data.last_name}
          </Link>
        </h4>
        {data.reviewAvg >= 0 && renderStars(data.reviewAvg)}
        <div className="detail_sec">
          {data.service_type !== "" && JSON.parse(data.service_type) != null && (
            <>
              {renderServiceIcon(
                Object.values(JSON.parse(data.service_type))[0]
              )}
              <p>{t(Object.values(JSON.parse(data.service_type))[0])}</p>
            </>
          )}
        </div>
        <div className="detail_sec">
          <img
            src={window.location.origin + "/images/post_pay.svg"}
            alt="Pay"
          />
          <p>{renderRate(data.nanyperhrrate)}</p>
        </div>
        {data.service_type !== "" && data.service_type != null &&
          Object.values(JSON.parse(data.service_type))[1] && (
            <>
              <div className="detail_sec">
                {renderServiceIcon(
                  Object.values(JSON.parse(data.service_type))[1]
                )}
                <p>{t(Object.values(JSON.parse(data.service_type))[1])}</p>
              </div>
              <div className="detail_sec">
                <img
                  src={window.location.origin + "/images/post_pay.svg"}
                  alt="Pay"
                />
                <p>{renderRate(data.tutorperhrrate)}</p>
              </div>
            </>
          )}
        <div className="detail_sec">
          <img
            src={window.location.origin + "/images/post_rang.svg"}
            alt="Experience"
          />
          <p>
            {data.tutorexp != null
              ? `${t(data.tutorexp)} ${t("years-experience")}`
              : `${t(data.nanyyearexp)} ${t("years-experience")}`}
          </p>
        </div>
        <div className="detail_sec">
          <img
            src={window.location.origin + "/images/post_loc.svg"}
            alt="Location"
          />
          <p>{data.city + ", " + data.country}</p>
        </div>
      </div>
    </div>
  );
};
