import React from "react";
import { useTranslation } from "react-i18next";


export const DocumentMessage = ({ data, handleDeleteSelect, setView, month, setProfileSection }) => {
  const { t } = useTranslation();

  const navigateToMessage = () => {
    setProfileSection('Job_history');
    setView(data.id);
  };

  return (
    <tr className={data.read_status === 0 ? "unread" : ""}>
      <td className="view-message dont-show">
        <input
          type="checkbox"
          className="mail-radio"
          name="chk"
          onChange={(e) => handleDeleteSelect(data.id)}
        />
        <span className="circle"></span>
        <span onClick={navigateToMessage}>
          {data.read_status === 0 && (
            <span className="new-tag">{t("text-new-up")}</span>
          )}
          <span className="username-text" onClick={navigateToMessage}>
            {data.ParentsName}
          </span>
          {t("text-request")}
          <strong>
            {data?.documment_name
              .substr(data?.documment_name.lastIndexOf("\\") + 1)
              .split("[")[1]
              ? JSON.parse(data?.documment_name).map(
                  (e, i) =>
                    t(e) +
                    (i + 1 === JSON.parse(data?.documment_name).length
                      ? ""
                      : " , ")
                )
              : t(data?.documment_name)}
          </strong>
        </span>
      </td>
      <td className="view-message text-right">
        {month[new Date(data.created_at).getMonth()] +
          " " +
          new Date(data.created_at).getDate()}
      </td>
    </tr>
  );
};
