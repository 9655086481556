import React from "react";
import { useTranslation } from "react-i18next";

import { useInterviewStore } from "../../../store/useInterviewStore";

export const ReviewNotificationRow = ({
  data,
  handleDeleteElement,
  navigate,
  slugData,
  month,
}) => {
  const { t } = useTranslation();

  const { setView } = useInterviewStore();

  return (
    <tr className={data.read_status === 0 ? "unread" : ""}>
      <td className="view-message dont-show">
        <input
          type="checkbox"
          className="mail-radio"
          name="chk"
          onChange={() => handleDeleteElement(data.id)}
        />
        <span className="circle"></span>
        <span
          onClick={() => {
            navigate("/search-providers/Reviews");
          }}
        >
          {data.read_status === 0 ? (
            <span className="new-tag"> {t("text-new-up")}</span>
          ) : (
            ""
          )}
          <span
            className="username-text"
            onClick={() => {
              navigate("/search-providers/Reviews");
            }}
          >
            {data.SenderName}
          </span>
          - {data.review_title}
        </span>
      </td>
      <td className="view-message text-right">
        {month[new Date(data.created_at).getMonth()] +
          " " +
          new Date(data.created_at).getDate()}
      </td>
    </tr>
  );
};
