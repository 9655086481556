import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../../../urls";
import { MONTH } from "../../../utils/variables";
import { JobCandidate } from "./JobCandidate";

export function JobApplicationHistoryDetail(props) {
  const { t } = useTranslation();

  const [jobProfileData, setJobProfileData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [isElement, setIsElement] = useState(true);
  const [profile] = useState();

  const handleElementById = (e, x, z) => {
    console.log(e, x);
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      app_jobid: props.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/providerappliedjobprofileview", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setJobProfileData([result.data.providerappliedjobprofileview]);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isCheck) {
      handleProfileData();
      setIsCheck(false);
    }
  }, [isCheck]);

  return (
    <>
      {jobProfileData && jobProfileData[0] ? (
        <>
          <h2 className="border"></h2>
          <div className="detail_invit removebg">
            <p>
              <strong> {t("job-post")}</strong>
              <span className="date">
                {t("applied-on")}
                {" "}
                {MONTH[
                  new Date(
                    jobProfileData &&
                      jobProfileData[0] &&
                      jobProfileData[0].applyJobDate
                  ).getMonth()
                ] +
                  " " +
                  new Date(
                    jobProfileData &&
                      jobProfileData[0] &&
                      jobProfileData[0].applyJobDate
                  ).getDate()}
                ,
                {new Date(
                  jobProfileData &&
                    jobProfileData[0] &&
                    jobProfileData[0].applyJobDate
                ).getFullYear()}
                ,
                {new Date(
                  jobProfileData &&
                    jobProfileData[0] &&
                    jobProfileData[0].applyJobDate
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </p>
          </div>
          <div class="right_side_section jobapli">
            <div class="looking_for_candidate">
              {jobProfileData.map((data, index) => {
                if (index <= 5) {
                  return (
                    <JobCandidate
                      key={index}
                      data={data}
                      index={index}
                      handleElementById={handleElementById}
                      profile={profile}
                    />
                  );
                }
              })}

              <div className="mobile ">
                {jobProfileData.map((data, index) => {
                  if (index <= 5) {
                    return (
                      <JobCandidate
                        key={`mobile-${index}`}
                        data={data}
                        index={index}
                        handleElementById={handleElementById}
                        profile={profile}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
