import React, { useState, useEffect } from "react";

import { fetchNotificationCount } from "../../../api";

export function NotificationCount() {
  const [notificationData, setNotificationData] = useState({});

  useEffect(() => {
    updateNotificationCount();
  }, []);

  const updateNotificationCount = async () => {
    try {
      const data = await fetchNotificationCount(
        localStorage.getItem("user_type")
      );
      setNotificationData(data);
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  return <>{notificationData.totalcount}</>;
}
