import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

export const ReviewGiven = ({
    isReviewModal,
    setIsReviewModal,
}) => {
    const { t } = useTranslation();

    return (
        <Modal show={isReviewModal} onHide={(e) => setIsReviewModal(false)}>
            <Modal.Body>
                <div className="promocode_content signout invite request reviewpop">
                    <div className="promocode_content">
                        {t("You have already submitted a review.")}
                    </div>
                    <div className="button text-center ">
                        <div className="pull-right">
                            <button className="btn" onClick={(e) => setIsReviewModal(false)}>
                                {t("text-ok")}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
