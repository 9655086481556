import {ArrowUpIcon} from "../../assets/image/ArrowUpIcon";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export const AdvancedSearchProvider = ({
    rate,
    setRate,
    search,
    setSearch,
    profile,
    setChildren,
    handleChildrenAge,
    handleElementById,
    handleProviderList,
    childrenAge,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categoryData, setCategoryData] = useState({
        data1: "",
        data2: "",
        data3: "",
        data4: "",
    });

    const shouldDisplayFilter =
        profile.about === "" ||
        profile.about === null ||
        !localStorage.getItem("token") ||
        !localStorage.getItem("id") ||
        localStorage.getItem("refine");

    return (
        <div
            className="filters_search detail work-experience"
            id="filtern"
            style={
                shouldDisplayFilter ? {display: "block"} : {display: "none"}
            }
        >
            <div className="form_group full border qualification">
                <label>{t("text-frequency")}</label>
                <div className="checkbox create">
                    <ul>
                        <li>
                            <input
                                type="checkbox"
                                name=""
                                onClick={(e) => {
                                    if (e.target.checked) {
                                        setSearch({
                                            ...search,
                                            tutorintrestedin: "Full time",
                                        });
                                    } else {
                                        setSearch({
                                            ...search,
                                            tutorintrestedin: "",
                                        });
                                    }
                                }}
                            />
                            <span> {t("full-time")}</span>
                        </li>
                        <li>
                            <input
                                type="checkbox"
                                name=""
                                onClick={(e) => {
                                    if (e.target.checked) {
                                        setSearch({
                                            ...search,
                                            tutorintrestedin1: "Part time",
                                        });
                                    } else {
                                        setSearch({
                                            ...search,
                                            tutorintrestedin1: "",
                                        });
                                    }
                                }}
                            />
                            <span> {t("part-time")}</span>
                        </li>
                        <li>
                            <input
                                type="checkbox"
                                name=""
                                onClick={(e) => {
                                    if (e.target.checked) {
                                        setSearch({
                                            ...search,
                                            tutorintrestedin2: "Occasionally",
                                        });
                                    } else {
                                        setSearch({
                                            ...search,
                                            tutorintrestedin2: "",
                                        });
                                    }
                                }}
                            />
                            <span> {t("occasionally")}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="form_group full border">
                <label>
                    {t("Hourly rate")}
                    {profile && profile.country == "Serbia" ? "(RSD)" : "(USD)"}
                </label>
                {profile && profile.country == "Serbia" ? (
                    <div className="wrapper">
                        <div className="container_slide">
                            <div className="slider-track"></div>
                            <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={rate.min / 10}
                                onChange={(e) => {
                                    if (
                                        (rate.max > 0 ? rate.max : 60) >
                                        (e.target.value == 5 ? 60 : e.target.value * 10)
                                    ) {
                                        setRate({
                                            ...rate,
                                            min:
                                                e.target.value == 5 ? 60 : e.target.value * 10,
                                        });
                                    }
                                }}
                            />
                            <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                                onChange={(e) => {
                                    if (
                                        rate.min <
                                        (e.target.value == 5 ? 60 : e.target.value * 10)
                                    ) {
                                        setRate({
                                            ...rate,
                                            max:
                                                e.target.value == 5 ? 60 : e.target.value * 10,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <div className="slider two">
                            <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <div className="wrapper">
                        <div className="container_slide">
                            <div className="slider-track"></div>
                            <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={rate.min / 10}
                                onChange={(e) => {
                                    if (
                                        (rate.max > 0 ? rate.max : 60) >
                                        (e.target.value == 5 ? 60 : e.target.value * 10)
                                    ) {
                                        setRate({
                                            ...rate,
                                            min:
                                                e.target.value == 5 ? 60 : e.target.value * 10,
                                        });
                                    }
                                }}
                            />
                            <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                                onChange={(e) => {
                                    if (
                                        rate.min <
                                        (e.target.value == 5 ? 60 : e.target.value * 10)
                                    ) {
                                        setRate({
                                            ...rate,
                                            max:
                                                e.target.value == 5 ? 60 : e.target.value * 10,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <div className="slider two">
                            <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                            </ul>
                        </div>
                    </div>
                )}
                <span className="price">
                      {profile && profile.country == "Serbia"
                          ? rate.min * 100 + " - " + rate.max * 100
                          : rate.min + " - " + rate.max}
                    </span>
                {/* <div className='errorfield'>{error.message}</div> */}
            </div>

            <div className="form_group full border">
                <label>Number of children</label>
                <div className="children_number">
                    <ul>
                        <li
                            onClick={(e) => {
                                if (categoryData.data1 == "") {
                                    setCategoryData({...categoryData, data1: 1});
                                    setChildren(1);
                                } else {
                                    setCategoryData({...categoryData, data1: ""});
                                    setChildren("");
                                }
                            }}
                            className={categoryData.data1 == 1 ? "active" : ""}
                        >
                            1
                        </li>
                        <li
                            onClick={(e) => {
                                if (categoryData.data2 == "") {
                                    setCategoryData({...categoryData, data2: 2});
                                    setChildren(2);
                                } else {
                                    setCategoryData({...categoryData, data2: ""});
                                    setChildren("");
                                }
                            }}
                            className={categoryData.data2 == 2 ? "active" : ""}
                        >
                            2
                        </li>
                        <li
                            onClick={(e) => {
                                if (categoryData.data3 == "") {
                                    setCategoryData({
                                        ...categoryData,
                                        data3: "twins",
                                    });
                                    setChildren("twins");
                                } else {
                                    setCategoryData({...categoryData, data3: ""});
                                    setChildren("");
                                }
                            }}
                            className={categoryData.data3 == "twins" ? "active" : ""}
                        >
                            Twins
                        </li>
                        <li
                            onClick={(e) => {
                                if (categoryData.data4 == "") {
                                    setCategoryData({...categoryData, data4: "3"});
                                    setChildren(3);
                                } else {
                                    setCategoryData({...categoryData, data4: ""});
                                    setChildren("");
                                }
                            }}
                            className={categoryData.data4 == 3 ? "active" : ""}
                        >
                            3+
                        </li>
                    </ul>
                </div>
                {/* <div className='errorfield'>{error.message}</div> */}
            </div>
            <div className="job_performance">
                <div className="form_group   full border">
                    <label>{t("child-age")}</label>
                    <div className="customselect inp">
                        <input
                            className="keyword"
                            type="text"
                            placeholder={t("choose-list")}
                            value={childrenAge.map((e) => {
                                return e.name;
                            })}
                        />
                        <div
                            className="overflow"
                            id="over2"
                            onClick={(e) => handleElementById("cate5", "over2")}
                        ></div>
                        <div className="option" id="cate5">
                            <p>
                                <input
                                    type="checkbox"
                                    onClick={(a) => handleChildrenAge("0 - 1 years")}
                                />
                                <h3>{"0 - 1 years"} </h3>
                                <span></span>
                            </p>
                            <p>
                                <input
                                    type="checkbox"
                                    onClick={(a) => handleChildrenAge("4 - 7 years")}
                                />
                                <h3>{"4 - 7 years"} </h3>
                                <span></span>
                            </p>
                            <p>
                                <input
                                    type="checkbox"
                                    onClick={(a) => handleChildrenAge("8 - 10 years")}
                                />
                                <h3>{"8 - 10 years"} </h3>
                                <span></span>
                            </p>
                            <p>
                                <input
                                    type="checkbox"
                                    onClick={(a) => handleChildrenAge("11 - 15 years")}
                                />
                                <h3>{"11 - 15 years"} </h3>
                                <span></span>
                            </p>
                            <p>
                                <input
                                    type="checkbox"
                                    onClick={(a) => handleChildrenAge("16+ years")}
                                />
                                <h3>{"16+ years"} </h3>
                                <span></span>
                            </p>
                        </div>
                        <span onClick={(e) => handleElementById("cate5", "over2")}>
                          <ArrowUpIcon/>
                        </span>
                    </div>
                </div>
            </div>

            <div className="form_group full border qualification">
                <label>{t("start-date")}</label>
                <div className="checkbox create">
                    <ul>
                        <li>
                            <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                    setSearch({
                                        ...search,
                                        withinweek: e.target.checked,
                                    });
                                }}
                            />
                            <span> {t("Within a week")}</span>
                        </li>
                        <li>
                            <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                    setSearch({
                                        ...search,
                                        withinmonth: e.target.checked,
                                    });
                                }}
                            />
                            <span> {t("Within a month")}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="form_group full qualification">
                <label>{t("Other options")}</label>
                <div className="checkbox create">
                    <ul>
                        <li>
                            <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                    setSearch({
                                        ...search,
                                        carorlicence: e.target.checked,
                                    });
                                }}
                            />
                            <span> {t("Without transportation")}</span>
                        </li>
                        <li>
                            <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                    setSearch({
                                        ...search,
                                        jobposted: e.target.checked,
                                    });
                                }}
                            />
                            <span> {t("Jobs posted within a week")}</span>
                        </li>
                        <li>
                            <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                    if (e.target.checked) {
                                        setSearch({
                                            ...search,
                                            workingabroad: "Yes",
                                        });
                                    } else {
                                        setSearch({
                                            ...search,
                                            workingabroad: "",
                                        });
                                    }
                                }}
                            />
                            <span> {t("Work abroad")}</span>
                        </li>
                        <li>
                            <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                    if (e.target.checked) {
                                        setSearch({
                                            ...search,
                                            tutorintrestedinschool: "Yes",
                                        });
                                    } else {
                                        setSearch({
                                            ...search,
                                            tutorintrestedinschool: "",
                                        });
                                    }
                                }}
                            />
                            <span> {t("School jobs")}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <button
                onClick={(e) => {
                    localStorage.setItem(
                        "filter",
                        JSON.stringify({
                            category: categoryData,
                            distance: search.distance,
                            zip: search.zip,
                            city: search.city,
                            cat: categoryData,
                        })
                    );
                    handleProviderList();
                    navigate("/search-providers");
                }}
            >
                {t("Refine result")}
            </button>
        </div>
    );
}