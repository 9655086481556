import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getInterviewNotification } from "../../../api/notificationApi";
import { deleteInvitations } from "../../../api/invitationApi";
import { InviteDetail } from "../InviteDetail";
import { REFINE_OPTIONS } from "../../../utils/variables";
import { InviteRow } from "./InviteRow";
import { useInterviewStore } from "../../../store/useInterviewStore";

import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";

export function InterviewNotification({setProfileSection}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCheck, setIsCheck] = useState(true);
  const [invitesData, setInvitesData] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const {
    recived,
    setRecived,
    view,
    setView,
    refine,
    setRefine,
    resetInterviewState,
  } = useInterviewStore();

  useEffect(() => {
    return () => {
      resetInterviewState();
    };
  }, []);

  const slugdata = useParams();
  let data = slugdata.name;

  const fetchInvitesData = async () => {
    setInvitesData([]);
    try {
      const result = await getInterviewNotification(refine);
      setInvitesData(result.data);
    } catch (error) {
      console.error("Error fetching invites data:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      fetchInvitesData();
      setIsCheck(false);
    }

    setView(data ? data : "");
  }, [isCheck, invitesData, deleteList, refine, data, slugdata]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }
  }, []);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    invitesData.map((data) => {
      console.log(data, deleteList);
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };
  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteSelect = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
    setInvitesData(invitesData);
  };

  const handleDeleteInvitaion = async () => {
    try {
      await deleteInvitations(deleteList);
      fetchInvitesData();
      setDeleteList([]);
    } catch (error) {
      console.error("Error deleting invitations:", error);
    }
  };

  const handleRefineDay = (e) => {
    setRefine({
      threedays: e == "3" ? true : "",
      withinweek: e == "7" ? true : "",
      twofourday: e == "24" ? true : "",
      withinmonth: e == "30" ? true : "",
    });
    setIsCheck(true);
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("interview-invites")}</h2>
            <div class="mail-header-bar">
              <p>
                {invitesData.length} {t("text-invites")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(recived)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive && (
                    <ul>
                      {REFINE_OPTIONS.map((option) => (
                        <li
                          key={option.label}
                          onClick={() => {
                            setRecived(t(option.label));
                            handleRefineDay(option.value);
                            setIsActive(!isActive);
                          }}
                        >
                          {t(option.label)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div
                  class="delete-btn"
                  onClick={(e) => handleDeleteInvitaion()}
                >
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div class="refresh-btn" onClick={(e) => fetchInvitesData()}>
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-inbox table-hover">
            <tbody>
              {invitesData.map((data) => {
                return (
                  <InviteRow
                    key={data}
                    data={data}
                    slugdata={slugdata}
                    handleDeleteSelect={handleDeleteSelect}
                    setView={setView}
                    setProfileSection={setProfileSection}
                  />
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div class="main-header">
          <button
            onClick={(e) => {
              navigate("/search-providers/" + slugdata.id);
              setView("");
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <InviteDetail id={view} />
        </div>
      )}
    </div>
  );
}
