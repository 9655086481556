import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { hiringViewNotifications } from "../../../api/notificationApi";

export function HiringDetail({ id }) {
  const { t } = useTranslation();

  const [notificationData, setNotificationData] = useState([]);
  const [message, setMessage] = useState("");

  const fetchNotificationData = async () => {
    try {
      const result = await hiringViewNotifications(id);
      setNotificationData([result.data]);
      setMessage(result.data.currentUserName);
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };

  useEffect(() => {
      fetchNotificationData();
  }, []);

  return (
    <>
      {notificationData && notificationData[0] ? (
        <>
          <h2 className="border"></h2>
          <div className="detail_invit recomend">
            <h2 className="first">
              {t("text-dear")} {" " + message},
            </h2>
            <h2>
              <a>{notificationData[0].jobOwnerName}</a>
              {" "}
              {t("hired-you")}
            </h2>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
