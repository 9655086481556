import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SocialFacebook } from "../../common/SocialFacebook";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { SearchIcon } from "../../../assets/image/SearchIcon";
import {FavoriteProfile} from "../../common/FavoriteProfile";

export const JobCandidate = ({ data, index, handleElementById, profile }) => {
  const { t } = useTranslation();

  const renderServiceIcon = (serviceType) => {
    switch (serviceType) {
      case "Nanny":
        return (
          <img src={`${window.location.origin}/images/nany_post.svg`} alt="" />
        );
      case "tab2":
        return (
          <img
            src={`${window.location.origin}/images/teacher_post.svg`}
            alt=""
          />
        );
      case "tab3":
        return (
          <img
            src={`${window.location.origin}/images/education_post.svg`}
            alt=""
          />
        );
      case "tab4":
        return (
          <img src={`${window.location.origin}/images/tutor_post.svg`} alt="" />
        );
      default:
        return null;
    }
  };

  return (
    <div className="looking_for_candidate_boxs">
      <div className="looking_for_candidate_box hovertable">
        <div className="first_sec">
          <div className="image_sec">
            <img
              src={
                data.file_path != null
                  ? `https://admin.stage.mysenscare.com/assets/images/users/${data.file_path}`
                  : `${window.location.origin}/img/nany_img.png`
              }
              alt=""
            />
            <div className="heart_sec">
              <FavoriteProfile id={data.id} username={data.username} heart2={data.favornotprofile}/>
            </div>
          </div>
          <div className="nany_social">
            {data.phoneVerifiedStatus == 1 ? (
              <img
                src={`${window.location.origin}/images/nany_phone.svg`}
                alt=""
              />
            ) : (
              <PhoneIcon />
            )}
            <img src={`${window.location.origin}/images/nany_msg.svg`} alt="" />
            {data.facebookverify != null || data.linkdinverify != null ? (
              <img
                src={`${window.location.origin}/images/nany_cont.svg`}
                alt=""
              />
            ) : (
              <ServiceIcon />
            )}
          </div>
        </div>
        <div className="second_sec">
          <div className="heading">
            <h3>
              {data.provider_readstatus == 0 ? (
                <span className="new-tag"> {t("text-new-upper")}</span>
              ) : data.status == 0 ? (
                <span className="new-tag2"> {t("text-active")}</span>
              ) : data.status >= 0 ? (
                <span className="new-tag3"> {t("text-closed")}</span>
              ) : (
                ""
              )}
              {data.title}
            </h3>
            {data.plateformonsocialmedia == "Yes" ? (
              <SocialFacebook
                link={`${window.location.origin}/profile-parents/${data.user_id}`}
              />
            ) : (
              ""
            )}
          </div>
          <div className="post_general">
            <h6>
              <Link to={`/profile-parents/${data.user_id}`}>
                {data.first_name != null
                  ? `${data.first_name} ${data.last_name}`
                  : ""}
              </Link>
              (
              {data.dob != undefined
                ? new Date().getFullYear() - parseInt(data.dob.split("-")[0])
                : ""}
              )
            </h6>
            <p>
              {data.reviewAvg >= 0 ? (
                <>
                  {[...Array(data.reviewAvg)].map((star, idx) => (
                    <StarIcon key={idx} />
                  ))}
                  {[...Array(5 - data.reviewAvg)].map((star, idx) => (
                    <WhiteStarIcon key={idx} />
                  ))}
                </>
              ) : (
                ""
              )}
              <span> ({data.reviewcount})</span>
            </p>
          </div>
          <div className="post_detail">
            <div className="post">
              {data.service_type != null &&
                renderServiceIcon(
                  Object.values(JSON.parse(data.service_type))[0]
                )}
              <h5>
                {data.service_type != null
                  ? t(Object.values(JSON.parse(data.service_type))[0])
                  : ""}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_pay">
              <img src={`${window.location.origin}/img/post_pay.png`} alt="" />
              {profile && profile.country == "Serbia" ? (
                <h5>
                  {data.nanyperhrrate != null
                    ? `${data.nanyperhrrate.split("-")[0] * 100} - ${
                        data.nanyperhrrate.split("-")[1] * 100
                      }`
                    : data.tutorperhrrate
                    ? `${data.tutorperhrrate.split("-")[0] * 100} - ${
                        data.tutorperhrrate.split("-")[1] * 100
                      }`
                    : ""}
                  {t("time-hour")}
                </h5>
              ) : (
                <h5>
                  $
                  {data.nanyperhrrate != null
                    ? data.nanyperhrrate
                    : data.tutorperhrrate
                    ? data.tutorperhrrate
                    : ""}
                  {t("time-hour")}
                </h5>
              )}
            </div>
            <div className="vi"></div>
            <div className="post_fet">
              <img
                src={`${window.location.origin}/images/post_fet.svg`}
                alt=""
              />
              <h5>
                {data.nanyintrestedin != null
                  ? data.nanyintrestedin
                  : data.tutorintrestedin}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_cal">
              <img
                src={`${window.location.origin}/images/post_cal.svg`}
                alt=""
              />
              <h5>
                {data.nanystartdate != null
                  ? data.nanystartdate
                  : data.tutorstartdate}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_loc">
              <img
                src={`${window.location.origin}/images/post_loc.svg`}
                alt=""
              />
              <h5>
                {data.country != null ? data.country : ""} ,
                {data.city != null ? data.city : ""}
              </h5>
            </div>
          </div>
          <p id={`half${index}`}>
            {data.description}
            {data.description != null && data.description.length > 100 ? (
              <span
                onClick={() =>
                  handleElementById(
                    `half${index}`,
                    `full${index}`,
                    `plink${index}`
                  )
                }
              >
                {t("text-more")}
              </span>
            ) : (
              ""
            )}
          </p>
          <p id={`full${index}`} className="hide">
            {data.description}
            <span
              onClick={() =>
                handleElementById(
                  `full${index}`,
                  `half${index}`,
                  `plink${index}`
                )
              }
            >
              {t("text-less")}
            </span>
          </p>
        </div>
        <div className="find_search"></div>
        <div className="view_profile_btn visiterbtn">
          <Link to="/search-parents">
            <SearchIcon />
            {t("apply-similar-jobs")}
          </Link>
          {data.status > 0 ? (
            ""
          ) : (
            <Link to={`/profile-parents/${data.user_id}`}>{t("view-job")}</Link>
          )}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};
