import { DEFAULT_API } from "../utils/variables";

export const deleteDocument = async (fileName) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ file_name: fileName });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/providermydocumentsdelete`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.text();
  } catch (error) {
    console.error("Error deleting document:", error);
    throw error;
  }
};

export const getListDocument = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/userlistforshardoc`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching document list:", error);
    throw error;
  }
};

export const sendDocument = async (shareSelect, shareDocument) => {
  if (!shareSelect) {
    throw new Error("Please select one!");
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

  const formdata = new FormData();
  formdata.append("parents_id", shareSelect);

  if (shareDocument.recom_doc) {
    formdata.append("recom_doc", shareDocument.recom_doc);
  }
  if (shareDocument.cv_doc) {
    formdata.append("cv_doc", shareDocument.cv_doc);
  }
  if (shareDocument.back_doc) {
    formdata.append("back_doc", shareDocument.back_doc);
  }
  if (shareDocument.cert_doc) {
    formdata.append("cert_doc", shareDocument.cert_doc);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/directdocsend`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error sending document:", error);
    throw error;
  }
};

export const sendDocumentType = async (
  document,
  parentsData,
  propsId,
  selectType
) => {
  if (
    !document.cv &&
    !document.qualificationscertificatesdoc &&
    !document.backgrounddoc &&
    !document.recommendationsfile &&
    !document.name
  ) {
    throw new Error("Document is missing required fields");
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

  const formdata = new FormData();
  formdata.append(
    "receiver_userid",
    parentsData && parentsData[0] && parentsData[0].user_id
  );
  formdata.append("inv_id", propsId);
  formdata.append("type", selectType);

  if (document.cv) {
    formdata.append("cv_doc", document.cv);
  }
  if (document.qualificationscertificatesdoc) {
    formdata.append("cert_doc", document.qualificationscertificatesdoc);
  }
  if (document.backgrounddoc) {
    formdata.append("back_doc", document.backgrounddoc);
  }
  if (document.recommendationsfile) {
    formdata.append("recom_doc", document.recommendationsfile);
  }
  if (document.name) {
    formdata.append("recom_doc", document);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/senddocuments`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }

    return response.text();
  } catch (error) {
    console.error("Error sending document:", error);
    throw error;
  }
};

export const getProviderDocuments = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/providermydocumentslist`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching provider documents:", error);
    throw error;
  }
};

export const historyRequestDocument = async (propsId) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    inv_id: propsId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/documentrequesthistory_viewdetails`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching document request history:", error);
    throw error;
  }
};

export const getNotificationsDocument = async (refine) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const queryParams =
    refine.threedays !== ""
      ? "threedays=" + refine.threedays
      : refine.withinweek !== ""
      ? "withinweek=" + refine.withinweek
      : refine.twofourday !== ""
      ? "twofourdays=" + refine.twofourday
      : refine.withinmonth !== ""
      ? "withinmonth=" + refine.withinmonth
      : "";

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/documentrequest_notifications?${queryParams}`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching document notifications:", error);
    throw error;
  }
};

export const deleteMultiDocuments = async (deleteList) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    ids: deleteList,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/multideletedocumentrequest`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error deleting documents:", error);
    throw error;
  }
};

export const fetchDocumentData = async (refine) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const queryParams = refine.threeDays
    ? `threedays=${refine.threeDays}`
    : refine.withinWeek
    ? `withinweek=${refine.withinWeek}`
    : refine.twoFourDay
    ? `twofourday=${refine.twoFourDay}`
    : refine.withinMonth
    ? `withinmonth=${refine.withinMonth}`
    : "";

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let url = `${DEFAULT_API}/api/v1/shareddocumentslist`;
  if (queryParams !== '') {
    url = `${DEFAULT_API}/api/v1/shareddocumentslist?${queryParams}`;
  }

  try {
    const response = await fetch(
      url,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching document data:", error);
    throw error;
  }
};

export const fethDeleteDocument = async (deleteIds) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ ids: deleteIds });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/deleteshareddocuments`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error deleting document:", error);
    throw error;
  }
};

export const fetchViewDocument = async (id, refine) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    inv_id: id,
  });

  const queryParams = refine.threedays
    ? `threedays=${refine.threedays}`
    : refine.withinweek
    ? `withinweek=${refine.withinweek}`
    : refine.twofourday
    ? `twofourdays=${refine.twofourday}`
    : refine.withinmonth
    ? `withinmonth=${refine.withinmonth}`
    : "";

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/viewdocuments?${queryParams}`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching document data:", error);
    throw error;
  }
};

export const fetcDeleteDocumentView = async (documentIds) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ ids: documentIds });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/shareddocdltparent`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error deleting document:", error);
    throw error;
  }
};
