import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

export const HireDataRow = ({ data, handleChangeElement, slugData, setView }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  return (
    <tr className={data.parent_readstatus === 0 ? "unread" : ""}>
      <td className="view-message  dont-show">
        <input
          type="checkbox"
          className="mail-radio"
          name="chk"
          onChange={() => handleChangeElement(data.id)}
        />
        <span className="circle"></span>
        <span
          onClick={() => {
            navigate(`/search-providers/${slugData.id}/${data.id}`);
            setView(data.id);
          }}
        >
          {data.parent_readstatus === 0 && (
            <span className="new-tag">{t("text-new-up")}</span>
          )}
          {t("text-congratulations")}
          {window.innerWidth < 720 && "!"}
          <br />
          <span
            className="username-text"
            onClick={() => {
              navigate(`/search-providers/${slugData.id}/${data.id}`);
              setView(data.id);
            }}
          >
            {data.ParentsName}
          </span>
            {t("hired-you")}
        </span>
      </td>
      <td className="view-message text-right">
        {`${month[new Date(data.created_at).getMonth()]} ${new Date(
          data.created_at
        ).getDate()}`}
      </td>
    </tr>
  );
};
