import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import { LocationContent } from "../../components/common/LocationContent";
import { DATA_COUNTRY } from "../../utils/data";
import {
  CAPITAL_LETTERS,
  EMAIL_REGEX,
  NUMBERS,
  SMALL_LETTERS,
  SPECIAL_CHARACTERS,
  VALID_PASSWORD,
} from "../../utils/variables";
import { fetchRegisterUser } from "../../api/registerApi";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

export function ParentsSignupPage() {
  const { t, i18n } = useTranslation();

  const [refresh, setRefresh] = useState("");
  const [category, setCategory] = useState("");
  const [describe, setDescribe] = useState({});
  const [step, setStep] = useState("stap1");
  const [showModal, setShowModal] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [isSelect, setIsSelect] = useState("");
  const [contactCode, setContactCode] = useState({
    code: "",
    flag: "",
  });
  const [latLong, setLatLong] = useState({});

  const [test, setTest] = useState([
    { name: "username" },
    { name: "email" },
    { name: "password" },
    { name: "c_password" },
    { name: "first_name" },
    { name: "last_name" },
    { name: "dob" },
    { name: "phone" },
    { name: "address" },
    { name: "city" },
    { name: "zip" },
    { name: "country" },
    { name: "hearAboutUs" },
  ]);

  const [formLogin, setFormLogin] = useState({
    username: "",
    email: "",
    password: "",
    c_password: "",
    service_type: "",
    first_name: "",
    last_name: "",
    dob: "",
    phone: "",
    address: "",
    city: "",
    zip: "",
    country: "",
    hearAboutUs: "",
    user_type: "",
  });

  const [errorFiled, setErrorField] = useState({
    username: "",
    email: "",
    password: "",
    c_password: "",
    service_type: "",
    first_name: "",
    last_name: "",
    dob: "",
    phone: "",
    address: "",
    city: "",
    zip: "",
    country: "",
    hearAboutUs: "",
  });

  const [isCode, setIsCode] = useState(true);

  const today = new Date();

  const handleDateChange = (date) => {
    if (date) {
      setErrorField({ ...errorFiled, dob: "" });

      // Use moment's year method to get the year from the moment date object
      const age = today.getFullYear() - date.year();

      if (age > 16) {
        setFormLogin({
          ...formLogin,
          dob: date.format('YYYY-MM-DD'), // Format as YYYY-MM-DD
        });
      } else {
        handleShowModal();
      }
    }
  };

  const handleDescribeSelect = (a, b) => {
    if (Object.keys(describe).length < 2) {
      setDescribe({ ...describe, [a]: b });
    } else {
      delete describe[a];
      setDescribe({ ...describe });
    }
  };

  const hadnleRedirect = () => {
    window.location.href = "/signup";
  };

  const validationLoginField = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "username":
        errorFiled.username =
          value.length < 2 ? t("Minimum 2 characters required") : "";
        break;
      case "email":
        errorFiled.email = EMAIL_REGEX.test(value) ? "" : t("email-valid");

        break;
      case "password":
        errorFiled.password = VALID_PASSWORD.test(value) ? "" : "Demo@123";

        break;
      case "c_password":
        errorFiled.c_password =
          value != formLogin.password ? t("Password not match.") : "";
        break;
      case "service_type":
        errorFiled.service_type =
          value.length < 2 ? t("Minimum 2 characters required") : "";
        break;
      case "first_name":
        errorFiled.first_name = SMALL_LETTERS.test(value)
          ? (errorFiled.first_name =
              value.length < 2 ? t("Minimum 2 characters required") : "")
          : t("Only letters are allowed");

        break;
      case "last_name":
        errorFiled.last_name = SMALL_LETTERS.test(value)
          ? (errorFiled.last_name =
              value.length < 2 ? t("Minimum 2 characters required") : "")
          : "Only letters are allowed";

        break;
      case "dob":
        errorFiled.dob = value.length < 2 ? t("required") : "";
        let x =
          new Date().getFullYear() - parseInt(value) > 16
            ? ""
            : handleShowModal();

        break;
      case "phone":
        errorFiled.phone = value.length < 8 ? t("required") : "";
        break;
      case "address":
        errorFiled.address = value.length < 2 ? t("required") : "";
        break;
      case "city":
        errorFiled.city = value.length < 2 ? t("required") : "";
        break;
      case "zip":
        errorFiled.zip = value.length < 2 ? t("required") : "";
        break;
      case "country":
        errorFiled.country = value.length < 2 ? t("required") : "";
        break;
      case "hearAboutUs":
        errorFiled.hearAboutUs = value.length < 2 ? t("required") : "";
        break;
      default:
        break;
    }
    setFormLogin({ ...formLogin, [name]: value });
    setErrorField(errorFiled);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setFormLogin({ ...formLogin, dob: "" });
    setShowModal(true);
  };

  const handleCodeSelect = () => {
    if (isCode) {
      setIsCode(false);
    } else {
      setIsCode(true);
    }
  };

  useEffect(() => {
    console.log(isCode);
  }, [errorFiled, refresh, contactCode, isCode]);

  console.log(errorFiled, "ERROR FIELD");
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errorFiled };
    if (!formLogin.username) {
      newErrors.username = t("required");
      isValid = false;
    }
    if (!formLogin.email) {
      newErrors.email = t("required");
      isValid = false;
    }
    if (!formLogin.password) {
      newErrors.password = t("required");
      isValid = false;
    }
    if (!formLogin.c_password) {
      newErrors.c_password = t("required");
      isValid = false;
    }
    if (!formLogin.first_name) {
      newErrors.first_name = t("required");
      isValid = false;
    }
    if (!formLogin.last_name) {
      newErrors.last_name = t("required");
      isValid = false;
    }
    if (!formLogin.dob) {
      newErrors.dob = t("required");
      isValid = false;
    }
    if (!formLogin.phone || !contactCode.code) {
      newErrors.phone = t("required");
      isValid = false;
    }
    if (!formLogin.address) {
      newErrors.address = t("required");
      isValid = false;
    }
    if (!formLogin.city) {
      newErrors.city = t("required");
      isValid = false;
    }
    if (!formLogin.zip) {
      newErrors.zip = t("required");
      isValid = false;
    }
    if (!formLogin.country) {
      newErrors.country = t("required");
      isValid = false;
    }
    if (!formLogin.hearAboutUs) {
      newErrors.hearAboutUs = t("required");
      isValid = false;
    }

    setErrorField(newErrors);
    return isValid;
  };

  const handleSignup = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const result = await fetchRegisterUser(formLogin, contactCode, category,describe, i18n.language, latLong);

      if (result.success) {
        setTimeout(() => {
          window.location.href =
            category === "parents" || category === "school"
              ? "/parent_thankyou"
              : "/providers_thankyou";
        }, 2000);
      }
    } catch (error) {
      console.error("Signup failed:", error);

      if (error.toString().includes("The email has already been taken.")) {
        setErrorField((prevErrors) => ({
          ...prevErrors,
          email: t("The email has already been taken."),
        }));
      }

      if (error.toString().includes("The username has already been taken.")) {
        setErrorField((prevErrors) => ({
          ...prevErrors,
          username: t("The username has already been taken."),
        }));
      }
    }
  };

  const validationAfterLoginField = (name) => {
    switch (name) {
      case "username":
        errorFiled.username = formLogin.username == "" ? t("required") : "";
        break;
      case "email":
        errorFiled.email = formLogin.email != "" ? "" : t("required");

        break;
      case "password":
        errorFiled.password = formLogin.password != "" ? "" : t("required");
        break;
      case "c_password":
        errorFiled.c_password = formLogin.c_password == "" ? t("required") : "";
        break;
      case "first_name":
        errorFiled.first_name = formLogin.first_name == "" ? t("required") : "";
        break;
      case "last_name":
        errorFiled.last_name = formLogin.last_name == "" ? t("required") : "";
        break;
      case "dob":
        errorFiled.dob = formLogin.dob == "" ? t("required") : "";
        break;
      case "phone":
        errorFiled.phone = formLogin.phone == "" ? t("required") : "";
        break;
      case "phone":
        errorFiled.phone = contactCode.code == "" ? t("required") : "";
        break;
      case "address":
        errorFiled.address = formLogin.address == "" ? t("required") : "";
        break;
      case "city":
        errorFiled.city = formLogin.city == "" ? t("required") : "";
        break;
      case "zip":
        errorFiled.zip = formLogin.zip == "" ? t("required") : "";
        break;
      case "country":
        errorFiled.country = formLogin.country == "" ? t("required") : "";
        break;
      case "hearAboutUs":
        errorFiled.hearAboutUs =
          formLogin.hearAboutUs == "" ? t("required") : "";
        break;

      default:
        break;
    }
    setErrorField(errorFiled);
    setTimeout(() => {
      setRefresh("njjijiinibiibi");
    }, 1000);
  };

  const handleLatLong = (latlong2, e, addresss) => {
    console.log(addresss, "A");

    // Якщо e є промісом, то працюємо з ним як з промісом
    if (e instanceof Promise) {
      e.then(function (result) {
        setLatLong(result);
      });
    } else {
      setLatLong(e);
    }

    // Перевіряємо, чи визначений latlong2.address_components і чи є це масивом
    if (Array.isArray(latlong2.address_components)) {
      latlong2.address_components.map((component) => {
        // Перевіряємо, чи визначений component.types і чи є це масивом
        if (Array.isArray(component.types)) {
          component.types.map((type) => {
            if (type === "country") {
              setErrorField({ ...errorFiled, country: "", address: "" });
              setFormLogin({
                ...formLogin,
                country: component.long_name,
                address: addresss,
              });
            }
          });
        }
      });
    } else {
      console.error(
        "latlong2.address_components is not an array",
        latlong2.address_components
      );
    }
  };

  console.log(errorFiled, "ERROR FIELD");

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <div className="container-fluid">
        <div className="container">
          <div className="signup_section">
            <div className="header_sign">
              <ul>
                <li>
                  <Link to="/">
                    <img src="./images/left_arrow.svg" />
                    <span>{t("Home")}</span>
                  </Link>
                </li>
                <li className="login">
                  <span>{t("Already a member?")}</span>
                  <Link to="/login">{t("log-in")} </Link>
                </li>
              </ul>
            </div>

            <div
              className={
                "more_about provider " + (step == "stap1" ? "" : "none")
              }
            >
              <img src="/images/sign_logo.svg" />
              <h2>{t("For a start, tell us who you are?")}</h2>
              <p>
                <span>{t("I am")}</span> ({t("make your selection")})
              </p>
              <ul>
                <li
                  className={
                    category == "parents"
                      ? "active"
                      : "" + isSelect == ""
                      ? ""
                      : "bordererror"
                  }
                  onClick={(e) => {
                    setCategory("parents");
                    setIsSelect("");
                  }}
                >
                  <span>
                    <img
                      src={
                        category == "parents"
                          ? "./images/parents_fill.svg"
                          : "./images/parents.svg"
                      }
                    />
                    <h4>{t("Parent")}</h4>
                  </span>
                </li>
                <li
                  className={
                    category == "school"
                      ? "active"
                      : "" + isSelect == ""
                      ? ""
                      : "bordererror"
                  }
                  onClick={(e) => {
                    setCategory("school");
                    setIsSelect("");
                  }}
                >
                  <span>
                    <img
                      src={
                        category == "school"
                          ? "./images/school_fill.svg"
                          : "./images/school.svg"
                      }
                    />
                    <h4>{t("School")}</h4>
                  </span>
                </li>
              </ul>
              <button className="back_sign" onClick={hadnleRedirect}>
                {t("text-back")}
              </button>
              <button
                onClick={(e) => {
                  category != ""
                    ? setStep("stap2")
                    : setIsSelect("Please select yourself.");
                }}
              >
                {t("text-next")}
              </button>
            </div>
            <div
              className={
                "describe more_about " + (step == "stap2" ? "" : "none")
              }
            >
              <img src="/images/sign_logo.svg" />
              <h2>{t("What services do you need?")}</h2>
              <p>
                <span>{t("text-i-need")} </span> (
                {t("Please select up to two professions")}) <br />
                {t("signup-change-choice")}
              </p>
              <div className="process_guid ">
                <ul>
                  <li
                    className={
                      describe && describe.tab1 == t("text-nanny")
                        ? "active"
                        : "" + isSelect == ""
                        ? ""
                        : "bordererror"
                    }
                    onClick={(e) => {
                      handleDescribeSelect("tab1", t("text-nanny"));
                      setIsSelect("");
                    }}
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab1 == t("text-nanny")
                            ? "./images/nanny_fill.svg"
                            : "./images/nanny.svg"
                        }
                      />
                      <span>{t("text-nanny")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe && describe.tab2 == t("education-teacher")
                        ? "active"
                        : "" + isSelect == ""
                        ? ""
                        : "bordererror"
                    }
                    onClick={(e) => {
                      handleDescribeSelect("tab2", t("education-teacher"));
                      setIsSelect("");
                    }}
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab2 == t("education-teacher")
                            ? "./images/teacher_fill.svg"
                            : "./images/teacher.svg"
                        }
                      />
                      <span>{t("education-teacher")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe &&
                      describe.tab3 == t("education-paraprofessional")
                        ? "active"
                        : "" + isSelect == ""
                        ? ""
                        : "bordererror"
                    }
                    onClick={(e) => {
                      handleDescribeSelect(
                        "tab3",
                        t("education-paraprofessional")
                      );
                      setIsSelect("");
                    }}
                  >
                    <Link to="">
                      <img
                        src={
                          describe &&
                          describe.tab3 == t("education-paraprofessional")
                            ? "./images/education_fill.svg"
                            : "./images/education.svg"
                        }
                      />
                      <span>{t("education-paraprofessional")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe && describe.tab4 == t("text-tutor")
                        ? "active"
                        : "" + isSelect == ""
                        ? ""
                        : "bordererror"
                    }
                    onClick={(e) => {
                      handleDescribeSelect("tab4", t("text-tutor"));
                      setIsSelect("");
                    }}
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab4 == t("text-tutor")
                            ? "./images/tutor_fill.svg"
                            : "./images/tutor.svg"
                        }
                      />
                      <span>{t("text-tutor")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <button className="back_sign" onClick={(e) => setStep("stap1")}>
                {t("text-back")}
              </button>
              <button
                onClick={(e) => {
                  Object.keys(describe).length >= 1
                    ? setStep("stap3")
                    : setIsSelect("Please select yourself.");
                }}
              >
                {t("text-next")}
              </button>
            </div>

            <div
              className={
                "describe more_about form_signup " +
                (step == "stap3" ? "" : "none")
              }
            >
              <img src="/images/sign_logo.svg" />
              <h2>{t("Tell us more about yourself")}</h2>
              <form>
                <div className="form_group">
                  <label>{t("First Name")}</label>
                  <input
                      type="text"
                      placeholder={t("text-type")}
                      className={errorFiled.first_name == "" ? "" : "bordererror"}
                      name="first_name"
                      onChange={(e) => validationLoginField(e)}
                  />
                  <span className="errorfield">{errorFiled.first_name}</span>
                </div>
                <div className="form_group">
                  <label>{t("Last Name")}</label>
                  <input
                      type="text"
                      placeholder={t("text-type")}
                      name="last_name"
                      onChange={(e) => validationLoginField(e)}
                      className={errorFiled.last_name == "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{t(errorFiled.last_name)}</span>
                </div>

                <div className="form_group">
                  <label>{t("Username")}</label>

                  <div
                      className={errorFiled.username === "The username has already been taken." && "bordererror"}
                  >
                    <input
                        type="text"
                        placeholder={t("text-type")}
                        name="username"
                        onChange={(e) => validationLoginField(e)}
                        className={
                          errorFiled.username === "" || errorFiled.username === "The username has already been taken." ? "" : "bordererror"
                        }
                    />
                  </div>

                  <span className="errorfield">{errorFiled.username}</span>
                </div>
                <div className="form_group">
                  <label>{t("Email address")}</label>
                  <div className={errorFiled.email === "The email has already been taken." && "bordererror"}>
                    <input
                        type="email"
                        placeholder={t("text-type")}
                        name="email"
                        onChange={(e) => validationLoginField(e)}
                        className={errorFiled.email == "" || errorFiled.email === "The email has already been taken." ? "" : "bordererror"}
                    />
                  </div>

                  <span className="errorfield">{errorFiled.email}</span>
                </div>

                <div className="form_group">
                  <label>{t("Password")}</label>
                  <input
                      type={isPassword ? "text" : "password"}
                      placeholder=". . . . . . . . ."
                      name="password"
                      onChange={(e) => validationLoginField(e)}
                      className={errorFiled.password == "" ? "" : "bordererror"}
                  />
                  <div className="eyes">
                    <input
                        type={"checkbox"}
                        onClick={(e) => {
                          setIsPassword(!isPassword);
                        }}
                    />
                    <i></i>
                  </div>
                  {errorFiled.password || errorFiled.password ? (
                      <div className="password_rule errorfield signuppassword">
                        <p>
                          {t("password-bw-8-15-char")}
                          <br/>

                          {CAPITAL_LETTERS.test(formLogin.password)
                              ? ""
                              : t("- at least one uppercase letter")}
                          <br/>
                          {NUMBERS.test(formLogin.password)
                              ? ""
                              : t("- at least one number digit")}
                          <br/>
                          {SPECIAL_CHARACTERS.test(formLogin.password)
                              ? ""
                              : t(
                                  "- at least one special character -for example:  #, @, !"
                              )}
                        </p>
                      </div>
                  ) : (
                      ""
                  )}
                </div>

                <div className="form_group">
                  <label>{t("Repeat password")}</label>
                  <input
                      type={isNewPassword ? "text" : "password"}
                      placeholder=". . . . . . . . . . "
                      name="c_password"
                      onChange={(e) => validationLoginField(e)}
                      className={errorFiled.c_password == "" ? "" : "bordererror"}
                  />
                  <div className="eyes">
                    <input
                        type={"checkbox"}
                        onClick={(e) => {
                          setIsNewPassword(!isNewPassword);
                        }}
                    />
                    <i></i>
                  </div>
                  <span className="errorfield">{errorFiled.c_password}</span>
                </div>

                <div className="form_group" style={{
                  marginBottom: "0px"
                }}>
                  <label>{t("Date of birth")}</label>
                  <DatePicker
                      value={formLogin.dob ? dayjs(formLogin.dob) : null} // Convert to Day.js object
                      onChange={handleDateChange}
                      maxDate={dayjs(today)} // Convert `today` if it's not already a Day.js object
                      format="YYYY-MM-DD"
                      slotProps={{
                        textField: {
                          placeholder: t("text-type"),
                          error: !!errorFiled.dob, // Boolean for error state
                          helperText: errorFiled.dob, // Display error message
                          InputProps: {
                            className: errorFiled.dob ? "bordererror" : "", // Apply error class if needed
                          },
                        },
                      }}
                      className="custom-date-picker"
                  />
                </div>

                <div className="form_group number">
                  <label>{t("Mobile phone")}</label>
                  <input
                      type="number"
                      placeholder={t("text-type")}
                      name="phone"
                      onChange={(e) => {
                        validationLoginField(e);
                        setFormLogin({
                          ...formLogin,
                          phone:
                              e.target.value.length <= 10
                                  ? e.target.value
                                  : formLogin.phone,
                        });
                      }}
                      className={errorFiled.phone == "" ? "" : "bordererror"}
                  />
                  <div
                      className={
                        errorFiled.phone == ""
                            ? "country_flag"
                            : "bordererror country_flag"
                      }
                      onClick={(e) => handleCodeSelect()}
                  >
                    <img src={contactCode.flag}/> {contactCode.code}
                  </div>
                  <ul style={isCode ? {display: "none"} : {}}>
                    {DATA_COUNTRY.data.map((e) => {
                      return (
                          <li
                              onClick={(a) => {
                                handleCodeSelect();
                                setContactCode({
                                  flag: e.flag,
                                  code: e.dial_code,
                                });
                              }}
                          >
                            <img src={e.flag}/>
                            {e.country + " " + " " + e.dial_code}
                          </li>
                      );
                    })}
                  </ul>

                  <span className="errorfield">{errorFiled.phone}</span>
                </div>
                <div className="form_group">
                  <label>{t("Address")}</label>
                  <div
                      className={errorFiled.address == "" ? "" : "bordererror"}
                  >
                    <LocationContent let={handleLatLong}/>
                  </div>
                  <span className="errorfield">{errorFiled.address}</span>
                </div>
                <div className="form_group part2">
                  <div className="citydetail">
                    <label>{t("City")}</label>
                    <input
                        type="text"
                        placeholder={t("text-type")}
                        name="city"
                        onChange={(e) => validationLoginField(e)}
                        className={errorFiled.city == "" ? "" : "bordererror"}
                        defaultValue={formLogin.city}
                    />
                    <span className="errorfield">{errorFiled.city}</span>
                  </div>
                  <div className="citydetail">
                    <label>{t("Zip code")}</label>
                    <input
                        type="number"
                        placeholder={t("text-type")}
                        name="zip"
                        onChange={(e) => validationLoginField(e)}
                        className={errorFiled.zip == "" ? "" : "bordererror"}
                    />
                    <span className="errorfield">{errorFiled.zip}</span>
                  </div>
                </div>
                <div className="form_group">
                  <label>{t("Country")}</label>
                  <input
                      type="text"
                      name="country"
                      onChange={(e) => validationLoginField(e)}
                      value={formLogin.country}
                      className={errorFiled.country == "" ? "" : "bordererror"}
                  />

                  <span className="errorfield">{errorFiled.country}</span>
                </div>
                <div className="form_group">
                  <label>{t("How did you hear about us?")}</label>
                  <select
                      name="hearAboutUs"
                      onChange={(e) => validationLoginField(e)}
                      className={
                        (!errorFiled.hearAboutUs || errorFiled.hearAboutUs === "") ? "" : "bordererror"
                      }
                  >
                    <option value="" selected={true} disabled={true}>{t("choose-list")}</option>
                    <option value={"Internet browsing "}>
                      {t("Internet browsing")}
                    </option>
                    <option value={"Friend recommendation"}>
                      {t("Friend recommendation")}
                    </option>
                    <option value={"Facebook"}>{t("Facebook")}</option>
                    <option value={"Our website"}>{t("Our website")} </option>
                    <option value={"Other"}>{t("text-other")}</option>
                  </select>
                  <span className="errorfield">{errorFiled.hearAboutUs}</span>
                </div>
              </form>
              <p style={{
                marginTop: "8px"
              }}>
                {t("By clicking on “Sign up” you agree to our")}
                <Link to="/terms-of-use" target={"_blank"}>
                  {t("Terms of Use")}
                </Link>
                {t("text-and")}
                <Link to="/privacy-policy" target={"_blank"}>
                  {t("Privacy Policy")}
                </Link>
                .
              </p>
              <button className="back_sign" onClick={(e) => setStep("stap2")}>
                {t("text-back")}
              </button>
              <button
                  onClick={(e) => {
                    // handleShow()
                    handleSignup();
                  }}
              >
                <Link
                    to="#"
                    // '/thank_you'
                >
                  {t("sign-up")}
                </Link>
              </button>
            </div>
            <div
              className={
                "signupdetail " +
                (step == "stap1" || step == "stap2" || step == "stap3"
                  ? ""
                  : "none")
              }
            >
              <h2>
                {t("sign-up")} <br /> {t("for FREE!")}
              </h2>
              <ul>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>{t("Add your job post.")}</span>
                </li>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>{t("Browse a candidate's profile.")}</span>
                </li>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>{t("Find an ideal candidate.")}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <div className="promocode_content younger">
            <Link to="" onClick={handleCloseModal}>
              +
            </Link>
            <h5>{t("Thank you!")}</h5>
            <p>{t("post-about-persons-younder")}</p>
          </div>
        </Modal.Body>
      </Modal>
      </LocalizationProvider>
  );
}
