import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { MarkSecondaryIcon } from "../../../assets/image/MarkSecondaryIcon";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { PageIcon } from "../../../assets/image/PageIcon";
import { BoldArrowDown } from "../../../assets/image/BoldArrowDown";
import {
  fetchDocumentData,
  fethDeleteDocument,
} from "../../../api/documentApi";
import { REFINE_OPTIONS } from "../../../utils/variables";

export function ShareDocumentJob() {
  const { t } = useTranslation();

  const [documentsList, setDocumentsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [responsiveClass, setResponsiveClass] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [refine, setRefine] = useState({
    threeDays: false,
    withinWeek: false,
    twoFourDay: false,
    withinMonth: false,
  });
  const [deleteIds, setDeleteIds] = useState("");

  const handleDocumentData = async () => {
    try {
      const result = await fetchDocumentData(refine);
      setDocumentsList(result.data.getDocumnets);
    } catch (error) {
      console.error("Error fetching document data:", error);
    }
  };

  const handleDeleteDocument = async () => {
    try {
      await fethDeleteDocument(deleteIds);
      handleDocumentData();
      setShowModal(false);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleRefineDay = (e) => {
    setRefine({
      threeDays: e === "3",
      withinWeek: e === "7",
      twoFourDay: e === "24",
      withinMonth: e === "30",
    });
    setShouldFetch(true);
  };

  useEffect(() => {
    if (shouldFetch) {
      handleDocumentData();
      setShouldFetch(false);
    }

    console.log(documentsList);
  }, [shouldFetch]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setResponsiveClass("text-refine");
    }
  }, []);

  return (
    <>
      <div class="main-header share_doc">
        <h2 className="border">{t("shared-documents")}</h2>
        <div class="mail-header-bar">
          <p>
            {documentsList && documentsList.length} {t("text-documents")}
          </p>
          <div class="btn-group flex">
            {t("text-refine")}
            <div className="select">
              <label onClick={(e) => setIsActive(!isActive)}>
                {t(responsiveClass)}
                <span>
                  <img src="/images/done_a.svg" />
                </span>
              </label>
              {isActive && (
                <ul>
                  {REFINE_OPTIONS.map((option) => (
                    <li
                      key={option.label}
                      onClick={() => {
                        handleRefineDay(option.value);
                        setIsActive(!isActive);
                      }}
                    >
                      {t(option.label)}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <ul>
          {documentsList.map((data) => {
            if (data.original_name == null) {
              return (
                <>
                  {data.back_doc != null ? (
                    <li>
                      <span className="docic">
                        <PageIcon />
                      </span>
                      <span>
                        {data.UserName != null
                          ? data.UserName + "_background check"
                          : ""}
                      </span>
                      <span className="date">
                        
                        {new Date(data.created_at).getDate()}/
                        {new Date(data.created_at).getMonth() + 1}/
                        {new Date(data.created_at).getFullYear()}
                      </span>

                      <button>
                        <a
                          href={
                            "https://admin.stage.mysenscare.com/assets/images/users/" +
                            data.back_doc
                          }
                          download
                          target="_blank"
                        >
                          <BoldArrowDown />
                        </a>
                      </button>
                      <button
                        onClick={(e) => {
                          setShowModal(true);
                          setDeleteIds(data.id);
                        }}
                      >
                        <RaffleIcon />
                      </button>
                    </li>
                  ) : (
                    ""
                  )}

                  {data.cert_doc != null ? (
                    <li>
                      <span className="docic">
                        <PageIcon />
                      </span>
                      <span>
                        {data.UserName != null
                          ? data.UserName + "_Certificate"
                          : ""}
                      </span>
                      <span className="date">
                        
                        {new Date(data.created_at).getDate()}/
                        {new Date(data.created_at).getMonth() + 1}/
                        {new Date(data.created_at).getFullYear()}
                      </span>

                      <button>
                        <a
                          href={
                            "https://admin.stage.mysenscare.com/assets/images/users/" +
                            data.cert_doc
                          }
                          download
                          target="_blank"
                        >
                          <BoldArrowDown />
                        </a>
                      </button>
                      <button
                        onClick={(e) => {
                          setShowModal(true);
                          setDeleteIds(data.id);
                        }}
                      >
                        <RaffleIcon />
                      </button>
                    </li>
                  ) : (
                    ""
                  )}
                  {data.cv_doc != null ? (
                    <li>
                      <span className="docic">
                        <PageIcon />
                      </span>
                      <span>
                        {data.UserName != null ? data.UserName + "_CV" : ""}
                      </span>
                      <span className="date">
                        
                        {new Date(data.created_at).getDate()}/
                        {new Date(data.created_at).getMonth() + 1}/
                        {new Date(data.created_at).getFullYear()}
                      </span>

                      <button>
                        <a
                          href={
                            "https://admin.stage.mysenscare.com/assets/images/users/" +
                            data.cv_doc
                          }
                          download
                          target="_blank"
                        >
                          <BoldArrowDown />
                        </a>
                      </button>
                      <button
                        onClick={(e) => {
                          setShowModal(true);
                          setDeleteIds(data.id);
                        }}
                      >
                        <RaffleIcon />
                      </button>
                    </li>
                  ) : (
                    ""
                  )}

                  {data.recom_doc != null ? (
                    <li>
                      <span className="docic">
                        <PageIcon />
                      </span>
                      <span>
                        {data.UserName != null
                          ? data.UserName + "_Recommendation"
                          : ""}
                      </span>
                      <span className="date">
                        
                        {new Date(data.created_at).getDate()}/
                        {new Date(data.created_at).getMonth() + 1}/
                        {new Date(data.created_at).getFullYear()}
                      </span>

                      <button>
                        <a
                          href={
                            "https://admin.stage.mysenscare.com/assets/images/users/" +
                            data.recom_doc
                          }
                          download
                          target="_blank"
                        >
                          <BoldArrowDown />
                        </a>
                      </button>
                      <button
                        onClick={(e) => {
                          setShowModal(true);
                          setDeleteIds(data.id);
                        }}
                      >
                        <RaffleIcon />
                      </button>
                    </li>
                  ) : (
                    ""
                  )}
                </>
              );
            }
          })}
        </ul>
      </div>

      {showModal ? (
        <Modal
          show={showModal}
          onHide={(e) => setShowModal(false)}
          className=""
        >
          <Modal.Body>
            <div className="promocode_content upload_document_delete">
              <MarkSecondaryIcon />
              <h2>{t("delete-document")}</h2>

              <div className="gray">
                <p>
                  <span>{t("text-warning")}</span>
                  {t("document-delete-question")}
                  <br />
                  <span>{t("action-undone")}</span>
                </p>
                <button onClick={(e) => handleDeleteDocument()}>
                  {t("text-delete")}
                </button>
                <button onClick={(e) => setShowModal(false)}>
                  {t("text-cancel")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
